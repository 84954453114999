// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from './dropdown-item.model';
import WrapperComponent from '../../../helpers/WrapperComponent';

type DropdownItemProps = {
  index: number,
  item: DropdownItem,
  onClick: () => {}
};
const DropdownItemComp = (props: DropdownItemProps) => {
  const { item, onClick, index } = props;
  // const { key } = otherProps;
  return (
    <WrapperComponent>
      <a className={`dropdown-item ${item.isActive ? 'is-active' : ''}`} onClick={() => onClick(index)}>
        {item.title}
      </a>
      {item.hasDividerAfter ? <hr className='dropdown-divider' /> : null}
    </WrapperComponent>
  );
};

DropdownItemComp.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.objectOf(DropdownItem),
  onClick: PropTypes.func
};

export default DropdownItemComp;
