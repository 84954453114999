import { postScanType } from '../types';
import { apiPostJson, apiPostUrlEncoded } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';

const postScanStart = itdeBarcode => async dispatch => {
  dispatch({ type: postScanType.POST_SCAN_START });
  try {
    const res = await apiPostUrlEncoded('/transaction/v2/post-scan-check.php', { itdeBarcode });
    dispatch({ type: postScanType.POST_SCAN_SUCCESS });
    return await res.data;
  } catch (e) {
    showAlert({ type: 'error', title: e.response.status === 404 ? 'Item not found' : 'Failed to fetch item info' });
    dispatch({ type: postScanType.POST_SCAN_FAILURE });
    throw e;
  }
};

const PostScanActions = {
  postScanStart
};

export default PostScanActions;
