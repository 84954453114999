// @flow
// css
import './borrow.styles.scss';

import React, { useState, useEffect } from 'react';
import BorrowView from './comp/borrow-view.component';
import BorrowDetails from './comp/borrow-details.component';
import BorrowAdd from './comp/borrow-add.component';
import type { BorrowFilterOnChange, BorrowFilterOnChangeParams, BorrowFilterValue } from './comp/borrow-filter.component';
import LendActions from '../../redux/actions/lend.actions';
import PatronActions from '../../redux/actions/patron.actions';
import { connect } from 'react-redux';

const BorrowPage = ({ getOrgDropdownList, getPatronDropdownList, location }) => {
  const { state } = location;
  const [currentPage, setCurrentPage] = useState({ page: 1, props: {} });
  const [orgDropdownList, setOrgDropdownOptions] = useState([]);
  const [patronDropdownList, setPatronDropdownOptions] = useState([]);
  const [filterValue: BorrowFilterValue, setFilterValue: (value: BorrowFilterValue) => {}] = useState({
    startDate: null,
    endDate: null,
    filterBy: state ? state.filterBy : 0,
    ooiId: null,
    ptrnId: null
  });

  const handleFilterValueChange: BorrowFilterOnChange = (key, value) => {
    setFilterValue((current: BorrowFilterValue) => ({ ...current, [key]: value }));
  };

  useEffect(() => {
    getOrgDropdownList().then(data => setOrgDropdownOptions(data));
    getPatronDropdownList().then(data => setPatronDropdownOptions(data));
  }, []);

  useEffect(() => console.log(filterValue), [filterValue]);

  const onBack = () => setCurrentPage({ page: 1, props: {} });

  const pages = {
    view: {
      id: 1,
      dProps: {
        setCurrentPage: setCurrentPage,
        filterProps: { filterValue, orgDropdownList, patronDropdownList, handleFilterValueChange }
      }
    },
    add: { id: 2, dProps: { onBack: onBack } },
    details: { id: 3, dProps: { onBack: onBack } }
  };

  const getCurrentPage = () => {
    switch (currentPage.page) {
      case pages.add.id:
        return <BorrowAdd {...{ ...pages.add.dProps, ...currentPage.props }} />;
      case pages.details.id:
        return <BorrowDetails {...{ ...pages.details.dProps, ...currentPage.props }} />;
      default:
        return <BorrowView {...{ ...pages.view.dProps, ...currentPage.props }} />;
    }
  };

  return (
    <div className='borrow-comp comp-container'>
      <div className='comp-header'>
        <div className='title'>BORROWING</div>
      </div>
      <div className='comp-body container'>{getCurrentPage()}</div>
    </div>
  );
};

const mapDispatchToProps = {
  getOrgDropdownList: LendActions.getOrgDropdownList,
  getPatronDropdownList: PatronActions.getPatronDropdownList
};
export default connect(null, mapDispatchToProps)(BorrowPage);
