import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutPatron } from '../../redux/actions/patron-action';

class Logout extends Component {
  onLogout() {
    this.props.closeSideNav();
    this.props
      .logoutPatron()
      .then(() => {
        this.props.history.push('/');
      })
      .catch(() => {
        this.props.history.push('/');
      });
  }

  componentWillMount(): void {
    if (this.props.patron !== null) this.onLogout();
    else this.props.history.push('/');
  }

  render() {
    return <div>Logging you out</div>;
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});

export default connect(
  mapStateToProps,
  { logoutPatron }
)(Logout);
