import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WrapperComponent from '../../../helpers/WrapperComponent';

const FpStepOne = props => {
  const { onInputsChange, defaultState } = props;

  const [state, setState] = useState(
    defaultState
      ? defaultState
      : {
          orgId: '',
          email: ''
        }
  );

  useEffect(
    () => {
      onInputsChange(state);
    },
    [state]
  );

  const onInputChange = (key, e) => {
    const { value } = e.target;
    setState({ ...state, [key]: value });
  };
  return (
    <WrapperComponent>
      <div className='field'>
        <label className='label'>Org ID</label>
        <div className='control'>
          <input
            type='text'
            className='input'
            placeholder='Enter organization id...'
            value={state.orgId}
            onChange={e => onInputChange('orgId', e)}
            autoFocus={true}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Email</label>
        <div className='control'>
          <input
            type='email'
            className='input'
            placeholder='Enter your email...'
            value={state.email}
            onChange={e => onInputChange('email', e)}
          />
        </div>
      </div>
    </WrapperComponent>
  );
};

FpStepOne.propTypes = {
  onInputsChange: PropTypes.func.isRequired,
  defaultState: PropTypes.object
};
export default FpStepOne;
