import React, { Component } from 'react';
import InputContainer from './sub-components/input-container';
import { connect } from 'react-redux';
import { addCheckinItem, checkinItems, removeCheckinItemAt } from '../../redux/actions/check-out-in-action';
import ListItem from './sub-components/list-item';
import { showAlert, showToast } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';

class Checkin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScannerOpen: false,
      isPostScanCheckLoading: false,
      isLoading: false
    };
    this.onGo = this.onGo.bind(this);
    this.onCheckin = this.onCheckin.bind(this);
    this.onDeleteListItem = this.onDeleteListItem.bind(this);
    this.refInputBarcode = React.createRef();
  }

  componentWillMount(): void {
    // if (!this.props.patron.ptrnIsAdmin) this.history.push('/');
  }

  componentDidMount(): void {
    this.refInputBarcode.current.focus();
  }

  toggleScanner() {
    this.setState({
      ...this.state,
      isScannerOpen: !this.state.isScannerOpen
    });
  }

  onGo() {
    const barcode = this.refInputBarcode.current.value.trim();
    this.setState({ ...this.state, isPostScanCheckLoading: true });

    const onResponse = () => {
      this.refInputBarcode.current.value = '';
      this.setState({ ...this.state, isPostScanCheckLoading: false }, () => {
        this.refInputBarcode.current.focus();
      });
    };
    this.props
      .addCheckinItem(barcode.trim())
      .then(() => {
        onResponse();
        console.log(this.props.checkinList);
      })
      .catch(err => {
        console.log(err);
        onResponse();
        showAlert(err);
      });
  }

  onCheckin() {
    const { checkinItems } = this.props;
    const onResponse = () => {
      this.refInputBarcode.current.focus();
      this.setState({ ...this.state, isLoading: false }, () => {
        this.refInputBarcode.current.focus();
      });
    };
    this.setState(
      {
        ...this.state,
        isLoading: true
      },
      () => {
        this.refInputBarcode.current.value = '';
        this.refInputBarcode.current.blur();
        checkinItems()
          .then(successConfig => {
            showToast(successConfig);
            onResponse();
          })
          .catch(errConfig => {
            showAlert(errConfig);
            onResponse();
          });
      }
    );
  }

  onDeleteListItem(itdeId) {
    this.props.removeCheckinItemAt(itdeId);
  }

  render() {
    const checkinList = this.props.checkinList;
    return (
      <div className='comp-container comp-check'>
        {this.state.isLoading ? <Loader title='Please wait...' /> : null}
        <div className='has-text-centered' style={{ marginBottom: '2rem' }}>
          <span className='title is-size-3'>Check-in</span>
        </div>
        <div className='center-column content-container'>
          <InputContainer
            refBarcode={this.refInputBarcode}
            onGo={this.onGo}
            completeBtnTitle={'CHECKIN'}
            isCompleteBtnVisible={checkinList.length > 0}
            onComplete={this.onCheckin}
            isPostScanCheckLoading={this.state.isPostScanCheckLoading}
          />
          <ul className='item-list'>
            {checkinList.map(item => (
              <ListItem key={item.itdeId} item={item} onDelete={this.onDeleteListItem} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron,
  checkinList: state.checkReducer.checkinList
});
export default connect(
  mapStateToProps,
  { addCheckinItem, checkinItems, removeCheckinItemAt }
)(Checkin);
