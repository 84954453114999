import React, { Component } from 'react';
import { apiGet } from '../../helpers/Api';
import Loader from '../../layout/loader/loader';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, List } from 'react-virtualized';
import ListItemPatron from './sub-components/list-item-patron';

class ViewPatrons extends Component {
  constructor(props) {
    super(props);
    this.placeholderMap = {
      ptrnName: 'Search by name...',
      ptrnFirstname: 'Search by first name...',
      ptrnLastname: 'Search by last name...',
      ptrnEmail: 'Search by email...'
    };
    this.state = {
      isLoading: false,
      getPatronsReq: null,
      list: [],
      selectedOption: 'ptrnName',
      placeholder: this.placeholderMap['ptrnName'],
      searchInputValue: ''
    };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 65
    });
  }

  componentDidMount(): void {
    this.apiGetPatrons();
  }

  apiGetPatrons = () => {
    this.setState({ isLoading: true }, () => {
      apiGet('admin/patron/get-patrons.php')
        .then(res => {
          this.setState({ isLoading: false, getPatronsReq: res.data }, () => this.setList());
        })
        .catch(err => {
          this.setState({ isLoading: false });
        });
    });
  };

  setList = () => {
    this.setState(state => {
      let {
        selectedOption,
        searchInputValue,
        getPatronsReq: { patrons },
        list
      } = state;
      console.log('patrons', patrons);
      list = [];
      for (const patron of patrons) {
        if (selectedOption === 'ptrnName') {
          const ptrnName = `${patron.ptrnFirstname.toLowerCase()} ${patron.ptrnLastname.toLowerCase()}`;
          if (ptrnName.includes(searchInputValue.trim().toLowerCase())) list.push(patron);
        } else {
          if (patron[selectedOption].toLowerCase().includes(searchInputValue.trim().toLowerCase())) list.push(patron);
        }
      }
      return { list };
    });
  };

  handleSelect = event => {
    const { value } = event.target;
    this.setState(
      {
        selectedOption: value,
        placeholder: this.placeholderMap[value]
      },
      this.setList()
    );
  };

  handleSearchInput = event => {
    const { value } = event.target;
    this.setState(
      {
        searchInputValue: value
      },
      () => this.setList()
    );
  };
  renderRow = ({ index, key, style, parent }) => {
    const { list } = this.state;
    return (
      <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
        <ListItemPatron patron={list[index]} style={style} key={key} />
      </CellMeasurer>
    );
  };
  render() {
    const { isLoading, list, selectedOption, placeholder, searchInputValue } = this.state;
    return (
      <div className='comp-container comp-view-patrons center-column' style={{ minHeight: 'calc(100vh - 3rem)' }}>
        <div className='field has-addons search-container' style={{ marginTop: '1rem', width: '100%', maxWidth: '40em' }}>
          <div className='control'>
            <div className='select'>
              <select value={selectedOption} onChange={e => this.handleSelect(e)}>
                <option value='ptrnName'>Name</option>
                <option value='ptrnFirstname'>First name</option>
                <option value='ptrnLastname'>Last name</option>
                <option value='ptrnEmail'>Email</option>
              </select>
            </div>
          </div>
          <div className='control is-expanded'>
            <input
              type='text'
              className='input'
              placeholder={placeholder}
              value={searchInputValue}
              onChange={e => this.handleSearchInput(e)}
            />
          </div>
          <div className='control'>
            <div className='button is-primary'>
              <i className='fas fa-search' />
            </div>
          </div>
        </div>
        {list.length !== 0 ? (
          <div className='list' style={{ flex: '1 1 auto', width: '100%', maxWidth: '50em', marginBottom: '0.5rem' }}>
            <AutoSizer>
              {({ width, height }) => (
                <List
                  height={height}
                  overscanRowCount={3}
                  rowCount={list.length}
                  width={width}
                  deferredMeasurementCache={this.cache}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.renderRow}
                />
              )}
            </AutoSizer>
          </div>
        ) : null}
        <Loader active={isLoading} />
      </div>
    );
  }
}

export default ViewPatrons;
