import React, { Component } from 'react';
import './style.css';

import Quagga from 'quagga';

class Scanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scannerDidThrowError: false
    };
    this.refScannerContainer = React.createRef();
    this.refBarcodeDisplay = React.createRef();
  }

  componentDidMount(): void {
    const quaggaConfig = {
      numOfWorkers: navigator.hardwareConcurrency,
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        constraints: {
          width: 1280,
          height: 800
        },
        target: this.refScannerContainer.current
      },
      decoder: {
        readers: ['code_128_reader'],
        debug: {
          drawBoundingBox: true
        }
      },
      locator: {
        halfSample: false,
        patchSize: 'medium',
        debug: {
          showCanvas: true,
          showPatches: true,
          showFoundPatches: true,
          showSkeleton: true,
          showLabels: true,
          showPatchLabels: true,
          showRemainingPatchLabels: true,
          boxFromPatches: {
            showTransformed: true,
            showTransformedBox: true,
            showBB: true
          }
        }
      }
    };
    const quaggaCallback = err => {
      if (err) {
        console.log(err);
        this.setState({
          ...this.state,
          scannerDidThrowError: true
        });
        return;
      }
      console.log('Initialization finished. Ready to start');
      Quagga.start();
      Quagga.onDetected(data => {
        console.log(data.codeResult.code);
        this.refBarcodeDisplay.current.textContent = data.codeResult.code;
      });
    };

    if (!this.state.scannerDidThrowError) {
      Quagga.init(quaggaConfig, quaggaCallback);
    }
  }

  componentWillUnmount(): void {
    Quagga.stop();
  }

  render(): React.ReactNode {
    const { toggleScanner } = this.props;
    return (
      <div className='overlay scanner-overlay center-column' onClick={toggleScanner}>
        <div className='scanner-container center' ref={this.refScannerContainer} />
        <div className='barcode-display center'>
          <span ref={this.refBarcodeDisplay} />
        </div>
        {this.state.scannerDidThrowError ? (
          <span className='error-message'>
            Failed to initialize Scanner. <br />
            Make sure your device's camera is switched on and working.
          </span>
        ) : null}
      </div>
    );
  }
}

export default Scanner;
