import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const initialState = {};

const middleWare = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleWare.push(logger);
}

const composers = [applyMiddleware(...middleWare)];

if (process.env.NODE_ENV === 'development') {
  if (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) {
    composers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const store = createStore(rootReducer, initialState, compose(...composers));

export default store;
