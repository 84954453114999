import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../constants';

import './list-item-patron.scss';

const ListItemPatron = props => {
  const { patron, style } = props;
  return (
    <Link
      to={{ pathname: `${ROUTES.admin.viewPatrons}/${patron.ptrnId}`, state: { selectedPatron: patron } }}
      className='list-item list-item-patron has-text-black'
      style={style}>
      <div className='level'>
        <div className='level-left'>
          <div>
            <div className='heading'>
              ID: <em>{patron.ptrnId}</em> | Email: <em>{patron.ptrnEmail}</em>{' '}
              {patron.ptrnIsAdmin ? <span className='has-text-success'>ADMIN</span> : null}
            </div>
            <div className='subtitle is-size-5'>
              {patron.ptrnFirstname} {patron.ptrnLastname}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

ListItemPatron.propTypes = {
  patron: PropTypes.object.isRequired
};

export default ListItemPatron;
