import React from 'react';
import PropTypes from 'prop-types';
import getLocalDate, { getTimeDiff } from '../../../helpers/get-local-date';
import { apiPostUrlEncoded } from '../../../helpers/Api';
import { showAlert, showToast } from '../../../helpers/Alert';

const HistoryItem = ({ item, onSuccessfulRenew }) => {
  const { itemLbpTitle, trdeId, trdeDueBack, trdeReturned, isRenewable } = item;
  const timeDiff = getTimeDiff(trdeDueBack);
  const onRenew = e => {
    console.log(e.target.className);
    let originalClass = e.target.className;
    e.target.className += ' is-loading';
    apiPostUrlEncoded('/transaction/renew.php', { trdeId: trdeId })
      .then(() => {
        showToast({ type: 'success', title: 'Item Renewed' });
        onSuccessfulRenew();
      })
      .catch(() => {
        showAlert({ type: 'error', title: 'Renew failed' });
        e.target.className = originalClass;
      });
  };
  return (
    <li className='list-item'>
      <div className='level is-mobile'>
        <div className='level-left' style={{ maxWidth: 'calc(100% - 6rem)' }}>
          <div className='level-item has-text-left' style={{ width: '100%' }}>
            <div style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <p className='heading is-size-6 is-size-7-mobile'>
                {trdeReturned === '' ? `Due back: ${getLocalDate(trdeDueBack)}` : `Returned: ${getLocalDate(trdeReturned)}`}
              </p>
              <p className='title is-size-5 is-size-6-mobile has-text-weight-bold'>{itemLbpTitle}</p>
            </div>
          </div>
        </div>
        {isRenewable ? (
          <div className='level-right'>
            <span className='button is-primary is-size-7-mobile' onClick={e => onRenew(e)}>
              Renew
            </span>
          </div>
        ) : null}
      </div>
    </li>
  );
};

HistoryItem.propTypes = {
  item: PropTypes.string.isRequired,
  onSuccessfulRenew: PropTypes.func.isRequired
};

export default HistoryItem;
