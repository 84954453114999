import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../helpers/form-field';
import './style.css';
import { AddItemReq, ItemCopy } from '../../models/AddItem';
import CopyItem from './sub-components/copy-item';
import { apiGet, apiPostJson, apiPostUrlEncoded } from '../../helpers/Api';
import { showAlert, showToast } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';
import { parse as parseSearchParam } from 'query-string';
import { ROUTES } from '../../constants';
import TagsInput from 'react-tagsinput';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UnavailableCopyItem from './sub-components/unavailable-copy-item';
import { connect } from 'react-redux';
import { setBrowseShouldRefresh } from '../../redux/actions/browse-action';
import Uuidv4 from 'uuid/v4';

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.addItemReq = new AddItemReq();
    this.searchParam = parseSearchParam(this.props.location.search);
    this.state = {
      isLoading: true,
      itemTypes: [],
      itemLocations: [],
      addItemReq: new AddItemReq(),
      itemCopies: this.addItemReq.itemCopies,
      availableCopiesList: this.addItemReq.availableCopiesList,
      unavailableCopiesList: this.addItemReq.unavailableCopiesList,
      volumeTags: []
    };
    this.delimiters = [188, 13]; //188 -> comma, 13 -> enter
    console.log('location', this.props.location);
  }

  resetState = () => {
    const { itemTypes, itemLocations } = this.state;
    const newAddItemReq = new AddItemReq();
    if (itemTypes.length !== 0) newAddItemReq.ittyId = itemTypes[0].ittyId;
    if (itemLocations.length !== 0) newAddItemReq.itemCopies[0].itloId = itemLocations[0].itloId;
    this.setState(
      {
        isLoading: false,
        addItemReq: newAddItemReq
      },
      () => window.scrollTo(0, 0)
    );
  };

  componentDidMount(): void {
    console.log('Search Params', this.searchParam);
    this.apiLoadTypesAndLocations(this.apiLoadCurrentItemDetails);
  }

  onChange = (event, key) => {
    const {
      target: { value }
    } = event;
    this.setState(
      state => {
        const { addItemReq } = state;
        addItemReq[key] = value;
        return { addItemReq };
      },
      () => console.log(this.state)
    );
  };

  onCopyChange = (event, id, objKey) => {
    const { value } = event.target;
    this.setState(state => {
      const itemCopies = state.addItemReq.itemCopies.map(copy => {
        if (copy.id === id) {
          copy[objKey] = value;
        }
        return copy;
      });
      const { addItemReq } = state;
      addItemReq.itemCopies = itemCopies;
      return {
        addItemReq
      };
    });
  };

  onAvailableCopiesChange = (event, id, objKey) => {
    const { value } = event.target;
    this.setState(state => {
      const availableCopiesList = state.addItemReq.availableCopiesList.map(copy => {
        if (copy.itdeId === id) {
          copy[objKey] = value;
        }
        return copy;
      });
      const { addItemReq } = state;
      addItemReq.availableCopiesList = availableCopiesList;
      return {
        addItemReq
      };
    });
  };

  apiLoadTypesAndLocations = (cb = null) => {
    this.setState({ isLoading: true }, () => {
      apiGet('/admin/others/get-item-types-locations.php', null)
        .then(res => {
          const { itemTypes, itemLocations } = res.data;

          this.setState(
            {
              isLoading: false,
              itemTypes: itemTypes,
              itemLocations: itemLocations
            },
            () => {
              if (cb !== null) cb();
            }
          );
          console.log('state', this.state);
        })
        .catch(err => {
          this.setState({ isLoading: false });
          showAlert({ type: 'error', title: 'Failed to fetch types and locations' });
        });
    });
  };

  apiLoadCurrentItemDetails = () => {
    if (!this.searchParam.itemId) {
      const { itemTypes, itemLocations } = this.state;
      const newAddItemReq = new AddItemReq();
      if (itemTypes.length !== 0) newAddItemReq.ittyId = itemTypes[0].ittyId;
      if (itemLocations.length !== 0) newAddItemReq.itemCopies[0].itloId = itemLocations[0].itloId;
      this.setState({ addItemReq: newAddItemReq }, () => console.log('add-item state', this.state));
      return;
    }
    this.setState({ isLoading: true });
    apiGet('/admin/item/item-details.php', this.searchParam)
      .then(res => {
        const temp = new AddItemReq();
        temp.merge(res.data);
        this.setState({
          isLoading: false,
          addItemReq: temp
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        showAlert({ type: 'error', title: 'Failed to fetch item details' });
      });
  };

  addCopy = itemCopy => {
    const { itemCopies } = this.state.addItemReq;
    itemCopy.itdeCopy = itemCopies[itemCopies.length - 1] ? Number(itemCopies[itemCopies.length - 1].itdeCopy) + 1 : 1;
    // itemCopy.itdeCopy = this.state.addItemReq.itemCopies.length + 1;
    this.setState(state => {
      const itemCopies = [...state.addItemReq.itemCopies, itemCopy];
      const { addItemReq } = state;
      addItemReq.itemCopies = itemCopies;
      return {
        addItemReq
      };
    });
  };

  removeCopy = id => {
    if (this.state.addItemReq.itemCopies.length === 1) {
      showToast({ title: 'Cannot remove the only copy' });
      return;
    }
    this.setState(
      state => {
        const itemCopies1 = state.addItemReq.itemCopies.filter(copy => copy.id !== id);
        // const itemCopies2 = itemCopies1.map((copy, index) => {
        //   copy.itdeCopy = index + 1;
        //   return copy;
        // });
        const { addItemReq } = state;
        addItemReq.itemCopies = itemCopies1;
        return {
          addItemReq
        };
      },
      () => console.log('after remove', this.state)
    );
  };

  onUpdateCopies = () => {
    const request = this.state.addItemReq.availableCopiesList;
    apiPostJson('/admin/item/update-item-copies.php', request)
      .then(() => {
        this.props.setBrowseShouldRefresh(true);
        this.showUpdateSuccessAlert('Copies updated successfully');
      })
      .catch(() => showToast({ type: 'error', title: 'Failed to update copies' }));
  };

  apiInsertItem = () => {
    const { addItemReq } = this.state;
    let successMessage = 'Item inserted successfully';
    let errorMessage = 'Failed to insert item';

    const cbMoreVols = () => {
      const alert = withReactContent(Swal);
      const { addItemReq } = this.state;
      this.setState(
        {
          addItemReq: { ...addItemReq, itemCopies: addItemReq.itemCopies.map(copy => ({ ...copy, itdeBarcode: '' })) }
        },
        () => {
          console.log(this.state);
          alert
            .fire({
              type: 'success',
              title: successMessage,
              html: (
                <p>
                  Note: If you click <strong>'Insert next volume...'</strong>, the next volume will have the same info as the
                  previous volume except the barcode(s) and of course the volume."
                </p>
              ),
              showCancelButton: true,
              confirmButtonText: 'Insert next volume...',
              cancelButtonText: "Wait, I'd like to make changes..."
            })
            .then(result => {
              if (!result.value) return;
              this.apiInsertItem();
            });
        }
      );
    };

    apiPostJson('/admin/item/insert-item.php', addItemReq)
      .then(res => {
        this.props.setBrowseShouldRefresh(true);
        showToast({ type: 'success', title: successMessage });
        if (this.state.volumeTags.length > 0) {
          this.onVolTagDelete(0, cbMoreVols, this.resetState);
        } else this.resetState();
      })
      .catch(err => {
        showToast({ type: 'error', title: errorMessage });
      });
  };

  apiInsertItemCopies = () => {
    const { addItemReq } = this.state;

    apiPostJson('/admin/item/insert-item-copies.php', { itemId: addItemReq.itemId, itemCopies: addItemReq.itemCopies })
      .then(() => {
        this.props.setBrowseShouldRefresh(true);
        this.apiLoadCurrentItemDetails();
        this.showUpdateSuccessAlert('Copies inserted');
      })
      .catch(() => showToast({ type: 'error', title: 'Failed to insert copies' }));
  };

  onSubmit = () => {
    const { addItemReq } = this.state;
    const { itemCopies, availableCopiesList } = addItemReq;

    //<editor-fold desc="Validation">
    const verifyCopies = () => {
      const counterObj = {};
      for (let i = 0; i < itemCopies.length; i++) {
        const barcode = itemCopies[i].itdeBarcode.trim();
        if (barcode !== '') {
          if (counterObj[barcode] === undefined) counterObj[barcode] = 1;
          else {
            showAlert({ type: 'error', title: 'Two or more copies have the same barcode' });
            return;
          }
        }
        if (this.searchParam.itemId) {
          for (let i = 0; i < availableCopiesList.length; i++) {
            const barcode = availableCopiesList[i].itdeBarcode.trim();
            if (barcode !== '') {
              if (counterObj[barcode] === undefined) counterObj[barcode] = 1;
              else {
                showAlert({ type: 'error', title: 'Two or more copies have the same barcode' });
                return;
              }
            }
          }
        }
      }
    };

    //Title empty check
    if (addItemReq.itemLbpTitle.trim() === '') {
      showAlert({ type: 'error', title: 'Item title cannot be empty' });
      return;
    }

    //Copies barcode validation
    verifyCopies();
    //</editor-fold>

    // if (this.searchParam.itemId) return;
    if (this.searchParam.itemId) this.apiInsertItemCopies();
    else this.apiInsertItem();
  };

  onUpdateItemInfo = () => {
    if (!this.searchParam.itemId) return;
    const { addItemReq } = this.state;
    if (addItemReq.itemLbpTitle.trim() === '') {
      showAlert({ type: 'error', title: 'Item title cannot be empty' });
      return;
    }
    // noinspection JSUnusedLocalSymbols
    const { itemCopies, availableCopiesList, unavailableCopiesList, ...itemInfo } = addItemReq;
    apiPostJson('/admin/item/update-item.php', itemInfo)
      .then(() => {
        this.props.setBrowseShouldRefresh(true);
        this.showUpdateSuccessAlert('Item information has been updated');
      })
      .catch(() => showToast({ type: 'error', title: 'Failed to update item information' }));
  };

  showUpdateSuccessAlert = message => {
    const alert = withReactContent(Swal);
    alert
      .fire({
        type: 'success',
        title: message,
        showCancelButton: true,
        confirmButtonText: "Go back to 'View Items'",
        cancelButtonText: 'Stay here'
      })
      .then(result => {
        if (result.value) {
          this.props.history.push({ pathname: ROUTES.admin.viewItems, state: { shouldRefresh: true } });
        }
      });
  };

  onDeleteItemCopy = itdeId => {
    const alert = withReactContent(Swal);
    const { availableCopiesList, unavailableCopiesList } = this.state.addItemReq;
    const isLastCopy = availableCopiesList.length + unavailableCopiesList.length === 1;
    const message = {
      title: isLastCopy
        ? 'Are you sure you want to delete the last copy of this item?'
        : 'Are you sure you want to delete this copy?',
      html: isLastCopy ? (
        <p>Deleting this copy will make the item unavailable for circulation</p>
      ) : (
        <p>This copy will not become unavailable for circulation if you delete it</p>
      )
    };

    alert
      .fire({
        type: 'warning',
        title: message.title,
        html: message.html,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      .then(result => {
        console.log(result);
        if (!result.value) return;
        let req, successCb;
        if (isLastCopy) {
          req = { itemId: this.state.addItemReq.itemId };
          successCb = () => {
            showToast({ type: 'success', title: 'Item deleted' });
            this.props.history.push({ pathname: ROUTES.admin.viewItems, state: { shouldRefresh: true } });
          };
        } else {
          req = { itdeId: itdeId };
          successCb = () => {
            showToast({ type: 'success', title: 'Copy deleted' });
            this.setState(state => ({
              addItemReq: {
                ...state.addItemReq,
                availableCopiesList: availableCopiesList.filter(value => value.itdeId !== itdeId)
              }
            }));
          };
        }
        this.apiDeleteItem(req, successCb);
      });
  };

  apiDeleteItem = (req, successCb = () => {}) => {
    apiPostUrlEncoded('/admin/item/delete-item.php', req)
      .then(() => {
        this.props.setBrowseShouldRefresh(true);
        successCb();
      })
      .catch(() => showAlert({ type: 'error', title: 'Deletion failed' }));
  };

  handleVolTagAddition = tags => {
    console.log(tags);
    this.setState(
      state => ({ volumeTags: tags, addItemReq: { ...state.addItemReq, itemVol: tags[0] || '' } }),
      () => {
        console.log(this.state);
      }
    );
  };

  onVolTagDelete = (i, cbVolsRemaining = () => {}, cbNoMoreVols = () => {}) => {
    console.log(i);
    const { volumeTags } = this.state;
    if (i > volumeTags.length - 1) {
      cbNoMoreVols();
      return;
    }
    this.setState(
      {
        volumeTags: volumeTags.filter((tag, index) => index !== i)
      },
      () => {
        if (this.state.volumeTags.length !== 0) {
          this.setState(
            {
              addItemReq: { ...this.state.addItemReq, itemVol: this.state.volumeTags[0] }
            },
            cbVolsRemaining
          );
        } else {
          this.setState(
            {
              addItemReq: { ...this.state.addItemReq, itemVol: '' }
            },
            cbNoMoreVols
          );
        }
      }
    );
  };

  handleVolTagBlur = value => {
    if (value.trim() !== '') {
      this.handleVolTagAddition({ id: value, text: value });
    }
  };

  onDuplicateItem = () => {
    this.searchParam.itemId = null;
    this.props.history.push(ROUTES.admin.addItem);
    this.setState(
      state => {
        let { addItemReq, volumeTags } = state;
        addItemReq.itemId = '';
        addItemReq.itemCopies = [
          ...addItemReq.availableCopiesList.map(value => ({ ...value, id: Uuidv4(), itdeId: '', itdeBarcode: '' })),
          ...addItemReq.unavailableCopiesList.map(value => ({ ...value, id: Uuidv4(), itdeId: '', itdeBarcode: '' }))
        ];
        addItemReq.availableCopiesList = [];
        addItemReq.unavailableCopiesList = [];
        if (addItemReq.itemVol.trim() !== '') volumeTags = [addItemReq.itemVol.trim()];
        return { addItemReq, volumeTags };
      },
      () => window.scrollTo(0, 0)
    );
    // this.forceUpdate();
  };

  render() {
    const { itemTypes, itemLocations, addItemReq, isLoading, volumeTags } = this.state;
    const { availableCopiesList, unavailableCopiesList } = this.state.addItemReq;
    const defaultCopyLocation = itemLocations.length > 0 ? itemLocations[0].itloId : null;
    return (
      <div className='comp-container comp-add-item center-column'>
        <h1 className='title is-size-3'>{this.searchParam.itemId ? 'Update' : 'Add'} Item</h1>
        <div className='form-container'>
          <FormField
            label={'Title'}
            onChange={this.onChange}
            defaultValue={addItemReq.itemLbpTitle}
            indexKey='itemLbpTitle'
            placeholder={'Enter title...'}
            inputColorClass={'is-primary'}
            required={true}
          />
          <FormField
            label={'Author'}
            defaultValue={addItemReq.itemLbpAuthor}
            onChange={this.onChange}
            indexKey='itemLbpAuthor'
            placeholder={'Enter author...'}
          />
          <FormField
            label={'Corporate Author'}
            defaultValue={addItemReq.itemCorAuthor}
            onChange={this.onChange}
            indexKey='itemCorAuthor'
            placeholder={'Enter corporate author'}
          />
          <FormField
            label={'Publisher'}
            defaultValue={addItemReq.itemLbpPublisher}
            onChange={this.onChange}
            indexKey='itemLbpPublisher'
            placeholder={'Enter publisher...'}
          />
          {!this.searchParam.itemId ? (
            <FormField label={'Volume(s)'}>
              <div className='control has-icons-right'>
                <TagsInput
                  value={volumeTags}
                  onChange={this.handleVolTagAddition}
                  onlyUnique={true}
                  addOnBlur={true}
                  inputProps={{ placeholder: 'Enter volume(s)...' }}
                  addKeys={this.delimiters}
                />
                <span
                  className='icon is-small is-right tooltip is-tooltip-top is-tooltip-left-mobile is-tooltip-left-tablet-only'
                  data-tooltip='Enter multiple volumes by pressing &#9166; or , key'
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
                  <i className='fas fa-question-circle' />
                </span>
              </div>
            </FormField>
          ) : (
            <FormField
              label={'Volume'}
              defaultValue={addItemReq.itemVol}
              onChange={this.onChange}
              indexKey='itemVol'
              placeholder={'Enter volume...'}
            />
          )}
          <FormField label={'Type'}>
            <div className='select'>
              <select onChange={e => this.onChange(e, 'ittyId')}>
                {itemTypes.map(type => (
                  <option selected={type.ittyId === addItemReq.ittyId} value={type.ittyId}>
                    {type.ittyName}
                  </option>
                ))}
              </select>
            </div>
          </FormField>
          <FormField label={'Description'} onChange={this.onChange} indexKey='itemLbpDescription'>
            <textarea
              cols='30'
              rows='5'
              value={addItemReq.itemLbpDescription}
              className='textarea'
              onChange={e => this.onChange(e, 'itemLbpDescription')}
              placeholder='Enter description...'
            />
          </FormField>
          <FormField
            label={'Detail 1'}
            value={addItemReq.itemDetail1}
            onChange={this.onChange}
            indexKey='itemDetail1'
            placeholder={'Enter detail...'}
          />
          <FormField
            label={'Detail 2'}
            value={addItemReq.itemDetail2}
            onChange={this.onChange}
            indexKey='itemDetail2'
            placeholder={'Enter detail...'}
          />
          <FormField
            label={'Detail 3'}
            value={addItemReq.itemDetail3}
            onChange={this.onChange}
            indexKey='itemDetail3'
            placeholder={'Enter detail...'}
          />
          <FormField
            label={'Detail 4'}
            value={addItemReq.itemDetail4}
            onChange={this.onChange}
            indexKey='itemDetail4'
            placeholder={'Enter detail...'}
          />
          <FormField
            label={'Lead Resource'}
            value={addItemReq.itemLeadResource}
            onChange={this.onChange}
            indexKey='itemLeadResource'
            placeholder={'Enter lead resource...'}
          />
          {this.searchParam.itemId ? (
            <div className='list-item has-background-white-ter right'>
              <div className='button is-info' onClick={this.onDuplicateItem}>
                Duplicate
              </div>
              <div className='button is-primary' onClick={this.onUpdateItemInfo} style={{ marginLeft: '1rem' }}>
                Update Item Info
              </div>
            </div>
          ) : null}
          {availableCopiesList.length > 0 ? (
            <div>
              <div
                className='is-divider is-success'
                data-content={`AVAILABLE COPIES - ${addItemReq.availableCopiesList.length}`}
              />
              <ul className='list copy-list'>
                {availableCopiesList.map((itemCopy, index) => (
                  <CopyItem
                    key={index}
                    onChange={this.onAvailableCopiesChange}
                    itemCopy={itemCopy}
                    itemLocations={itemLocations}
                    onRemove={this.onDeleteItemCopy}
                    onDuplicate={this.addCopy}
                  />
                ))}
                <li className='list-item has-background-white-ter right'>
                  <div className='button is-primary' onClick={this.onUpdateCopies} style={{ marginLeft: '1rem' }}>
                    Update Copies
                  </div>
                </li>
              </ul>
            </div>
          ) : null}
          {unavailableCopiesList.length > 0 ? (
            <div>
              <div className='is-divider is-danger' data-content={`CHECKED-OUT COPIES - ${unavailableCopiesList.length}`} />
              <ul className='list copy-list'>
                {unavailableCopiesList.map(itemCopy => (
                  <UnavailableCopyItem itemCopy={itemCopy} itemLocations={itemLocations} />
                ))}
              </ul>
            </div>
          ) : null}
          <div
            className='is-divider is-primary'
            data-content={`${this.searchParam.itemId ? 'NEW ' : ''}COPIES - ${addItemReq.itemCopies.length}`}
          />
          <ul className='list copy-list'>
            {addItemReq.itemCopies.map((itemCopy, index) => (
              <CopyItem
                key={index}
                onChange={this.onCopyChange}
                itemCopy={itemCopy}
                itemLocations={itemLocations}
                onRemove={this.removeCopy}
                onDuplicate={this.addCopy}
              />
            ))}
            <li className='list-item has-background-white-ter right'>
              <div
                className='button is-success'
                onClick={() => this.addCopy(new ItemCopy(addItemReq.itemCopies.length + 1, '', '', '', defaultCopyLocation))}>
                Add {this.searchParam.itemId ? 'New ' : ''}Copy
              </div>
              <div className='button is-primary' onClick={this.onSubmit} style={{ marginLeft: '1rem' }}>
                {this.searchParam.itemId ? 'Save new copies' : 'Submit'}
              </div>
            </li>
          </ul>
        </div>
        <Loader title={'Please wait...'} active={isLoading} />
      </div>
    );
  }
}

AddItem.propTypes = {
  setBrowseShouldRefresh: PropTypes.func.isRequired
};
export default connect(() => {}, { setBrowseShouldRefresh })(AddItem);
