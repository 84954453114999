import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logo from '../../assets/blast_custom_logo.png';
import { ROUTES } from '../../constants';

function NotLoggedIn(props) {
  // loginPatron(event) {
  //   const orgId = this.orgIdInput.current.value;
  //   const ptrnEmail = this.emailInput.current.value;
  //   const ptrnPass = this.passwordInput.current.value;
  //   event.preventDefault();
  //   this.setState({
  //     ...this.state,
  //     isLoading: true
  //   });
  //   this.props.loginPatron(orgId, ptrnEmail, ptrnPass, status => {
  //     if (status === 500) {
  //       this.setState({
  //         ...this.state,
  //         loginMessage: 'Connection error',
  //         isLoading: false
  //       });
  //     } else if (status === 404 || status === 401) {
  //       this.setState({
  //         ...this.state,
  //         loginMessage: 'Invalid credentials',
  //         isLoading: false
  //       });
  //     } else {
  //       this.setState({
  //         ...this.state,
  //         loginMessage: '',
  //         isLoading: false
  //       });
  //     }
  //   });
  // }
  const { onLogin, state } = props;
  const { loginStatusMessage, loginButtonIsLoading } = state;
  let orgIdInput, emailInput, passwordInput;
  const style = {
    logo: {
      height: '5em',
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      marginTop: '2rem'
    },
    heroFoot: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      paddingBottom: '1.5rem',
      button: {
        display: 'block',
        margin: '0.5rem 0.5rem'
      }
    }
  };
  return (
    <section className='hero is-fullheight is-primary is-bold'>
      <div className='hero-head'>
        <img src={logo} alt='BLAST' className='logo' style={style.logo} />
      </div>
      <div className='hero-body'>
        <div
          className='columns is-vcentered is-variable is-8-desktop is-8-widescreen is-8-fullhd'
          style={{ margin: 'auto', width: '100%' }}>
          <div className='column center'>
            <div className='box' style={{ maxWidth: '26rem', width: '100%', margin: 'auto' }}>
              <form method='POST'>
                {loginStatusMessage !== null ? (
                  <div className='field has-text-centered has-text-danger'>
                    <span>{loginStatusMessage}</span>
                  </div>
                ) : null}
                <div className='field'>
                  <label htmlFor='input-orgid' className='label'>
                    Org ID
                  </label>
                  <div className='control'>
                    <input
                      type='text'
                      name='orgid'
                      id='input-orgid'
                      className='input has-border'
                      placeholder='Enter org ID'
                      ref={element => (orgIdInput = element)}
                      required
                    />
                  </div>
                </div>
                <div className='field'>
                  <label htmlFor='input-email' className='label'>
                    Email Address
                  </label>
                  <div className='control has-icons-left'>
                    <input
                      type='email'
                      name='email'
                      id='input-email'
                      className='input'
                      placeholder='Enter email address'
                      ref={element => (emailInput = element)}
                      required
                    />
                    <span className='icon is-small is-left'>
                      <i className='fas fa-envelope' />
                    </span>
                  </div>
                </div>
                <div className='field'>
                  <label htmlFor='input-password' className='label'>
                    Password
                  </label>
                  <div className='control has-icons-left'>
                    <input
                      type='password'
                      name='password'
                      id='input-password'
                      className='input'
                      placeholder='Enter password'
                      ref={element => (passwordInput = element)}
                      required
                    />
                    <span className='icon is-small is-left'>
                      <i className='fas fa-lock' />
                    </span>
                  </div>
                </div>
                <hr style={{ marginBottom: '0.5rem' }} />
                <Link className='has-text-info' to={ROUTES.forgotPass}>
                  Can't login?
                </Link>
                <div className='field'>
                  <div className='control has-text-right'>
                    <button
                      className={`button is-link ${loginButtonIsLoading ? 'is-loading' : ''}`}
                      onClick={event => onLogin(event, orgIdInput.value, emailInput.value, passwordInput.value)}>
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*<div className='column center'>*/}
          {/*<div style={{ maxWidth: '30rem', width: '100%', margin: 'auto' }}>*/}
          {/*<div className='title'>About Us</div>*/}
          {/*<p>*/}
          {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque totam reprehenderit officia quaerat*/}
          {/*itaque, error, esse explicabo modi dignissimos mollitia quam sint repellat quis dolorum magnam nihil sit*/}
          {/*quae maiores cum quisquam! Distinctio nobis debitis qui quae error saepe dolore molestias maxime quia*/}
          {/*consequatur, facilis rem aut reiciendis ab totam.*/}
          {/*</p>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>

      {/*<div className='hero-foot' style={style.heroFoot}>*/}
      {/*<Link to='/registration' className='button is-info is-medium' style={style.heroFoot.button}>*/}
      {/*Register with us*/}
      {/*</Link>*/}
      {/*<button className='button is-info is-medium is-outlined' style={style.heroFoot.button}>*/}
      {/*Contact us*/}
      {/*</button>*/}
      {/*</div>*/}
    </section>
  );
}

NotLoggedIn.propTypes = {
  onLogin: PropTypes.func.isRequired
};

export default NotLoggedIn;
