import { BROWSE_SET_REQ, BROWSE_SET_RES, BROWSE_SHOULD_REFRESH, LOGOUT } from '../types';
import { GetItemsReq } from '../../models/GetItems';

class BrowseReducerState {
  constructor() {
    this.getItemsReq = new GetItemsReq(100);
    this.getItemsRes = null;
    this.shouldRefresh = false;
  }
}
const initialState = new BrowseReducerState();

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case BROWSE_SET_REQ:
      // return { ...state, getItemsReq: payload.getItemsReq, getItemsRes: payload.getItemsRes };
      return { ...state, getItemsReq: { ...state.getItemsReq, ...payload } };
    case BROWSE_SET_RES:
      return { ...state, getItemsRes: payload };
    case BROWSE_SHOULD_REFRESH:
      return { ...state, shouldRefresh: payload };
    case LOGOUT:
      return new BrowseReducerState();
    default:
      return state;
  }
}
