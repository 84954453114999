import React, { Component } from 'react';

class OrgDetailsComponent extends Component {
  render() {
    return (
      <div className='container comp-container'>
        <div className='comp-header'>Org details</div>
        <div className='comp-org-details'>org details page</div>
      </div>
    );
  }
}

export default OrgDetailsComponent;
