import { postScanType } from '../types';

const initialState = {
  isLoading: false
};

const PostScanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case postScanType.POST_SCAN_START:
      return { ...state, isLoading: true };
    case postScanType.POST_SCAN_SUCCESS:
    case postScanType.POST_SCAN_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default PostScanReducer;
