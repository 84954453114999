import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PRoute = ({ component: Component, ...rest }) => {
  const thisProps = { ...rest };
  return (
    <Route
      {...rest}
      render={props =>
        thisProps.patron !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: thisProps.location.pathname } }} />
        )
      }
    />
  );
};

const ARoute = ({ component: Component, ...rest }) => {
  const thisProps = { ...rest };
  return (
    <Route
      {...rest}
      render={props =>
        thisProps.patron !== null && thisProps.patron.ptrnIsAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: thisProps.location.pathname, forAdminOnly: true } }} />
        )
      }
    />
  );
};

const NPRoute = ({ component: Component, ...rest }) => {
  const thisProps = { ...rest };
  return (
    <Route
      {...rest}
      render={props =>
        thisProps.patron === null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: thisProps.location.pathname } }} />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});

const PrivateRoute = connect(mapStateToProps)(PRoute);
const AdminRoute = connect(mapStateToProps)(ARoute);
const NonPrivateRoute = connect(mapStateToProps)(NPRoute);

const propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired
};

PrivateRoute.propTypes = propTypes;
AdminRoute.propTypes = propTypes;
NonPrivateRoute.propTypes = propTypes;

export { PrivateRoute, NonPrivateRoute, AdminRoute };
