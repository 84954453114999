/* @flow */
export class GetItemsReq {
  page: number;
  limit: number;
  searchText: string;
  searchByAuthor: boolean;
  getFavourites: boolean;
  getCategories: boolean;
  categories: string[];

  constructor(limit: number) {
    this.page = 1;
    this.limit = limit;
    this.searchText = '';
    this.searchByAuthor = false;
    this.getFavourites = false;
    this.getCategories = true;
    this.categories = [];
  }
}

class ItemCategory {
  ittyId: string;
  ittyName: string;

  constructor(ittyId: string, ittyName: string) {
    this.ittyId = ittyId;
    this.ittyName = ittyName;
  }
}

class Item {
  itemId: number;
  itemLbpTitle: string;
  itemLbpAuthor: string;
  ittyName: string;
  availableCopies: string;
  totalCopies: string;

  constructor(
    itemId: number,
    itemLbpTitle: string,
    itemLbpAuthor: string,
    ittyName: string,
    availableCopies: string,
    totalCopies: string
  ) {
    this.itemId = itemId;
    this.itemLbpTitle = itemLbpTitle;
    this.itemLbpAuthor = itemLbpAuthor;
    this.ittyName = ittyName;
    this.availableCopies = availableCopies;
    this.totalCopies = totalCopies;
  }
}

export class GetItemsRes {
  total: number;
  currentListTotal: number;
  itemCategories: ItemCategory[];
  items: Item[];

  constructor(getItemsRes: GetItemsRes) {
    this.total = getItemsRes.total;
    this.currentListTotal = getItemsRes.currentListTotal;
    this.itemCategories = getItemsRes.itemCategories;
    this.items = getItemsRes.items;
  }
}
