import React from 'react';
import PropTypes from 'prop-types';
import GridItem from '../grid-item';

const GridItemReader = props => {
  const { item } = props;
  return (
    <GridItem header={'Reader'} color={'is-accent'}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div>
            <span className='heading'>Total</span>
            <span className='title is-size-4'>{item.totalReaders}</span>
          </div>
        </div>
      </div>
      <div className='level is-mobile'>
        <div className='level-item'>
          <div>
            <span className='heading'>
              Reader
              <br />
              checkouts
            </span>
            <span className='title is-size-5'>{item.readerCheckouts}</span>
          </div>
        </div>
        <div className='level-item' style={{ marginLeft: '0.5rem' }}>
          <div>
            <span className='heading'>
              Other
              <br />
              checkouts
            </span>
            <span className='title is-size-5'>{item.otherCheckouts}</span>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

GridItemReader.propTypes = {
  item: PropTypes.object.isRequired
};

export default GridItemReader;
