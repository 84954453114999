import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WrapperComponent from '../../../helpers/WrapperComponent';

class FieldMsg {
  constructor(show = false, msg = '', type = '') {
    this.show = show;
    this.msg = msg;
    this.type = type;
  }
}

class State {
  constructor(number = '', pass = '', cpass = '') {
    this.number = number;
    this.pass = pass;
    this.cpass = cpass;
  }
}

const FpStepTwo = props => {
  const { onInputsChange, onResendNumber } = props;

  const [state, setState] = useState(new State());
  const [cPassFieldMsg, setCPassFieldMsg] = useState(new FieldMsg());

  onInputsChange(state);

  const onInputChange = (key, e) => {
    const { value } = e.target;
    if (key === 'number' && value.length > 6) return;
    setState({ ...state, [key]: value });
  };

  useEffect(
    () => {
      if (state.pass !== state.cpass) {
        setCPassFieldMsg({ show: true, msg: "Passwords don't match", type: ' is-danger' });
      } else if (state.pass === state.cpass && state.cpass !== '') {
        setCPassFieldMsg({ show: true, msg: 'Passwords match', type: ' is-success' });
      }
    },
    [state.cpass, state.pass]
  );

  useEffect(
    () => {
      console.log(state);
      onInputsChange(state);
    },
    [state]
  );

  return (
    <WrapperComponent>
      <div className='info'>
        You will receive a 6-digit number in your Email. <br />
        <span style={{ fontSize: '0.8em' }}>Note: It may take up to 5 minutes.</span>
      </div>
      <div className='has-text-centered has-text-primary' style={{ marginBottom: '0.3rem' }}>
        <span style={{ cursor: 'pointer' }} onClick={onResendNumber}>
          Didn't receive it yet? Resend.
        </span>
      </div>
      <div className='field'>
        <label className='label'>6-digit number</label>
        <div className='control'>
          <input
            type='number'
            className='input'
            placeholder='Enter the 6-digit number...'
            value={state.number}
            onChange={e => onInputChange('number', e)}
            autoFocus={true}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>New Password</label>
        <div className='control'>
          <input
            type='password'
            className='input'
            placeholder='Enter new password...'
            value={state.pass}
            onChange={e => onInputChange('pass', e)}
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Confirm Password</label>
        <div className='control'>
          <input
            type='password'
            className={`input${cPassFieldMsg.show ? cPassFieldMsg.type : ''}`}
            placeholder='Enter the new password again...'
            value={state.cpass}
            onChange={e => onInputChange('cpass', e)}
          />
          {cPassFieldMsg.show ? <p className={`help${cPassFieldMsg.type}`}>{cPassFieldMsg.msg}</p> : null}
        </div>
      </div>
    </WrapperComponent>
  );
};

FpStepTwo.propTypes = {
  onInputsChange: PropTypes.func.isRequired,
  onResendNumber: PropTypes.func.isRequired
};

export default FpStepTwo;
