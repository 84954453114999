import React from 'react';
import PropTypes from 'prop-types';
import './pagination.styles.scss';

const Page = props => {
  const { page, isCurrent, onPageSelect } = props;
  console.log('page no', page, isCurrent);
  return (
    <li>
      {page !== 0 ? (
        isCurrent ? (
          <span className='pagination-link is-current' style={{ cursor: 'default' }}>
            {page}
          </span>
        ) : (
          <span className='pagination-link' onClick={() => onPageSelect(page)}>
            {page}
          </span>
        )
      ) : (
        <span className='pagination-ellipsis'>&hellip;</span>
      )}
    </li>
  );
};

const Pagination = props => {
  const { page, limit, totalItems, onPageSelect } = props;
  let totalPages = Math.ceil(totalItems / limit);

  const getPageArray = () => {
    let array = [1];
    if (totalPages > 7) {
      switch (page) {
        case 1:
        case 2:
          array = [1, 2, 3, 0, totalPages];
          break;
        case 3:
          array = [1, 2, page, page + 1, 0, totalPages];
          break;
        case totalPages - 2:
          array = [1, 0, page - 1, page, page + 1, totalPages];
          break;
        case totalPages - 1:
        case totalPages:
          array = [1, 0, totalPages - 2, totalPages - 1, totalPages];
          break;
        default:
          array = [
            1,
            1 + 1 === page - 2 ? page - 2 : 0,
            page - 1,
            page,
            page + 1,
            totalPages - 1 === page + 2 ? page + 2 : 0,
            totalPages
          ];
      }
    } else {
      for (let i = 2; i <= totalPages; i++) {
        array.push(i);
      }
    }
    return array;
  };

  return (
    <nav className='pagination is-centered' role='navigation'>
      {page !== 1 ? (
        <span className='pagination-previous' onClick={() => onPageSelect(page - 1)}>
          <strong>&lt;</strong>
        </span>
      ) : null}
      {page !== totalPages ? (
        <span className='pagination-next' onClick={() => onPageSelect(page + 1)}>
          <strong>&gt;</strong>
        </span>
      ) : null}
      <ul className='pagination-list'>
        {getPageArray().map(arrayItem => (
          <Page page={arrayItem} isCurrent={page === arrayItem} onPageSelect={onPageSelect} />
        ))}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPageSelect: PropTypes.func.isRequired
};

export default Pagination;
