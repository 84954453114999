import React, { Component } from 'react';
import './style.css';
import { apiGet, apiPostUrlEncoded } from '../../helpers/Api';
import ListItem from './sub-components/list-item';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

class AssignBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = { foundItems: [], total: 0, untaggedTotal: 0 };
    this.inputTitleRef = React.createRef();
    this.inputAuthorRef = React.createRef();
    this.onListItemSelect = this.onListItemSelect.bind(this);
  }

  componentDidMount(): void {
    this.apiGetAssignTag();
  }

  apiGetAssignTag() {
    let itemLbpTitle = this.inputTitleRef.current.value.trim();
    let itemLbpAuthor = this.inputAuthorRef.current.value.trim();
    apiGet('/admin/item/assign-tag.php', {
      itemLbpTitle: itemLbpTitle,
      itemLbpAuthor: itemLbpAuthor
    })
      .then(res => {
        this.setState({
          ...this.state,
          foundItems: res.data.items,
          total: res.data.total,
          untaggedTotal: res.data.untaggedTotal
        });
      })
      .catch(err => {});
  }

  onSearchInputKeyUp(event) {
    if (event.keyCode === 13) this.apiGetAssignTag();
  }

  async onListItemSelect(title, copyNo, itdeId) {
    const MySwal = withReactContent(Swal);

    //Sweet Alert 2 config
    const swalConfig = {
      title: (
        <span className='title is-size-5 has-text-weight-light'>
          <span>{title}</span>
          <br />
          <span className='subtitle is-size-6'>Copy No. {copyNo}</span>
        </span>
      ),
      input: 'number',
      inputPlaceholder: 'Enter or scan barcode...',
      showCancelButton: true,
      allowOutsideClick: () => !MySwal.isLoading(),
      customClass: {
        input: 'input input-swal is-rounded',
        confirmButton: 'button is-primary is-rounded',
        cancelButton: 'button is-danger is-rounded'
      },
      inputValidator: value => {
        if (value.trim() !== '') {
          MySwal.showLoading();
          return new Promise(resolve => {
            apiPostUrlEncoded('/admin/item/assign-tag.php', { tag: value.trim(), itdeId: itdeId })
              .then(res => {
                if (res.status === 200) {
                  console.log(res.data);
                  resolve();
                }
              })
              .catch(() => {
                resolve('Failed to assign barcode');
              });
          });
        } else {
          return 'Please enter a barcode';
        }
      }
    };
    const { value: barcode } = await MySwal.fire(swalConfig);

    if (barcode) {
      //Toast with sweet alert 2
      const Toast = MySwal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 4000
      });
      Toast.fire({
        type: 'success',
        html: `Barcode ${barcode} has been assigned to <span class="title is-size-5 has-text-weight-light">${title}</span>`
      }).then(() => {
        this.inputTitleRef.current.value = '';
        this.inputAuthorRef.current.value = '';
        this.inputTitleRef.current.focus();
        // this.setState({
        //   ...this.state,
        //   foundItems: []
        // });
        this.apiGetAssignTag();
      });
    }
  }

  render() {
    const { foundItems, total, untaggedTotal } = this.state;
    return (
      <div className='container comp-container comp-assign-barcode center-column'>
        <div className='title'>Assign tag</div>
        <div className='is-paddingless is-vcentered is-centered title-author-input-container'>
          <div className='level is-mobile' style={{ width: '100%', maxWidth: '40em', margin: 'auto' }}>
            <div className='level-left' style={{ width: 'calc(100% - 8rem)' }}>
              <div style={{ width: '100%' }}>
                <input
                  type='text'
                  className='input is-primary is-rounded is-block title-input'
                  placeholder={'Enter title here...'}
                  ref={this.inputTitleRef}
                  onKeyUp={this.onSearchInputKeyUp.bind(this)}
                />
                <input
                  type='text'
                  className='input is-primary is-rounded is-block author-input'
                  placeholder={'Enter author here...'}
                  ref={this.inputAuthorRef}
                  onKeyUp={this.onSearchInputKeyUp.bind(this)}
                  style={{ marginTop: '1rem' }}
                />
              </div>
            </div>
            <div className='level-right'>
              <div className='button is-primary is-rounded' onClick={this.apiGetAssignTag.bind(this)}>
                SEARCH
              </div>
            </div>
          </div>
          <div className='has-background-grey-light has-text-right has-text-white' style={{ margin: '1rem 0', padding: '0.2rem 0.5rem' }}>
            <span>
              <strong className='has-text-white'>Total: </strong>
              {total}
            </span>
            <span style={{ marginLeft: '1rem' }}>
              <strong className='has-text-white'>Tagged: </strong>
              {total - untaggedTotal}
            </span>
          </div>
        </div>

        <div className='list-container'>
          {this.state.foundItems.map((foundItem, index) => (
            <ListItem id={index} listItem={foundItem} onSelect={this.onListItemSelect} />
          ))}
        </div>
      </div>
    );
  }
}

export default AssignBarcode;
