// @flow
import React from 'react';
import PropTypes from 'prop-types';
import getLocalDate from '../../../helpers/get-local-date';
import type { BorrowItem } from '../borrow.types';
import Moment from 'moment';

export type BorrowListItemProp = {
  item: BorrowItem,
  onClick: () => {}
};

const BorrowListItem = (props: BorrowListItemProp) => {
  const { item, onClick } = props;
  return (
    <div className='borrow-list-item list-item has-background-white-bis is-flex' onClick={onClick}>
      <div className='left-container column'>
        <div className='third-detail title is-size-5' style={{ marginBottom: 0 }}>
          <span className='has-text-weight-bold'>Title: </span> {item.borItemTitle}
        </div>
        <div className='borrow-no'>
          <span className='has-text-weight-bold'>File no.: </span> {item.borNumber ? item.borNumber : 'nil'}
        </div>
        <div className='org'>
          <span className='has-text-weight-bold'>Org: </span> {item.ooiName ? item.ooiName : 'nil'}{' '}
          {item.ooiEmail ? `(${item.ooiEmail})` : ''}
        </div>

        {item.ptrnId ? (
          <div className='fourth-detail'>
            <span className='has-text-weight-bold'>Assigned to: </span>
            {item.ptrnLastname ? `${item.ptrnFirstname} ${item.ptrnLastname} (${item.ptrnEmail})` : 'nil'}
          </div>
        ) : null}
      </div>
      <div className='right-container center-column'>
        <div>
          <div>
            <span className='has-text-weight-bold'>Borrowed on: </span>
            {Moment(item.borDate).format('MMMM DD, YYYY')}
          </div>
          {item.borReturned ? (
            <span>
            <span className='has-text-weight-bold'>Returned on: </span>
            <span className='has-text-success'>{Moment(item.borReturned).format('MMMM DD, YYYY')}</span>
          </span>
          ) : (
            <span>
            <span className='has-text-weight-bold'>Due back on: </span>
              {Moment(item.borDueBack).format('MMMM DD, YYYY')}
          </span>
          )}</div>
        {!!!item.borReturned ? <button className='button is-primary'>Return</button> : null}
      </div>
      {/*<div className='date-container subtitle is-size-5 is-flex' style={{ marginBottom: 0, justifyContent: 'space-between' }}>*/}
      {/*  <div className='bor-date'>*/}
      {/*    <span className='has-text-weight-bold'>Date: </span> {Moment(item.borDate).format('MMMM DD, YYYY')}*/}
      {/*  </div>*/}
      {/*  <div className='other-date' style={{ marginLeft: '1rem' }}>*/}
      {/*    {item.borReturned ? (*/}
      {/*      <span>*/}
      {/*        <span className='has-text-weight-bold'>Returned on: </span>*/}
      {/*        <span className='has-text-success'>{Moment(item.borReturned).format('MMMM DD, YYYY')}</span>*/}
      {/*      </span>*/}
      {/*    ) : (*/}
      {/*      <span>*/}
      {/*        <span className='has-text-weight-bold'>Due back on: </span>*/}
      {/*        {Moment(item.borDueBack).format('MMMM DD, YYYY')}*/}
      {/*      </span>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='second-detail is-flex'>*/}
      {/*  <div className='borrow-no'>*/}
      {/*    <span className='has-text-weight-bold'>File no.: </span> {item.borNumber ? item.borNumber : 'nil'}*/}
      {/*  </div>*/}
      {/*  <div className='org' style={{ marginLeft: '1rem' }}>*/}
      {/*    <span className='has-text-weight-bold'>Org: </span> {item.ooiName ? item.ooiName : 'nil'}{' '}*/}
      {/*    {item.ooiEmail ? `(${item.ooiEmail})` : ''}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='third-detail title is-size-5' style={{ marginBottom: 0 }}>*/}
      {/*  <span className='has-text-weight-bold'>Title: </span> {item.borItemTitle}*/}
      {/*</div>*/}
      {/*{item.ptrnId ? (*/}
      {/*  <div className='fourth-detail'>*/}
      {/*    <span className='has-text-weight-bold'>Assigned to: </span>*/}
      {/*    {item.ptrnLastname ? `${item.ptrnFirstname} ${item.ptrnLastname} (${item.ptrnEmail})` : 'nil'}*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </div>
  );
};

BorrowListItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default BorrowListItem;
