// flow
import React from 'react';
import PropTypes from 'prop-types';
import type { PartnerOrgType } from '../../../models/PartnerOrg';

type PropType = {
  partner: PartnerOrgType,
  bkColorFlag: boolean,
  onClick(): void
};

const ListItemPartner = (props: PropType) => {
  const { partner, bkColorFlag, onClick } = props;
  return (
    <li
      className={`list-item list-item-partner is-hoverable ${
        bkColorFlag ? 'has-background-white-bis' : 'has-background-white-ter'
      }`}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div className='level-item'>
            <span className='title is-size-6'>{partner.ooiName}</span>
          </div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <div>
              <div className='button is-primary is-outlined is-rounded' onClick={onClick}>
                View
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

ListItemPartner.propTypes = {
  partner: PropTypes.object.isRequired,
  bkColorFlag: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default ListItemPartner;
