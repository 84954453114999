import React from 'react';
import './style.css';
import logo from '../../assets/blast_custom_logo.png';

export default function Footer(props) {
  const { isLoggedIn } = props;
  const getUpperFooter = () => {
    return !isLoggedIn ? (
      <div className='footer-top columns is-tablet'>
        <div className='column'>
          <div className='title has-text-grey-dark'>More about us</div>
          <p>
            For over 15 years Beacontree Technologies has brought together an extraordinary level of experience and expertise in
            both the technical and business aspects of RFID innovation, application and implementation.
          </p>
        </div>
        <div className='column'>
          <div className='title has-text-grey-dark'>Contact Information</div>
          <ul>
            <li>
              <i className='fas fa-map-marker-alt' /> 1141 Broadview Ave, Suite 200 Toronto, Ontario M4K-2S6 Canada
            </li>
            <li>
              <i className='fas fa-phone' />
              +1 416-423-2900
            </li>
            <li>
              <i className='fas fa-envelope' />
              info@beacontree.net
            </li>
          </ul>
        </div>
      </div>
    ) : null;
  };
  return (
    <footer className='footer is-paddingless has-background-grey-lighter has-text-grey'>
      {getUpperFooter()}
      <div className='footer-foot columns has-text-light is-vcentered is-mobile darken'>
        <div className='column is-paddingless is-1'>
          <img src={logo} alt='BLAST' className='logo-footer' />
        </div>
        <div className='footer-copyright column is-paddingless is-4'>
          <span>Copyright &copy; 2019 Beacontree Technologies</span>
        </div>
        <div className='column is-paddingless right'>
          <span className='footer-right-column'>Terms</span>
          <span className='footer-right-column'>Privacy Policy and Cookie Policy</span>
        </div>
      </div>
    </footer>
  );
}
