// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from './dropdown-item.model';
import DropdownItemComp from './dropdown-item.comp';

opaque type DropdownProps = {
  items: DropdownItem[],
  onSelect: (item: DropdownItem, index: number) => null
};

opaque type StateItem = {
  key: number,
  value: DropdownItem
};
opaque type DropdownState = {
  items: DropdownItem[]
};

class Dropdown extends Component<DropdownProps, DropdownState> {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items
    };
  }

  onItemClicked = (key: number) => {
    const { onSelect } = this.props;
    const { items } = this.state;
    this.setState(
      state => {
        const { items } = state;
        for (const item of items) {
          item.isActive = false;
        }
        items[key].isActive = true;
        return { items };
      },
      () => {
        onSelect(items[key], key);
      }
    );
  };

  render() {
    const { items } = this.state;
    const activeItem = items.filter(item => item.isActive);
    return (
      <div className='dropdown is-hoverable'>
        <div className='dropdown-trigger'>
          <button className='button' aria-haspopup='true' aria-controls='dropdown-menu'>
            <span>{activeItem[0] ? activeItem[0].title : 'Select an option'}</span>
            <span className='icon is-small'>
              <i className='fas fa-angle-down' aria-hidden='true' />
            </span>
          </button>
        </div>
        <div className='dropdown-menu' id='dropdown-menu' role='menu'>
          <div className='dropdown-content'>
            {items.map((item, index) => {
              return <DropdownItemComp key={index} index={index} item={item} onClick={this.onItemClicked} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf(DropdownItem).isRequired,
  defaultSelectedItemIndex: PropTypes.number,
  onSelect: PropTypes.func
};

export default Dropdown;
