import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuuriGrid from 'react-muuri';
import './style.css';
import GridItem from './sub-components/grid-item';
import { apiGet } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';
import WrapperComponent from '../../helpers/WrapperComponent';
import Grid from './sub-components/grid';
import Moment from 'moment';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.refDashboardGrid = React.createRef();
    this.state = {
      numbersData: null,
      isLoading: true
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.state.numbersData !== null) {
      this.grid = new MuuriGrid({
        node: this.gridElement,
        defaultOptions: {
          dragEnabled: true,
          dragStartPredicate: {
            handle: '.item-header'
          },
          layout: {
            fillGaps: true
          }
        }
      });
      this.grid.getEvent('dragEnd', (item, event) => {
        console.log(item, event);
      });
    }
  }

  componentDidMount(): void {
    // console.log('grid', this.refDashboardGrid.current);
    this.apiGetNumbers();
    console.log('grid element', this.gridElement);

    /*const grid = new Muuri('.comp-container.dashboard-grid', {
      layoutDuration: 400,
      layoutEasing: 'ease',
      dragEnabled: true,
      dragSortInterval: 0,
      dragReleaseDuration: 400,
      dragReleaseEasing: 'ease'
    });*/
  }

  componentWillUnmount(): void {
    // this.grid.getMethod('destroy');
  }

  apiGetNumbers = () => {
    apiGet('admin/dashboard/get-numbers.php', null)
      .then(res => {
        this.setState({
          numbersData: res.data,
          isLoading: false
        });
      })
      .catch(() => {
        showAlert({ type: 'error', title: 'Failed to fetch data' });
        this.setState({
          isLoading: false
        });
      });
  };

  render() {
    const { numbersData } = this.state;
    return (
      <div className='comp-container comp-dashboard'>
        <div className='dashboard-grid' ref={gridElement => (this.gridElement = gridElement)}>
          {numbersData !== null ? <Grid numbersData={numbersData} /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
