// @flow
export class DropdownItem {
  id: any;
  title: string;
  hasDividerAfter: string;
  isActive: boolean;

  constructor(id: *, title: string, hasDividerAfter: string = false, isActive: boolean = false) {
    this.id = id;
    this.title = title;
    this.hasDividerAfter = hasDividerAfter;
    this.isActive = isActive;
  }
}
