// styles
import './lend-history.styles.scss';

// js
import React, { useState, useEffect } from 'react';
import HistoryOptions from '../_helpers/history-options/history-options.comp';
import { apiGet } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';
import LendHistoryListItem from './lend-history-list-item/lhli.component';

const LendHistoryPage = ({ location }) => {
  const { state } = location;
  const [reqState, setReqState] = useState({
    ooiName: '',
    startDate: null,
    endDate: null,
    currentReturnedAll: state ? state.filterBy : 0
  });

  const [lendHistoryRes, setLendHistoryRes] = useState({
    total: 0,
    currentTotal: 0,
    returnedTotal: 0,
    list: []
  });

  useEffect(() => {
    apiGetLendHistory();
  }, [reqState]);

  const apiGetLendHistory = () => {
    apiGet('/admin/partner/get-lend-history.php', reqState)
      .then(res => setLendHistoryRes(res.data))
      .catch(() => {
        showAlert({ type: 'error', title: 'Failed to fetch lend history' });
      });
  };

  const handleOnInputChange = (startDate, endDate, currentReturnedAll) => {
    console.log(startDate, endDate, currentReturnedAll);
    setReqState({
      ...reqState,
      startDate,
      endDate,
      currentReturnedAll
    });
  };

  return (
    <div className='comp-container lend-history-comp'>
      <div className='comp-header'>
        <div className='title'>Lend History</div>
      </div>
      <div className='comp-body'>
        <div className='options-container'>
          <HistoryOptions
            startDate={reqState.startDate}
            endDate={reqState.endDate}
            filterBy={reqState.currentReturnedAll}
            onChange={handleOnInputChange}
            isLendHistory={true}
          />
        </div>
        <div className='info-bar is-flex has-background-grey-light has-text-white'>
          <span className='left-part total'>
            Total items: <em>{lendHistoryRes.total}</em>
          </span>
          <div className='right-part is-flex'>
            <span className='current'>
              Currently lent: <em>{lendHistoryRes.currentTotal}</em>
            </span>
            <span className='returned'>
              Returned: <em>{lendHistoryRes.returnedTotal}</em>
            </span>
          </div>
        </div>
        <div className='lend-history-list list' style={{ marginTop: '1rem' }}>
          {lendHistoryRes.list.length ? (
            lendHistoryRes.list.map((item, index) => <LendHistoryListItem key={index} item={item} />)
          ) : (
            <div className='list-item has-text-centered subtitle'>Nothing to show</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LendHistoryPage;
