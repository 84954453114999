/* flow */
import Uuidv4 from 'uuid/v4';

export class ItemCopy {
  id: string;
  itdeId: string;
  itdeCopy: number;
  itdeBarcode: string;
  itdeCallNumber: string;
  itdeIsbn: string;
  itloId: string;
  itdeNotes: string;
  itdeLendLimit: number;
  itdeRenews: number;
  itdeRenewPeriod: number;

  constructor(
    itdeCopy: string = 1,
    itdeBarcode: string = '',
    itdeCallNumber: string = '',
    itdeIsbn: string = '',
    itloId: string = null,
    itdeNotes: string = '',
    itdeLendLimit: number = 7,
    itdeRenews: number = 3,
    itdeRenewPeriod: number = 7
  ) {
    this.id = new Uuidv4();
    this.itdeCopy = itdeCopy;
    this.itdeBarcode = itdeBarcode;
    this.itdeCallNumber = itdeCallNumber;
    this.itdeIsbn = itdeIsbn;
    this.itloId = itloId;
    this.itdeNotes = itdeNotes;
    this.itdeLendLimit = itdeLendLimit;
    this.itdeRenews = itdeRenews;
    this.itdeRenewPeriod = itdeRenewPeriod;
  }
}
export class AddItemReq {
  itemId: string;
  itemLbpTitle: string;
  itemLbpAuthor: string;
  itemCorAuthor: string;
  itemLbpPublisher: string;
  itemVol: string;
  volValue: string;
  itemLbpDescription: string;
  ittyId: string;
  itemDetail1: string;
  itemDetail2: string;
  itemDetail3: string;
  itemDetail4: string;
  itemLeadResource: string;
  itemCopies: ItemCopy[];
  availableCopiesList: ItemCopy[];
  unavailableCopiesList: ItemCopy[];

  constructor() {
    this.itemId = '';
    this.itemLbpTitle = '';
    this.itemLbpAuthor = '';
    this.itemCorAuthor = '';
    this.itemLbpPublisher = '';
    this.volValue = '';
    this.itemVol = this.volValue;
    this.itemLbpDescription = '';
    this.ittyId = null;
    this.itemDetail1 = '';
    this.itemDetail2 = '';
    this.itemDetail3 = '';
    this.itemDetail4 = '';
    this.itemLeadResource = '';
    this.itemCopies = [new ItemCopy()];
    this.availableCopiesList = [];
    this.unavailableCopiesList = [];
  }

  merge(obj) {
    if (obj) {
      this.itemId = obj.itemId ? obj.itemId : '';
      this.itemLbpTitle = obj.itemLbpTitle ? obj.itemLbpTitle : '';
      this.itemLbpAuthor = obj.itemLbpAuthor ? obj.itemLbpAuthor : '';
      this.itemCorAuthor = obj.itemCorAuthor ? obj.itemCorAuthor : '';
      this.itemLbpPublisher = obj.itemLbpPublisher ? obj.itemLbpPublisher : '';
      this.itemVol = obj.itemVol ? obj.itemVol : '';
      this.itemLbpDescription = obj.itemLbpDescription ? obj.itemLbpDescription : '';
      this.ittyId = obj.ittyId ? obj.ittyId : null;
      this.itemDetails1 = obj.itemDetails1 ? obj.itemDetails1 : '';
      this.itemDetails2 = obj.itemDetails2 ? obj.itemDetails2 : '';
      this.itemDetails3 = obj.itemDetails3 ? obj.itemDetails3 : '';
      this.itemDetails4 = obj.itemDetails4 ? obj.itemDetails4 : '';
      this.itemLeadResource = obj.itemLeadResource ? obj.itemLeadResource : '';
      this.itemCopies = obj.itemCopies ? obj.itemCopies : [];
      this.availableCopiesList = obj.availableCopiesList ? obj.availableCopiesList : [];
      this.unavailableCopiesList = obj.unavailableCopiesList ? obj.unavailableCopiesList : [];
    }
  }
}
