// flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './patron-details.styles.scss';
import { apiGet } from '../../helpers/Api';
import WrapperComponent from '../../helpers/WrapperComponent';
import Loader from '../../layout/loader/loader';
import { ROUTES } from '../../constants';
import PatronEditComp from '../_helpers/patron-edit/patron-edit.component';

class PatronDetailsComp extends Component {
  constructor(props) {
    super(props);

    const { selectedPatron } = this.props.location.state || { selectedPatron: null };
    this.state = {
      patron: selectedPatron,
      isLoading: false,
      isEditing: false
    };
    this.ptrnId = this.props.match.params.ptrnId;
  }

  componentDidMount(): void {
    const { history, match } = this.props;
    console.log(history);
    console.log('Match', match.params.ptrnId);
    if (!this.state.patron) this.apiGetPatronDetails();
  }

  apiGetPatronDetails = () => {
    this.setState({ isLoading: true }, () => {
      apiGet('admin/patron/get-patrons.php', { ptrnId: this.ptrnId })
        .then(res => {
          this.setState({ isLoading: false, patron: res.data.patrons[0] });
        })
        .catch(err => {
          this.setState({ isLoading: false });
        });
    });
  };

  onEditToggle = () => {
    this.setState(state => ({ isEditing: !state.isEditing }));
  };

  afterSave = () => {
    this.apiGetPatronDetails();
    this.setState({
      isEditing: false
    });
  };

  getView = () => {
    const { patron, isEditing } = this.state;
    const { loggedInPatron } = this.props;

    console.log('Logged in patron', loggedInPatron);

    const getTopbarLabel = () => {
      if (isEditing) {
        return <span>Edit patron</span>;
      }
      if (patron.ptrnIsAdmin) {
        return <span className='has-text-success'>ADMIN</span>;
      } else {
        return <span className='has-text-danger'>NOT ADMIN</span>;
      }
    };

    if (patron) {
      return (
        <WrapperComponent>
          <div className='comp-header'>
            <span className='title'>Patron details</span>
          </div>
          <div className='patron-details-comp'>
            <div className='box'>
              <div className='patron-details-view-container'>
                <div className='details-container'>
                  <div className='top-bar'>
                    <div className='title is-size-4 admin-label'>{getTopbarLabel()}</div>
                    {!(loggedInPatron.ptrnId == patron.ptrnId) ? (
                      <div className='btn-group button is-circle is-rounded' onClick={this.onEditToggle}>
                        <i className={`fas ${isEditing ? 'fa-times' : 'fa-edit'}`} />
                      </div>
                    ) : null}
                  </div>
                  {isEditing ? (
                    <PatronEditComp patron={patron} afterSave={this.afterSave} />
                  ) : (
                    <div className='list has-background-white-bis'>
                      <div className='list-item'>
                        <span className='title is-size-5'>ID: </span>
                        <br />
                        <span className='is-size-5'>{patron.ptrnId}</span>
                      </div>
                      <div className='list-item'>
                        <span className='title is-size-5'>Name: </span>
                        <br />
                        <span className='is-size-5'>
                          {patron.ptrnFirstname} {patron.ptrnLastname}
                        </span>
                      </div>
                      <div className='list-item'>
                        <span className='title is-size-5'>Email: </span>
                        <br />
                        <span className='is-size-5'>{patron.ptrnEmail}</span>
                      </div>
                    </div>
                  )}
                </div>
                {!isEditing ? (
                  <Link
                    to={{ pathname: `${ROUTES.history}/${this.ptrnId}`, state: { selectedPatron: patron } }}
                    className='button center is-info is-outlined show-history-button'>
                    SHOW HISTORY
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </WrapperComponent>
      );
    } else {
      return <Loader title='Loading patron details...' active={true} />;
    }
  };

  render() {
    const { patron } = this.state;
    return <div className='comp-container'>{this.getView()}</div>;
  }
}

PatronDetailsComp.defaultProps = {
  selectedPatron: null
};

PatronDetailsComp.propTypes = {
  selectedPatron: PropTypes.object
};

const mapStateToProps = state => ({
  loggedInPatron: state.patronReducer.patron
});

export default connect(mapStateToProps)(PatronDetailsComp);
