import { lendReducer } from '../reducers/lend.reducer';
import { apiGet, apiPostJson } from '../../helpers/Api';
import { lendTypes } from '../types';
import PostScanActions from './post-scan.actions';
import { showAlert, showToast } from '../../helpers/Alert';

const lendStart = orgDetails => async (dispatch, getState) => {
  if (!orgDetails) {
    showAlert({ type: 'warning', title: 'Please select the organization' });
    return;
  }
  const {
    lendReducer: { lendList }
  } = getState();
  dispatch({ type: lendTypes.LEND_ITEM_START });
  try {
    const data = await apiPostJson('/admin/partner/v2/lend.php', { items: lendList, ooiId: orgDetails.value });
    dispatch({ type: lendTypes.LEND_ITEM_SUCCESS });
    showAlert({ type: 'success', title: 'Lend success' });
  } catch (e) {
    showAlert({ type: 'error', title: 'Lend failed' });
  }
};

const isItemAlreadyPresent = (itemBarcode, lendList) => {
  const foundItem = lendList.find(item => item.itdeBarcode === itemBarcode);
  return !!foundItem;
};

const addLendItem = (itemBarcode, lendDays, lendFileNo = null) => async (dispatch, getState) => {
  itemBarcode = itemBarcode.trim();
  const {
    lendReducer: { isLoading, lendList }
  } = getState();

  if (isLoading) return;

  if (isItemAlreadyPresent(itemBarcode, lendList)) {
    showAlert({ type: 'warning', title: 'Item already added to the list' });
    return;
  }

  try {
    const data = await dispatch(PostScanActions.postScanStart(itemBarcode));
    if (data.isAvailable) {
      showToast({ type: 'success', title: 'Added item to list' });
      dispatch({ type: lendTypes.ADD_LEND_ITEM, payload: { ...data, lendDays, lendFileNo } });
    } else {
      showAlert({ type: 'error', title: 'This item is not available. Please check it in first.' });
    }
  } catch (e) {}
};

const removeLendItem = itdeBarcode => dispatch => {
  dispatch({ type: lendTypes.LEND_ITEM_REMOVE, payload: itdeBarcode });
};

const fetchOrgList = () => async dispatch => {
  dispatch({ type: lendTypes.LEND_FETCH_ORG_LIST_START });
  try {
    const res = await apiGet('/admin/partner/partner-org.php');
    const data = await res.data;
    dispatch({ type: lendTypes.LEND_FETCH_ORG_LIST_SUCCESS, payload: data.partnerOrgs });
    return data;
  } catch (e) {
    dispatch({ type: lendTypes.LEND_FETCH_ORG_LIST_FAILURE });
    showAlert({ type: 'error', title: 'Failed to fetch organizations' });
    throw e;
  }
};

const parseDropdownList = list => {
  const parsedList = [];
  for (const item of list) {
    parsedList.push({ key: item.ooiId, value: item.ooiId, text: item.ooiName });
  }
  console.log('parsed dropdown list', parsedList);
  return parsedList;
};

const getOrgDropdownList = () => async (dispatch, getState) => {
  const {
    lendReducer: { orgList }
  } = getState();

  if (orgList.list.length === 0) {
    try {
      const data = await dispatch(fetchOrgList());
      return parseDropdownList(data.partnerOrgs);
    } catch (e) {}
  } else {
    return parseDropdownList(orgList.list);
  }
};

const LendActions = {
  addLendItem,
  removeLendItem,
  lendStart,
  fetchOrgList: fetchOrgList,
  getOrgDropdownList
};

export default LendActions;
