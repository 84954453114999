import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../../assets/blast_custom_logo.png';
import { Link } from 'react-router-dom';
import SideNavbarUser from './SideNavbarUser';

import './style.css';
import { ROUTES } from '../../../constants';

function NavbarUser(props) {
  const isCurrent = pathname => {
    return pathname === props.pathname ? ' has-text-weight-bold' : '';
  };
  const blockLink = {
    display: 'block'
  };
  const adminMenu = () => {
    return props.patron.ptrnIsAdmin ? (
      <div className='navbar-item custom-dropdown-nav-item'>
        Admin
        <i className='fas fa-caret-down' style={{ paddingLeft: '0.5em' }} />
        <div className='custom-dropdown custom-dropdown-menu left-column has-background-white has-text-black-bis'>
          <Link
            to={ROUTES.admin.dashboard}
            className='custom-dropdown-item dropdown-parent-item dropdown-imp has-text-black'
            style={blockLink}>
            <span>Dashboard</span>
          </Link>
          <div className='custom-dropdown-item dropdown-parent-item'>
            <span>Items</span> <i className='fas fa-caret-right' />
            <div className='custom-dropdown custom-subdropdown-menu has-background-white'>
              <Link to={ROUTES.admin.viewItems} className='custom-dropdown-item dropdown-child-item' style={blockLink}>
                View items
              </Link>
              <Link
                to={ROUTES.admin.addItem}
                onClick={window.location.reload}
                className='custom-dropdown-item dropdown-child-item'
                style={blockLink}>
                Add Items
              </Link>
            </div>
          </div>
          <div className='custom-dropdown-item dropdown-parent-item'>
            <span>Patrons</span> <i className='fas fa-caret-right' />
            <div className='custom-dropdown custom-subdropdown-menu has-background-white'>
              <Link to={ROUTES.admin.viewPatrons} className='custom-dropdown-item dropdown-child-item' style={blockLink}>
                View Patrons
              </Link>
              <Link to={ROUTES.admin.addPatron} className='custom-dropdown-item dropdown-child-item' style={blockLink}>
                Register Patrons
              </Link>
            </div>
          </div>
          <Link
            to={ROUTES.admin.allTransactions}
            className='custom-dropdown-item dropdown-parent-item dropdown-imp has-text-black'
            style={blockLink}>
            <span>All transactions</span>
          </Link>
          <Link to={ROUTES.admin.more} className='custom-dropdown-item dropdown-parent-item has-text-black' style={blockLink}>
            <span>ILL</span>
          </Link>
        </div>
      </div>
    ) : null;
  };

  return (
    <React.Fragment>
      <nav className='navbar is-primary'>
        <div className='navbar-brand'>
          <Link className='navbar-item' to={'/'}>
            <img src={logo} alt='BLAST' style={{ height: '3em' }} />
          </Link>

          <div
            className={`navbar-item navbar-burger center hamburger hamburger--vortex-r ${
              props.isSideNavbarVisible ? 'is-active' : ''
            } is-hidden-desktop`}
            role='button'
            aria-label='menu'
            aria-expanded='false'
            aria-controls='navigation'
            onClick={props.onNavbarBurgerClick}>
            {/*<i className='fas fa-bars' />*/}
            <div className='hamburger-box'>
              <div className='hamburger-inner' />
            </div>
          </div>
        </div>
        <div className='navbar-menu'>
          <div className='navbar-start'>
            <Link className={`navbar-item${isCurrent(ROUTES.index)}`} to={ROUTES.index}>
              Home
            </Link>
            <Link className={`navbar-item${isCurrent(ROUTES.checkout)}`} to={ROUTES.checkout}>
              Check-out
            </Link>
            {props.patron.ptrnIsAdmin ? (
              <Link className={`navbar-item${isCurrent(ROUTES.admin.checkin)}`} to={ROUTES.admin.checkin}>
                Check-in
              </Link>
            ) : null}
            <Link className={`navbar-item${isCurrent(ROUTES.browse)}`} to={ROUTES.browse}>
              Browse
            </Link>
            <Link className={`navbar-item${isCurrent(ROUTES.history)}`} to={ROUTES.history}>
              History
            </Link>
            {adminMenu()}
          </div>
          <div className='navbar-end'>
            <div className='navbar-item custom-dropdown-nav-item custom-dropdown-right'>
              <span style={{ fontSize: '1.2em', marginRight: '1rem' }}>
                Welcome, {props.patron.ptrnFirstname} {props.patron.ptrnIsAdmin ? '[Admin]' : ''}
              </span>
              <i className='fas fa-user-circle is-size-4' />
              <div className='custom-dropdown custom-dropdown-menu left-column has-background-white has-text-black-bis'>
                <div
                  className='custom-dropdown-item dropdown-imp-unselectable'
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <span className='title is-size-6'>{props.patron.ptrnFirstname + ' ' + props.patron.ptrnLastname}</span>
                  <br />
                  <span className='subtitle is-size-6'>{props.patron.ptrnEmail}</span>
                </div>
                <Link to={ROUTES.account} className='custom-dropdown-item dropdown-parent-item has-text-black'>
                  Account
                </Link>
                <Link to={ROUTES.logout} className='custom-dropdown-item dropdown-parent-item has-text-black'>
                  Log out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <SideNavbarUser patron={props.patron} pathname={props.pathname} isSideNavbarVisible={props.isSideNavbarVisible} />
    </React.Fragment>
  );
}

NavbarUser.propTypes = {};

export default NavbarUser;
