import React from 'react';
import PropTypes from 'prop-types';

const ListItemAcc = props => {
  const { header, children } = props;
  return (
    <div className='list-item is-paddingless'>
      <div className='subtitle is-size-6 has-background-grey-lighter is-marginless' style={{ padding: '1.2rem 0 0.5rem 1rem' }}>
        {header}
      </div>
      <div className='content'>
        <div className='list is-radiusless'>{children}</div>
      </div>
    </div>
  );
};

ListItemAcc.propTypes = {
  header: PropTypes.string.isRequired
};
export default ListItemAcc;
