import React from 'react';
import PropTypes from 'prop-types';

const UnavailableCopyItem = props => {
  const { itemCopy, itemLocations } = props;
  // noinspection JSUnresolvedVariable
  return (
    <li className='list-item copy-item has-background-white-bis'>
      <div className='title is-size-5'>Copy {itemCopy.itdeCopy}</div>
      <div>
        {itemCopy.itdeBarcode && !itemCopy.itdeBarcode.includes('NOTAG', 0) ? (
          <div>
            <strong>Barcode: </strong>
            {itemCopy.itdeBarcode}
          </div>
        ) : null}
        {itemCopy.itdeCallNumber ? (
          <div>
            <strong>Call Number: </strong>
            {itemCopy.itdeCallNumber}
          </div>
        ) : null}
        {itemCopy.itdeIsbn ? (
          <div>
            <strong>ISBN: </strong>
            {itemCopy.itdeIsbn}
          </div>
        ) : null}
        {itemCopy.itloId ? (
          <div>
            <strong>Location: </strong>
            {itemLocations.find(location => location.itloId === itemCopy.itloId).itloName}
          </div>
        ) : null}
        {itemCopy.itdeNotes ? (
          <div>
            <strong>Notes: </strong>
            {itemCopy.itdeNotes}
          </div>
        ) : null}
        {itemCopy.itdeLendLimit ? (
          <div>
            <strong>Lend Limit: </strong>
            {itemCopy.itdeLendLimit}
          </div>
        ) : null}
        {itemCopy.itdeRenews ? (
          <div>
            <strong>Number of Renews: </strong>
            {itemCopy.itdeRenews}
          </div>
        ) : null}
        {itemCopy.itdeRenewPeriod ? (
          <div>
            <strong>Renew Period: </strong>
            {itemCopy.itdeRenewPeriod}
          </div>
        ) : null}
      </div>
    </li>
  );
};

UnavailableCopyItem.propTypes = {
  itemCopy: PropTypes.object.isRequired,
  itemLocations: PropTypes.array.isRequired
};

export default UnavailableCopyItem;
