import React from 'react';
import PropTypes from 'prop-types';

import './item-details-copy.styles.scss';

const ItemDetailsCopy = ({ copy, onClick }) => {
  const { itdeId, itdeCopy, itdeBarcode, available } = copy;

  const [isLoading, setIsLoading] = React.useState(false);
  const handleOnClick = () => {
    setIsLoading(true);
    onClick(itdeId);
  };

  return (
    <div className='item-details-copy list-item' onClick={handleOnClick}>
      <div className='copy-header-container'>
        <div className='copy-title-container'>
          <div>
            <i className={`fas ${available ? 'fa-check has-text-success' : 'fa-times has-text-danger'}`} /> Copy {itdeCopy}{' '}
          </div>
          {isLoading ? <div className='ld ld-ring ld-spin' style={{ fontSize: '1em' }} /> : null}
        </div>
      </div>
      <div className='other-details-container'>
        <div className='barcode-container'>{itdeBarcode ? itdeBarcode : 'No barcode'}</div>
      </div>
    </div>
  );
};

ItemDetailsCopy.propTypes = {
  copy: PropTypes.object.isRequired
};

export default ItemDetailsCopy;
