import React, { Component } from 'react';

class Registration extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='container'
           style={{ height: '60vh' }}>
        <h1>Registration page</h1>
      </div>
    );
  }
}

export default Registration;