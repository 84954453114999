import React from 'react';
import PropTypes from 'prop-types';
import GridItem from '../grid-item';
import { ROUTES } from '../../../../constants';
import { Link } from 'react-router-dom';
import Moment from 'moment';

const GridItemTrans = props => {
  const { item } = props;
  const pathname = ROUTES.admin.allTransactions;
  const today = Moment().format('YYYY-MM-DD');
  const yesterday = Moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const sevenDays = Moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD');
  const thirtyDays = Moment()
    .subtract(30, 'days')
    .format('YYYY-MM-DD');
  return (
    <GridItem header={'Transaction'} color={'is-teal'}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div>
            <Link to={{ pathname, state: { filterBy: 0 } }}>
              <span className='heading'>Total</span>
              <span className='title is-size-4'>{item.total}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <Link to={{ pathname, state: { startDate: today, endDate: today, filterBy: 0 } }}>
                  <span className='heading'>Today</span>
                  <span className='title is-size-5'>{item.today}</span>
                </Link>
              </div>
            </div>
            <div className='level-item' style={{ marginLeft: '0.5rem' }}>
              <div>
                <Link to={{ pathname, state: { startDate: yesterday, endDate: yesterday, filterBy: 0 } }}>
                  <span className='heading'>Yesterday</span>
                  <span className='title is-size-5'>{item.yesterday}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <Link to={{ pathname, state: { startDate: sevenDays, filterBy: 0 } }}>
                  <span className='heading'>7 Days</span>
                  <span className='title is-size-5'>{item['7days']}</span>
                </Link>
              </div>
            </div>
            <div className='level-item' style={{ marginLeft: '0.5rem' }}>
              <div>
                <Link to={{ pathname, state: { startDate: thirtyDays, filterBy: 0 } }}>
                  <span className='heading'>30 Days</span>
                  <span className='title is-size-5'>{item['30days']}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

GridItemTrans.propTypes = {
  item: PropTypes.object.isRequired
};

export default GridItemTrans;
