import { LOGIN, LOGOUT, VERIFY, PATRON_CHANGE_EMAIL } from '../types';

/* Test object
{
    ptrnFirstname: 'John',
    ptrnLastname: 'Shepard',
    ptrnEmail: 'test@gmail.com',
    ptrnId: '12312312',
    ptrnIsAdmin: true
}*/
const initialState = {
  patron: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN:
      return { ...state, patron: payload };
    case LOGOUT:
      return { patron: null };
    case PATRON_CHANGE_EMAIL:
      const patron = { ...state.patron };
      patron.ptrnEmail = payload;
      return { ...state, patron: patron };
    case VERIFY:
      return { ...state, patron: payload };
    default:
      return state;
  }
}
