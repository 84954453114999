import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './item-details.styles.scss';
import { apiGet, apiPostUrlEncoded } from '../../helpers/Api';
import { showAlert, showToast } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';
import ItemDetailsCopy from '../_helpers/item-details-copy/item-details-copy.component';
import ItemCopyDetails from '../_helpers/item-copy-details/item-copy-details.component';
import { setBrowseShouldRefresh } from '../../redux/actions/browse-action';

class ItemDetailsPage extends Component {
  state = {
    item: null,
    selectedItemCopy: null,
    total: 0,
    availableTotal: 0,
    visibleItemCopyDetail: null
  };

  itemDetailLabelArray = [
    { key: 'itemId', label: 'Item Id' },
    { key: 'itemVol', label: 'Volume' },
    { key: 'itemLbpAuthor', label: 'Author' },
    { key: 'itemCorAuthor', label: 'Corporate author' },
    { key: 'ittyName', label: 'Type' },
    { key: 'itemLbpPublisher', label: 'Publisher' },
    { key: 'itemLbpDescription', label: 'Description' },
    { key: 'itemDetail1', label: 'Detail 1' },
    { key: 'itemDetail2', label: 'Detail 2' },
    { key: 'itemDetail3', label: 'Detail 3' },
    { key: 'itemDetail4', label: 'Detail 4' },
    { key: 'itemLeadResource', label: 'Lead Resource' }
  ];

  componentDidMount(): void {
    const {
      match: { params }
    } = this.props;
    console.log('item-details.component', this.props);

    apiGet('/item/item-details.php', { itemId: params.itemId })
      .then(res => {
        console.log(res.data);
        const result = res.data;
        const total = result.availableCopiesList.length + result.unavailableCopiesList.length;
        const availableTotal = result.availableCopiesList.length;
        let selectedItemCopy = null;
        if (params.itdeId) {
          selectedItemCopy =
            res.data.availableCopiesList.find(copy => copy.itdeId == params.itdeId) ||
            res.data.unavailableCopiesList.find(copy => copy.itdeId == params.itdeId);

          result.availableCopiesList = result.availableCopiesList.filter(copy => copy.itdeId != params.itdeId);
          result.unavailableCopiesList = result.unavailableCopiesList.filter(copy => copy.itdeId != params.itdeId);
        }

        this.setState({
          item: result,
          selectedItemCopy: selectedItemCopy,
          availableTotal: availableTotal,
          total: total
        });
      })
      .catch(() => showAlert({ type: 'error', title: 'Failed to fetch item details' }));
  }

  handleOnCopyClick = itdeId => {
    apiGet('/item/item-copy-details.php', { itdeId })
      .then(res => {
        this.setState({ visibleItemCopyDetail: res.data });
      })
      .catch(() => {
        showAlert({
          type: 'error',
          title: 'Failed to fetch item copy info'
        });
      });
  };

  handleOnCopyDetailsBack = () => {
    this.setState({ visibleItemCopyDetail: null });
  };

  handleFavouriteClick = () => {
    const { item } = this.state;
    const { setBrowseShouldRefresh } = this.props;
    apiPostUrlEncoded('/patron/add-to-favourite.php', { itemId: item.itemId })
      .then(res => {
        /** @namespace res.body.didInsert */
        setBrowseShouldRefresh(true);
        this.setState(state => ({ item: { ...state.item, favourite: res.data.didInsert } }));
        showToast({ type: 'success', title: res.data.didInsert ? 'Added to favourites' : 'Removed from favourites' });
      })
      .catch(() => showAlert({ type: 'error', title: 'Failed to set favourite' }));
  };

  handleCopiesContainerRender = () => {
    const { item, visibleItemCopyDetail, selectedItemCopy } = this.state;
    if (!visibleItemCopyDetail) {
      const { availableCopiesList, unavailableCopiesList } = item;
      return (
        <React.Fragment>
          {selectedItemCopy ? (
            <div className='copies-container selected-item-copy-container'>
              <div className='copies-container-header'>Selected Copy</div>
              <div className='list'>
                <ItemDetailsCopy copy={selectedItemCopy} onClick={this.handleOnCopyClick} />
              </div>
            </div>
          ) : null}
          {availableCopiesList.length + unavailableCopiesList.length > 0 ? (
            <div className='copies-container'>
              <div className='copies-container-header'>{!selectedItemCopy ? 'Copies' : 'Other copies'}</div>
              <div className='list'>
                {availableCopiesList.map(copy => (
                  <ItemDetailsCopy copy={copy} onClick={this.handleOnCopyClick} />
                ))}
                {unavailableCopiesList.map(copy => (
                  <ItemDetailsCopy copy={copy} onClick={this.handleOnCopyClick} />
                ))}
              </div>
            </div>
          ) : null}
        </React.Fragment>
      );
    } else {
      return (
        <div className='copies-container'>
          <div className='copies-container-header'>Copy Info</div>
          <div className='list'>
            <ItemCopyDetails copy={visibleItemCopyDetail} onBack={this.handleOnCopyDetailsBack} />
          </div>
        </div>
      );
    }
  };

  handleCompRender = () => {
    const { item, availableTotal, total } = this.state;
    if (!item) {
      return <Loader active title='Please wait...' />;
    }

    return (
      <React.Fragment>
        <div className='title-container'>
          <span className='title-label'>
            <i
              className={`fav-btn ${item.favourite ? 'fas' : 'far'} fa-star has-text-accent`}
              onClick={this.handleFavouriteClick}
            />{' '}
            {item.itemLbpTitle}
          </span>
          <span className={availableTotal > 0 ? 'has-text-success' : 'has-text-danger'}>
            {availableTotal}/{total}
          </span>
        </div>
        <div className='details-container'>
          <div className='columns'>
            <div className='column is-three-fifths-desktop is-half-tablet is-full item-details-container'>
              {this.itemDetailLabelArray.map(arItem => (
                <div className='columns item-details-list-item'>
                  <div className='detail-label column is-3-tablet is-full has-text-weight-bold'>{arItem.label}:</div>
                  <div className='detail-value column'>{item[arItem.key]}</div>
                </div>
              ))}
            </div>
            <div className='copy-details-container column'>{this.handleCopiesContainerRender()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    return <div className='container comp-container item-details-comp'>{this.handleCompRender()}</div>;
  }
}

ItemDetailsPage.propTypes = {
  history: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  setBrowseShouldRefresh: setBrowseShouldRefresh
};

export default connect(null, mapDispatchToProps)(ItemDetailsPage);
