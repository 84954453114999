import { apiPostUrlEncoded } from '../../helpers/Api';
import { GET_HISTORY } from '../types';

export const getHistory = historyType => dispatch => {
  return apiPostUrlEncoded('/patron/history.php', { daysOld: historyType })
    .then(res => {
      const { data } = res;
      dispatch({
        type: GET_HISTORY,
        payload: data
      });
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject();
    });
};
