// flow
import React, { Component } from 'react';
import './style.css';
import { apiGet, apiPostUrlEncoded } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';
import ListItemPartner from './sub-components/list-item-partner';
import { PartnerOrgGetRes, PartnerOrgPostReq, PartnerOrgType } from '../../models/PartnerOrg';
import ViewPartner from './sub-components/view-partner/view-partner';
import AddPartner from './sub-components/add-partner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ROUTES } from '../../constants';

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchText: '',
      getPartnerRes: new PartnerOrgGetRes(),
      addPartnerReq: new PartnerOrgPostReq()
    };
    this.SwalReact = withReactContent(Swal);
  }

  componentDidMount(): void {
    this.apiGetPartners();
  }

  handleOnSearchInputChange = e => {
    const {
      target: { value }
    } = e;
    this.setState(state => ({ ...state, searchText: value }));
  };

  handleOnSearchClick = () => {
    this.apiGetPartners();
  };

  apiGetPartners = () => {
    this.setState({ isLoading: true }, () => {
      apiGet('/admin/partner/partner-org.php', { ooiName: this.state.searchText })
        .then(res => {
          this.setState({
            isLoading: false,
            getPartnerRes: res.data
          });
        })
        .catch(err => {
          const { data } = err.response;
          this.setState({
            isLoading: false
          });
          showAlert({ type: 'error', title: data ? data.status : 'Failed to fetch data' });
        });
    });
  };

  apiAddNewPartner = () => {
    const { addPartnerReq } = this.state;
    if (addPartnerReq.ooiName.trim() === '') {
      return Promise.reject("Please provide the organization's name");
    } else {
      return apiPostUrlEncoded('/admin/partner/partner-org.php', addPartnerReq)
        .then(() => {
          this.setState(
            {
              addPartnerReq: new PartnerOrgPostReq()
            },
            () => this.apiGetPartners()
          );
          return Promise.resolve();
        })
        .catch(err => {
          Swal.showValidationMessage(`Request failed: ${err}`);
          return Promise.reject();
        });
    }
  };

  onChangeAddPartner = (key, value) => {
    this.setState(
      state => {
        const { addPartnerReq } = state;
        addPartnerReq[key] = value;
        return {
          addPartnerReq
        };
      },
      () => console.log('state', this.state)
    );
  };

  onPartnerView = (partnerOrg: PartnerOrgType) => {
    console.log(partnerOrg);
    this.props.history.push({
      pathname: `${ROUTES.admin.partners}/${partnerOrg.ooiId}`,
      state: { partner: partnerOrg }
    });
    // const titleElement = (
    //   <div className='title is-size-3' style={{ width: '100%' }}>
    //     Partner Details{' '}
    //     <i
    //       className='fas fa-edit is-hoverable has-text-grey'
    //       style={{ marginLeft: '2rem', cursor: 'pointer' }}
    //       onClick={() => this.onPartnerEdit(partnerOrg, handleOnPartnerItemClick)}
    //     />
    //   </div>
    // );
    //
    // const handleOnPartnerItemClick = () => {
    //
    // };
    //
    // const onShowAlert = () => {
    //   showAlert(
    //     {
    //       title: titleElement,
    //       html: <ViewPartner partnerOrg={partnerOrg} />
    //     },
    //     true
    //   );
    // };
    // onShowAlert();
  };

  onPartnerEdit = (partnerOrg: PartnerOrgType, afterCloseCB) => {
    showAlert(
      {
        title: 'Edit partner',
        html: <div>Edit alert</div>,
        showCancelButton: true,
        confirmButtonText: 'CONFIRM',
        onClose: afterCloseCB
      },
      true
    );
  };

  onAddNew = () => {
    this.SwalReact.fire({
      title: 'Add new partner',
      html: (
        <AddPartner addPartnerReq={this.state.addPartnerReq} onAdd={this.apiAddNewPartner} onChange={this.onChangeAddPartner} />
      ),
      showLoaderOnConfirm: true,
      confirmButtonText: 'Add partner',
      showCancelButton: true,
      validationMessage: 'asdfasdf',
      preConfirm: (title, message) => {
        return this.apiAddNewPartner()
          .then(() => {
            console.log('success');
            return true;
          })
          .catch(err => {
            console.log('title', title);
            console.log('message', message);
            console.log('error', err);
            this.SwalReact.showValidationMessage(err);
            return false;
          });
      },
      customClass: {
        container: 'swal2-custom-container',
        content: 'swal2-custom-content'
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  };

  render() {
    const { isLoading, searchText, getPartnerRes } = this.state;
    const { partnerOrgs } = getPartnerRes;
    let listBkColorFlag = false;
    return (
      <div className='comp-container comp-partners center-column'>
        <div className='comp-header title'>Partners</div>
        <div className='search-container'>
          <div className='field has-addons has-addons-centered'>
            <div className='control is-expanded'>
              <input
                type='text'
                className='input is-primary'
                placeholder={"Enter partner's name to search..."}
                value={searchText}
                onChange={this.handleOnSearchInputChange}
              />
            </div>
            <div className='control'>
              <div className='button is-info' onClick={this.onAddNew}>
                Add New
              </div>
            </div>
          </div>
        </div>
        <ul
          className='list list-lending'
          style={{ marginTop: '1rem', marginBottom: '1.5rem', width: 'calc(100% - 1rem)', maxWidth: '50em' }}>
          {partnerOrgs
            ? partnerOrgs
                .filter(org => org.ooiName.toLowerCase().includes(searchText.toLowerCase()))
                .map(org => (
                  <ListItemPartner partner={org} bkColorFlag={!listBkColorFlag} onClick={() => this.onPartnerView(org)} />
                ))
            : null}
        </ul>
        <Loader title={'Please wait...'} active={isLoading} />
      </div>
    );
  }
}

export default Partners;
