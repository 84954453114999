import React, { Component } from 'react';
import './style.css';
import PropTypes from 'prop-types';
import InputContainer from './sub-components/input-container';
import Scanner from '../scanner/scanner';
import { connect } from 'react-redux';
import { addCheckoutItem, checkoutItems, removeCheckoutItemAt } from '../../redux/actions/check-out-in-action';
import { showAlert, showToast } from '../../helpers/Alert';
import ListItem from './sub-components/list-item';

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScannerOpen: false,
      isPostScanCheckLoading: false,
      isLoading: false
    };
    this.onGo = this.onGo.bind(this);
    this.onCheckout = this.onCheckout.bind(this);
    this.refInputBarcode = React.createRef();
  }

  componentDidMount(): void {
    this.refInputBarcode.current.focus();
  }

  toggleScanner() {
    this.setState({
      ...this.state,
      isScannerOpen: !this.state.isScannerOpen
    });
  }
  onGo() {
    const barcode = this.refInputBarcode.current.value.trim();
    this.setState({ ...this.state, isPostScanCheckLoading: true });
    const { addCheckoutItem, checkoutList } = this.props;
    const onResponse = () => {
      this.refInputBarcode.current.value = '';
      this.setState({ ...this.state, isPostScanCheckLoading: false }, () => {
        this.refInputBarcode.current.focus();
      });
    };
    addCheckoutItem(barcode)
      .then(() => {
        onResponse();
        console.log(checkoutList);
      })
      .catch(err => {
        console.log(err);
        onResponse();
        showAlert(err);
      });
  }

  onDeleteListItem = itdeId => {
    this.props.removeCheckoutItemAt(itdeId);
  };

  onCheckout = () => {
    const { checkoutItems } = this.props;
    const onResponse = () => {
      this.refInputBarcode.current.focus();
      this.setState({ ...this.state, isLoading: false }, () => {
        this.refInputBarcode.current.focus();
      });
    };
    this.setState(
      {
        ...this.state,
        isLoading: true
      },
      () => {
        // this.refInputBarcode.current.value = '';
        // this.refInputBarcode.current.blur();
        checkoutItems()
          .then(successConfig => {
            showToast(successConfig);
            onResponse();
          })
          .catch(errConfig => {
            showAlert(errConfig);
            onResponse();
          });
      }
    );
  };

  render(): React.ReactNode {
    const { isPostScanCheckLoading, isScannerOpen } = this.state;
    const { checkoutList } = this.props;
    return (
      <div className='comp-container comp-check'>
        <div className='has-text-centered' style={{ marginBottom: '2rem' }}>
          <span className='title is-size-3'>Check-out</span>
        </div>
        <div className='center-column content-container'>
          <InputContainer
            refBarcode={this.refInputBarcode}
            onGo={this.onGo}
            completeBtnTitle={'CHECKOUT'}
            isCompleteBtnVisible={checkoutList.length > 0}
            onComplete={this.onCheckout}
            isPostScanCheckLoading={isPostScanCheckLoading}
          />
          <ul className='item-list'>
            {checkoutList.map(item => (
              <ListItem key={item.itdeId} item={item} onDelete={this.onDeleteListItem} />
            ))}
          </ul>
          <div className='button is-success scan-button' onClick={this.toggleScanner.bind(this)}>
            <i className='fas fa-barcode' />
          </div>
          {isScannerOpen ? <Scanner toggleScanner={this.toggleScanner.bind(this)} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron,
  checkoutList: state.checkReducer.checkoutList
});

Checkout.propTypes = {
  patron: PropTypes.object.isRequired,
  checkoutList: PropTypes.array.isRequired,
  addCheckoutItem: PropTypes.func.isRequired,
  checkoutItems: PropTypes.func.isRequired,
  removeCheckoutItemAt: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  { addCheckoutItem, checkoutItems, removeCheckoutItemAt }
)(Checkout);
