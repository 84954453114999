import React from 'react';
import getLocalDate from '../../../helpers/get-local-date';

const ItemCopyWith = ({ patron }) => {
  const { trdeId, trnsDate, trdeDueBack, trdeTimesRenewed, ptrnFirstname, ptrnLastname, ptrnEmail } = patron;
  return (
    <div className='item-copy-with box has-background-white-bis' style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
      <div>
        {ptrnFirstname} {ptrnLastname} ({ptrnEmail})
      </div>
      <div>
        {trdeId ? (
          <React.Fragment>
            <div className='has-text-weight-semibold'>Checked out on: </div>
            <div>{getLocalDate(trnsDate, false)}</div>
            <div className='has-text-weight-semibold'>Due back on: </div>
            <div>{getLocalDate(trdeDueBack, false)}</div>
            <div className='has-text-weight-semibold'>Number of times renewed: </div>
            <div>{trdeTimesRenewed}</div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default ItemCopyWith;
