// flow
import React, { Component } from 'react';
import './styles.scss';

import { apiGet } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';
import HistoryRes, { Transaction } from '../../models/history.res';
import HistoryOptions from '../_helpers/history-options/history-options.comp';
import { AxiosResponse } from 'axios';
import HistoryListItem from '../_helpers/history-list-item/history-list-item.component';
import Pagination from '../_helpers/pagination/pagination.comp';
import { ROUTES } from '../../constants';

opaque type HistoryState = {
  patron: { ptrnId: string, ptrnFirstname: string, ptrnLastname: string, ptrnEmail: string } | null,
  req: {
    startDate: string,
    endDate: string,
    borrowedOnly: boolean,
    page: number,
    limit: 100,
    ptrnId: string | null,
    forSelectedPatron: boolean
  },
  res: HistoryRes
};

class HistoryComponent extends Component<{}, HistoryState> {
  constructor(props) {
    super(props);
    const { location } = this.props;
    let defaultStartDate = '';
    let defaultEndDate = '';
    let defaultFilterBy = 1;
    if (location.state) {
      if (location.state.filterBy !== undefined) {
        defaultFilterBy = location.state.filterBy;
      }
      defaultStartDate = location.state.startDate || '';
      defaultEndDate = location.state.endDate || '';
    }
    
    console.log('HistoryComp', location);

    this.refInputStartDate = null;
    this.refInputEndDate = null;
    this.firstLoad = true;
    this.ptrnId = this.props.match.params.ptrnId || null;
    console.log('HistoryComponent', this.props.history.location.pathname);
    const { selectedPatron } = this.props.location.state || { selectedPatron: null };

    this.state = {
      patron: selectedPatron,
      req: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        filterBy: defaultFilterBy,
        page: 1,
        limit: 100,
        ptrnId: null,
        forSelectedPatron: this.props.history.location.pathname !== ROUTES.admin.allTransactions
      },
      res: null
    };
  }

  componentDidMount(): void {
    if (this.ptrnId) {
      this.setState(
        state => {
          const { req } = state;
          req.ptrnId = this.ptrnId;
          return { req };
        },
        () => this.apiGetPatronDetails()
      );
      // this.apiGetPatronDetails();
    } else {
      this.apiLoadHistory();
    }
  }

  apiGetPatronDetails = () => {
    this.setState({ isLoading: true }, () => {
      apiGet('admin/patron/get-patrons.php', { ptrnId: this.ptrnId })
        .then(res => {
          const patron = res.data.patrons[0];
          if (patron) {
            this.setState({ isLoading: false, patron: patron }, () => {
              this.apiLoadHistory();
            });
          } else {
            this.setState({ isLoading: false });
            showAlert({ type: 'error', title: `Patron with ID: ${this.ptrnId} doesn't exist` });
          }
        })
        .catch(err => {
          this.setState({ isLoading: false });
          showAlert({ type: 'error', title: 'Failed to get patron' });
        });
    });
  };

  apiLoadHistory = () => {
    const { req } = this.state;
    // if (this.ptrnId) req.ptrnId = this.ptrnId;
    console.log('apiLoadHistory', req);
    apiGet('patron/v2/history.php', req)
      .then((res: AxiosResponse<HistoryRes>) => {
        this.setState({
          res: res.data
        });
      })
      .catch(() => showAlert({ type: 'error', title: 'Failed to get history' }));
  };

  handleInputChange = (startDate, endDate, filterBy) => {
    this.setState(
      {
        req: {
          ...this.state.req,
          startDate,
          endDate,
          filterBy
        }
      },
      () => {
        this.apiLoadHistory();
      }
    );
  };

  handlePageChange = (page: number) => {
    this.setState({ req: { ...this.state.req, page: page } }, () => this.apiLoadHistory());
  };

  render() {
    const { patron, req, res } = this.state;
    return (
      <div className='comp-container comp-history'>
        <div className='comp-header'>
          <span className='title'>{req.forSelectedPatron ? 'History' : 'All Transactions'}</span>
          <br />
          {patron ? (
            <span className='subtitle'>
              {patron.ptrnFirstname} {patron.ptrnLastname} <em>({patron.ptrnEmail})</em>
            </span>
          ) : null}
        </div>
        <div className='comp-body container'>
          <div className='options-container'>
            <div className='date-inputs-container'>
              <HistoryOptions
                startDate={req.startDate}
                endDate={req.endDate}
                filterBy={req.filterBy}
                onChange={this.handleInputChange}
              />
              {res != null ? (
                <div className='info-bar is-flex is-pulled-left has-background-grey-light has-text-white'>
                  <span className='left-part showing'>
                    Showing: <em>{res.currentList}</em>
                  </span>
                  <div className='right-part is-flex'>
                    <span className='total'>
                      Transactions: <em>{res.totalTransactions}</em>
                    </span>
                    <span className='total-items'>
                      Total items checked out: <em>{res.totalItemsCheckedOut}</em>
                    </span>
                    <span className='current-checked-total'>
                      Currently checked out: <em>{res.currentlyCheckedOut}</em>
                    </span>
                  </div>
                </div>
              ) : null}
              <div className='history-list list'>
                {res && res.transactions && res.transactions.length > 0 ? (
                  res.transactions.map((transaction: Transaction) => (
                    <HistoryListItem historyTransaction={transaction} apiGetHistory={this.apiLoadHistory} />
                  ))
                ) : (
                  <div className='list-item'>Nothing to show</div>
                )}
              </div>
              {res ? (
                <Pagination page={req.page} totalItems={res.totalFound} limit={req.limit} onPageSelect={this.handlePageChange} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HistoryComponent;
