import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FormField = props => {
  const { label, placeholder, inputColorClass, children, required, indexKey, onChange, onBlur, defaultValue } = props;

  const { attributes } = props || { attributes: {} };
  const { type } = props || { type: 'text' };
  const [showErrMsg, setErrMsg] = useState({ msg: '', status: false });
  let refInput = React.createRef();
  const getInputColorClass = () => {
    if (!showErrMsg) return '';
    if (showErrMsg.status) return ' is-danger';
    else if (inputColorClass) return ` ${inputColorClass}`;
    return '';
  };
  const onInputBlur = e => {
    if (required && e.target.value.trim() === '') {
      setErrMsg({ msg: 'This field is Required', status: true });
    } else {
      console.log('onBlur', typeof onBlur === 'function');
      if (typeof onBlur === 'function') {
        const onBlurObj = onBlur({ event: e, indexKey: indexKey });
        console.log('onBlur Error msg', onBlurObj);
        setErrMsg(onBlurObj);
      } else setErrMsg(false);
    }
    e.target.value = e.target.value.trim();
  };

  return (
    <div className='field is-horizontal'>
      <div className='field-label is-normal'>
        <label className='label'>{label}</label>
      </div>
      <div className='field-body'>
        <div className='field'>
          <div className='control'>
            {children ? (
              children
            ) : (
              <input
                onBlur={e => onInputBlur(e)}
                value={defaultValue}
                onChange={e => onChange(e, indexKey)}
                type={type}
                className={'input is-horizontal' + getInputColorClass()}
                placeholder={placeholder}
                required={required}
                {...attributes}
              />
            )}
            {showErrMsg && showErrMsg.status ? <p className='help is-danger'>{showErrMsg.msg}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputColorClass: PropTypes.string,
  indexKey: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  attributes: PropTypes.object
};
export default FormField;
