/* flow */
export class PartnerOrgType {
  ooiId: string;
  ooiName: string;
  ooiStreet: string;
  ooiPin: string;
  ooiProvince: string;
  ooiCountry: string;
  ooiDesc: string;
  ooiContactName: string;
  ooiEmail: string;
  ooiPhone: string;

  constructor(
    ooiId: string = '',
    ooiName: string = '',
    ooiStreet: string = '',
    ooiPin: string = '',
    ooiProvince: string = '',
    ooiCountry: string = '',
    ooiDesc: string = '',
    ooiContactName: string = '',
    ooiEmail: string = '',
    ooiPhone: string = ''
  ) {
    this.ooiId = ooiId;
    this.ooiName = ooiName;
    this.ooiStreet = ooiStreet;
    this.ooiPin = ooiPin;
    this.ooiProvince = ooiProvince;
    this.ooiCountry = ooiCountry;
    this.ooiDesc = ooiDesc;
    this.ooiContactName = ooiContactName;
    this.ooiEmail = ooiEmail;
    this.ooiPhone = ooiPhone;
  }
}

export class PartnerOrgGetRes {
  total: number;
  currentListTotal: number;
  partnerOrgs: Array<PartnerOrgType>;

  constructor(total: number = 0, currentListTotal: number = 0, partnerOrgs: Array<PartnerOrgType> = []) {
    this.total = total;
    this.currentListTotal = currentListTotal;
    this.partnerOrgs = partnerOrgs;
  }
}

export class PartnerOrgPostReq {
  ooiName: string;
  ooiStreet: string;
  ooiPin: string;
  ooiProvince: string;
  ooiCountry: string;
  ooiDesc: string;
  ooiContactName: string;
  ooiEmail: string;
  ooiPhone: string;

  constructor(
    ooiName: string = '',
    ooiStreet: string = '',
    ooiPin: string = '',
    ooiProvince: string = '',
    ooiCountry: string = '',
    ooiDesc: string = '',
    ooiContactName: string = '',
    ooiEmail: string = '',
    ooiPhone: string = ''
  ) {
    this.ooiName = ooiName;
    this.ooiStreet = ooiStreet;
    this.ooiPin = ooiPin;
    this.ooiProvince = ooiProvince;
    this.ooiCountry = ooiCountry;
    this.ooiDesc = ooiDesc;
    this.ooiContactName = ooiContactName;
    this.ooiEmail = ooiEmail;
    this.ooiPhone = ooiPhone;
  }
}
