// @flow
import React from 'react';
import PropTypes from 'prop-types';
import HistoryOptions from '../../_helpers/history-options/history-options.comp';
import Datepicker from '../../_helpers/datepicker/datepicker.component';
import Dropdown from '../../_helpers/dropdown/dropdown.comp';
import { DropdownItem } from '../../_helpers/dropdown/dropdown-item.model';
import LendActions from '../../../redux/actions/lend.actions';
import PatronActions from '../../../redux/actions/patron.actions';
import SearchDropdown from '../../_helpers/search-dropdown/search-dropdown.component';
import patron from '../../Index/logged-in/patron';

export type BorrowFilterValue = {|
  startDate: ?string,
  endDate: ?string,
  filterBy: 0 | 1 | 2,
  ooiId: ?string,
  ptrnId: ?string
|};

export type BorrowFilterOnChangeParams = {
  key: 'startDate' | 'endDate' | 'filterBy' | 'ooiId' | 'ptrnId',
  value: ?string | number
};
export type BorrowFilterOnChange = (...BorrowFilterOnChangeParams) => null;

export type BorrowFilterProps = {|
  values: BorrowFilterValue,
  onChange: BorrowFilterOnChange,
  orgDropdownList: [],
  patronDropdownList: []
|};

const BorrowFilter = (props: BorrowFilterProps) => {
  const { values, onChange, orgDropdownList, patronDropdownList } = props;

  const dropdownItems = [
    new DropdownItem(0, 'All loaned items', true, values.filterBy === 0),
    new DropdownItem(1, 'Currently Loaned', false, values.filterBy === 1),
    new DropdownItem(2, 'Returned items', false, values.filterBy === 2)
  ];

  const handleOnInputOptionsChange = (type: 'startDate' | 'endDate' | 'filterBy', value: ?string | number) => {
    onChange(type, value);
  };

  const handleOnDropdownSelect = (item: DropdownItem) => {
    handleOnInputOptionsChange('filterBy', item.id);
  };

  const handleOnOrgChange = item => {
    onChange('ooiId', item ? item.value : null);
  };
  const handleOnPatronChange = item => {
    onChange('ptrnId', item ? item.value : null);
  };

  return (
    <div className='filter-container box has-background-white-bis'>
      <div className='dropdown-container column'>
        <div className='org'>
          <SearchDropdown
            label='Organization'
            onSelect={handleOnOrgChange}
            value={values.ooiId ? { value: values.ooiId, text: '' } : null}
            placeholder='Select an organization'
            fluid
            options={orgDropdownList}
            hasClear
          />
        </div>
        <div className='patron' style={{ marginTop: '1rem' }}>
          <SearchDropdown
            label='Patrons'
            onSelect={handleOnPatronChange}
            value={values.ptrnId ? { value: values.ptrnId, text: '' } : null}
            placeholder='Select a patron'
            fluid
            options={patronDropdownList}
            hasClear
          />
        </div>
      </div>
      <div className='inputs-container'>
        <div className='date-range-picker field is-grouped is-grouped-centered'>
          <div className='field is-grouped'>
            <div className='control'>
              <Datepicker
                label='From'
                options={{ maxDate: values.endDate || 'today', defaultDate: values.startDate }}
                handleChange={(date: ?string) => handleOnInputOptionsChange('startDate', date || null)}
              />
            </div>
            <div className='control'>
              <Datepicker
                label='To'
                options={{ minDate: values.startDate, maxDate: 'today', defaultDate: values.endDate }}
                handleChange={(date: ?string) => handleOnInputOptionsChange('endDate', date || null)}
              />
            </div>
          </div>
          <div className='field is-grouped is-flex' style={{ alignItems: 'center', marginLeft: '2rem' }}>
            <Dropdown items={dropdownItems} onSelect={handleOnDropdownSelect} />
          </div>
        </div>
      </div>
    </div>
  );
};

BorrowFilter.propTypes = {
  values: PropTypes.object.isRequired
};

export default BorrowFilter;
