import React, { Component } from 'react';
import './style.css';
import { AddPatronReq } from '../../models/AddPatron';
import FormField from '../../helpers/form-field';
import { showAlert, showToast } from '../../helpers/Alert';
import { apiPostUrlEncoded } from '../../helpers/Api';
import Loader from '../../layout/loader/loader';

class AddPatron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addPatronReq: new AddPatronReq()
    };
  }

  onChange = (event, indexKey) => {
    const { value } = event.target;
    this.setState(state => {
      const { addPatronReq } = state;
      addPatronReq[indexKey] = value;
      return {
        addPatronReq
      };
    }, console.log('state', this.state));
  };

  validateEmail = email => {
    const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return pattern.test(email);
  };

  emailOnBlurHandler = ({ event }) => {
    const { value } = event.target;
    return { msg: 'Invalid email format', status: !this.validateEmail(value) };
  };

  onSubmit = () => {
    const { addPatronReq } = this.state;
    let { ptrnFirstname, ptrnLastname, ptrnEmail } = addPatronReq;
    if (ptrnFirstname.trim() === '' || ptrnLastname.trim() === '' || ptrnEmail.trim() === '') {
      showAlert({ type: 'error', title: 'Cannot have empty fields' });
      return;
    }
    if (!this.validateEmail(ptrnEmail.trim())) {
      showAlert({ type: 'error', title: 'Invalid email format' });
      return;
    }
    this.setState(
      {
        isLoading: true
      },
      () => {
        apiPostUrlEncoded('/admin/patron/register-patron.php', this.state.addPatronReq)
          .then(res => {
            showToast({ type: 'success', title: 'Patron successfully created' });
            this.setState({
              isLoading: false,
              addPatronReq: new AddPatronReq()
            });
          })
          .catch(err => {
            this.setState({
              isLoading: false
            });
            showAlert({ type: 'error', title: err.response.data.status });
          });
      }
    );
  };

  render() {
    const { isLoading, addPatronReq } = this.state;
    return (
      <div className='comp-container comp-add-patron center-column'>
        <h1 className='title is-size-3'>Register Patron</h1>
        <ul className='list'>
          <li className='list-item form-container has-background-white-bis'>
            <FormField
              label={'First Name'}
              defaultValue={addPatronReq.ptrnFirstname}
              onChange={this.onChange}
              required={true}
              indexKey={'ptrnFirstname'}
              placeholder={'Enter first name...'}
            />
            <FormField
              label={'Last Name'}
              defaultValue={addPatronReq.ptrnLastname}
              onChange={this.onChange}
              required={true}
              indexKey={'ptrnLastname'}
              placeholder={'Enter first name...'}
            />
            <FormField
              label={'Email Address'}
              defaultValue={addPatronReq.ptrnEmail}
              type={'email'}
              onChange={this.onChange}
              onBlur={this.emailOnBlurHandler}
              required={true}
              indexKey={'ptrnEmail'}
              placeholder={'Enter first name...'}
            />
            <FormField label={''}>
              <div className='field is-grouped is-grouped-multiline' style={{ userSelect: 'none' }}>
                <span className='control'>
                  <input
                    type='checkbox'
                    className='is-checkradio is-success'
                    id='adptrn-checkbox-isAdmin'
                    onChange={e => this.onChange({ target: { value: e.target.checked } }, 'ptrnIsAdmin')}
                    checked={addPatronReq.ptrnIsAdmin}
                  />
                  <label htmlFor='adptrn-checkbox-isAdmin'>Make admin</label>
                </span>
                <span className='control'>
                  <input
                    type='checkbox'
                    className='is-checkradio is-info'
                    id='adptrn-checkbox-email'
                    onChange={e => this.onChange({ target: { value: e.target.checked } }, 'shouldEmail')}
                    checked={addPatronReq.shouldEmail}
                  />
                  <label htmlFor='adptrn-checkbox-email'>Notify Patron via Email</label>
                </span>
              </div>
            </FormField>
          </li>
          <li className='list-item has-background-white-ter right'>
            <div className='button is-primary' onClick={this.onSubmit}>
              Submit
            </div>
          </li>
        </ul>
        <Loader title={'Registering new patron...'} active={isLoading} />
      </div>
    );
  }
}

export default AddPatron;
