import axios from 'axios';
import { cssGreen, cssRed, cssBlue } from '../helpers/log-css';
import Store from '../redux/store';
import { LOGOUT, SET_REFRESHING_TOKEN, VERIFY } from '../redux/types';
import { firstPath } from '../constants';

const APP_NAME = 'beaconblast.net';

export function getURL(urlSuffix: string) {
  return 'https://beaconblast.net/api/' + encodeURI(urlSuffix);
}

if (process.env.NODE_ENV === 'development' || firstPath === 'blast-test') {
  axios.defaults.baseURL = 'https://beaconblast.net/dev/api';
} else {
  axios.defaults.baseURL = 'https://beaconblast.net/api';
}

axios.interceptors.request.use(
  config => {
    console.log('%cRequest config\n', cssBlue, config);
    if (config.method === 'get') console.log('%cRequest params\n', cssBlue, config.params);
    else {
      if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded')
        console.log('%cRequest data\n', cssBlue, config.data.toString());
      else console.log('%cRequest data\n', cssBlue, config.data);
    }
    return config;
  },
  err => {
    console.log(err);
    return Promise.reject(err);
  }
);

const refreshToken = cb => {
  const { dispatch, getState } = Store;
  const { apiReducer } = getState();
  console.log('refreshing token');
  console.log('ApiReducer', apiReducer);

  if (apiReducer.isRefreshing) {
    return apiReducer.refreshingCall;
  }

  const refreshingCall = apiGet('/verification/verify-token.php', { issuer: APP_NAME })
    .then(res => {
      dispatch({ type: SET_REFRESHING_TOKEN, payload: { isRefreshing: false, refreshingCall: null } });
      dispatch({ type: VERIFY, payload: res.data });
      return Promise.resolve(true);
    })
    .then(cb)
    .catch(err => {
      dispatch({ type: SET_REFRESHING_TOKEN, payload: { isRefreshing: false, refreshingCall: null } });
      if (err.status === 401 || err.status === 403) {
        dispatch({ type: LOGOUT, payload: null });
        return Promise.reject('Failed to verify session');
      }
    });

  dispatch({ type: SET_REFRESHING_TOKEN, payload: { isRefreshing: true, refreshingCall: refreshingCall } });
  return refreshingCall;
};

axios.interceptors.response.use(
  response => {
    console.log('%cResponse\n', cssGreen, response);
    console.log('%cResponse headers\n', cssGreen, response.headers);
    console.log('%cResponse data\n', cssGreen, response.data);
    return response;
  },
  err => {
    if (err.response.status === 403) {
      return refreshToken(() => axios.request(err.config));
    }
    console.log('%cError\n', cssRed, err);
    console.log('%cError response\n', cssRed, err.response);
    return Promise.reject(err);
  }
);

const baseURL = 'https://beaconblast.net/api';
const interceptorReq = config => {
  console.log(config);
  return config;
};

const interceptorRes = response => {
  console.log('response', response);
  console.log('response headers', response.headers);
  console.log('response data', response.data);
  return response;
};

const interceptorErr = err => {
  console.log(err);
  return Promise.reject(err);
};

export const axiosPostUrlEncoded = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});
axiosPostUrlEncoded.interceptors.request.use(interceptorReq, interceptorErr);
axiosPostUrlEncoded.interceptors.response.use(interceptorRes, interceptorErr);

export const axiosPostJSON = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});
axiosPostJSON.interceptors.request.use(interceptorReq, interceptorErr);
axiosPostJSON.interceptors.response.use(interceptorRes, interceptorErr);

export const apiPostUrlEncoded = (url: string, data: Object) => {
  const reqBody = new URLSearchParams(data);
  return axios.post(url, reqBody, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: true
  });
};

export const apiPostJson = (url: string, data: Object) => {
  return axios.post(url, data, {
    withCredentials: true
  });
};

export const apiGet = (url: string, params: Object) => {
  return axios.get(url, {
    params: params,
    withCredentials: true
  });
};

export function postFormData(urlSuffix: string, formBody: Object): Promise<Response> {
  if (typeof urlSuffix != 'string' && typeof formBody != 'object') throw new Error('Invalid parameter types');
  const formData = new URLSearchParams();
  for (let key in formBody) {
    if (Array.isArray(formBody[key])) {
      for (let index = 0; index < formBody[key]; index++) {
        formData.append(key + '[' + index + ']', formBody[key][index]);
      }
    } else {
      formData.append(key, formBody[key]);
    }
  }

  return fetch(getURL(urlSuffix), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    body: formData
  });
}

export function postJsonData(urlSuffix: string, jsonBody: Object): Promise<Response> {
  if (typeof urlSuffix != 'string' && typeof jsonBody != 'object') throw new Error('Invalid parameter types');
  return fetch(getURL(urlSuffix), {
    method: 'JSON',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(jsonBody)
  });
}
