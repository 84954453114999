import React from 'react';
import PropTypes from 'prop-types';
import { apiPostUrlEncoded } from '../../../helpers/Api';
import { showAlert, showToast } from '../../../helpers/Alert';
import { ROUTES } from '../../../constants';
import { withRouter } from 'react-router-dom';

const PatronEditComp = ({ patron, afterSave, history }) => {
  let refInputFirstname = null;
  let refInputLastname = null;
  let refInputEmail = null;
  let refCheckAdmin = null;

  const apiOnSave = req => {
    apiPostUrlEncoded('/admin/patron/update-patron.php', req)
      .then(() => {
        showToast({ type: 'success', title: 'Patron updated' });
        afterSave();
      })
      .catch(() => {
        showAlert({ type: 'error', title: 'Failed to update patron' });
      });
  };

  const apiOnDelete = () => {
    apiPostUrlEncoded('/admin/patron/delete-patron.php', { ptrnId: patron.ptrnId })
      .then(() => {
        console.log('patron deleted');
        history.push(ROUTES.admin.viewPatrons);
        // return true;
        showToast({ type: 'success', title: 'Patron deleted' });
      })
      .catch(() => {
        showAlert({ type: 'error', title: 'Failed to delete patron' });
        // return false;
      });
  };

  const onSubmit = event => {
    event.preventDefault();
    const req = {
      ptrnId: patron.ptrnId,
      ptrnFirstname: refInputFirstname.value.trim(),
      ptrnLastname: refInputLastname.value.trim(),
      ptrnEmail: refInputEmail.value.trim(),
      ptrnIsAdmin: refCheckAdmin.checked
    };

    console.log('patron-edit', req);
    apiOnSave(req);
  };

  const onBlur = event => {
    event.target.value = event.target.value.trim();
  };

  const onDeleteClicked = () => {
    showAlert({
      type: 'warning',
      title: 'Are you sure you want to delete this patron?',
      html: 'Note: Patrons with transaction(s) will be deactivated instead',
      showCancelButton: true,
      preConfirm: () => {
        console.log('ok clicked');
        apiOnDelete();
        return false;
      }
    });
  };

  return (
    <div className='patron-edit-comp'>
      <form className='form' onSubmit={onSubmit}>
        <div className='field'>
          <label htmlFor='input-ptrn-firstname' className='label'>
            First name
          </label>
          <div className='control'>
            <input
              type='text'
              className='input'
              id='input-ptrn-firstname'
              defaultValue={patron.ptrnFirstname}
              placeholder='Enter first name...'
              ref={elm => (refInputFirstname = elm)}
              onBlur={onBlur}
              required
            />
          </div>
        </div>
        <div className='field'>
          <label htmlFor='input-ptrn-lastname' className='label'>
            Last name
          </label>
          <div className='control'>
            <input
              type='text'
              className='input'
              id='input-ptrn-lastname'
              defaultValue={patron.ptrnLastname}
              placeholder='Enter last name...'
              ref={elm => (refInputLastname = elm)}
              onBlur={onBlur}
              required
            />
          </div>
        </div>
        <div className='field'>
          <label htmlFor='input-ptrn-email' className='label'>
            Email
          </label>
          <div className='control'>
            <input
              type='email'
              className='input'
              id='input-ptrn-email'
              defaultValue={patron.ptrnEmail}
              placeholder='Enter email...'
              ref={elm => (refInputEmail = elm)}
              onBlur={onBlur}
              required
            />
          </div>
        </div>
        <div className='field'>
          <input
            type='checkbox'
            className='is-checkradio is-info'
            id='checkbox-ptrn-admin'
            defaultChecked={patron.ptrnIsAdmin}
            ref={elm => (refCheckAdmin = elm)}
          />
          <label htmlFor='checkbox-ptrn-admin' className='label'>
            Admin
          </label>
        </div>
        <div className='field is-grouped is-grouped-right'>
          <input type='button' className='button is-danger' value='Delete / Deactivate' onClick={onDeleteClicked} />
          <input type='submit' className='button is-primary' value='Save' style={{ marginLeft: '1rem' }} />
        </div>
      </form>
    </div>
  );
};

PatronEditComp.propTypes = {
  patron: PropTypes.object.isRequired,
  afterSave: PropTypes.func.isRequired
};

export default withRouter(PatronEditComp);
