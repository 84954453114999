import { GET_HISTORY, LOGOUT } from '../types';

class HistoryReducerState {
  constructor() {
    this.historyState = null;
  }
}

const initialState = new HistoryReducerState();

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case GET_HISTORY:
      return { ...state, historyState: payload };
    case LOGOUT:
      return new HistoryReducerState();
    default:
      return state;
  }
}
