import React from 'react';
import PropTypes from 'prop-types';
import GridItem from './grid-item';
import WrapperComponent from '../../../helpers/WrapperComponent';
import GridItemPatron from './grid-items/grid-item-patron';
import GridItemItem from './grid-items/grid-item-item';
import GridItemTrans from './grid-items/grid-item-trans';
import GridItemReader from './grid-items/grid-item-reader';
import GridItemChart from './grid-items/grid-item-chart';
import GridItemIll from './grid-items/grid-item-ill';

const Grid = ({ numbersData }) => {
  const { item, patron, transaction, reader, ill } = numbersData;
  return (
    <WrapperComponent>
      <GridItemChart />
      <GridItemPatron patron={patron} />
      <GridItemIll ill={ill} />
      <GridItemTrans item={transaction} />
      <GridItemReader item={reader} />
      <GridItemItem item={item} />
    </WrapperComponent>
  );
};

export default Grid;
