import React from 'react';

const Section = props => {
  return (
    <div className='section is-paddingless'>
      {props.label ? (
        <h2 className='section-header subtitle is-size-5 has-background-grey-lighter'>
          <i className={'fas ' + props.iconClass} /> {props.label}
        </h2>
      ) : null}
      <div className='columns is-variable is-5 is-mobile is-multiline is-centered section-container'>{props.children}</div>
    </div>
  );
};

export default Section;
