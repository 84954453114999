import React from 'react';
import PropTypes from 'prop-types';

const InputContainer = props => {
  const { refBarcode, onGo, onComplete, isCompleteBtnVisible, completeBtnTitle, isPostScanCheckLoading } = props;
  const onEnterAndGo = e => {
    if (e.keyCode === 13) onGo();
  };
  return (
    <div className='input-container'>
      <div className='columns is-centered'>
        <div className='column is-10'>
          <div className='control has-icons-right'>
            <input
              type='text'
              className='input is-rounded is-primary'
              placeholder='Enter or scan barcode...'
              ref={refBarcode}
              disabled={isPostScanCheckLoading}
              onKeyUp={e => onEnterAndGo(e)}
            />
            <span className='icon is-small is-right'>
              <i className='fas fa-question-circle' style={{ cursor: 'pointer', pointerEvents: 'auto' }} />
            </span>
          </div>
        </div>
        <div className='column is-paddingless center'>
          <div className={'button is-primary is-rounded' + (isPostScanCheckLoading ? ' is-loading' : '')} onClick={onGo}>
            GO
          </div>
          {isCompleteBtnVisible ? (
            <div className='button is-success is-rounded complete-button' onClick={onComplete}>
              {completeBtnTitle}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

InputContainer.propTypes = {
  refBarcode: PropTypes.object.isRequired,
  onGo: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  isCompleteBtnVisible: PropTypes.bool.isRequired,
  completeBtnTitle: PropTypes.string.isRequired,
  isPostScanCheckLoading: PropTypes.bool.isRequired
};

export default InputContainer;
