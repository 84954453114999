import React from 'react';

const ListItem = props => {
  const { item, onListItemClick, isAdmin } = props;
  const style = {
    levelLeft: {
      maxWidth: 'calc(100% - 3em)',
      overflowWrap: 'breakWord',
      hyphens: 'auto'
    },
    levelRight: {
      maxWidth: '3em'
    }
  };
  return (
    <div className='list-item' onClick={() => onListItemClick(item.itemId)}>
      <div className='level is-mobile'>
        <div className='level-left' style={style.levelLeft}>
          <div className='level-item' style={{ width: '100%' }}>
            <div>
              <span className='title is-size-5-tablet is-size-6-mobile'>{item.itemLbpTitle}</span>{' '}
              {item.itemVol ? <em className='subtitle is-size-5-tablet is-size-6-mobile'>Vol: {item.itemVol}</em> : null}
              <br />
              <span className='subtitle is-size-6-tablet is-size-7-mobile'>{item.ittyName}</span>
            </div>
          </div>
        </div>
        <div className='level-right' style={style.levelRight}>
          <div className='level-item'>
            <div>
              {!isAdmin ? (
                <span
                  className={
                    'subtitle is-size-5-tablet is-size-6-mobile has-text-weight-bold' +
                    (item.availableCopies > 0 ? ' has-text-success' : ' has-text-danger')
                  }>
                  {item.availableCopies}/{item.totalCopies}
                </span>
              ) : (
                <span className='subtitle is-size-5-tablet is-size-6-mobile has-text-weight-bold'>
                  <i className='fas fa-edit' />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
