import React from 'react';

import icoCheckin from '../../../assets/ico_checkin.png';
import icoDash from '../../../assets/ico_dash.png';
import icoIll from '../../../assets/ico_ill_2.png';
import icoAssignTag from '../../../assets/ico_assign-tag.png';
import BoxLink from './sub-components/box-link';
import BoxMultiLink from './sub-components/box-multi-link';
import Section from './sub-components/section';
import OtherLink from './sub-components/other-link';
import { ROUTES } from '../../../constants';
import WrapperComponent from '../../../helpers/WrapperComponent';
import { Link } from 'react-router-dom';

const admin = () => {
  return (
    <WrapperComponent>
      <Section label='Admin functions' iconClass='fa-user-cog'>
        <BoxLink image={icoCheckin} label='CHECK-IN' link={ROUTES.admin.checkin} />
        <BoxLink image={icoDash} label='DASHBOARD' link={ROUTES.admin.dashboard} />
        <BoxMultiLink label='ITEM' viewLink={ROUTES.admin.viewItems} addLink={ROUTES.admin.addItem} />
        <BoxMultiLink label='PATRON' viewLink={ROUTES.admin.viewPatrons} addLink={ROUTES.admin.addPatron} />
        <BoxLink image={icoIll} label='ILL' link={ROUTES.admin.more} />
        <BoxLink image={icoAssignTag} label='Assign Barcode' link={ROUTES.admin.assignTag} />
  
        {/*<div className='column is-half-tablet is-full-mobile'>*/}
        {/*  <div className='box'>*/}
        {/*    <span className='box-label-top'>OTHERS</span>*/}
        {/*    <div className='columns is-variable is-3 is-mobile is-multiline is-centered'>*/}
        {/*      <OtherLink link={ROUTES.admin.assignTag} label='Assign Barcode Tag' classes='is-link' iconClass='fa-tag' />*/}
        {/*      /!*<OtherLink link={ROUTES.admin.importPatrons} label='Import Patrons' classes='is-link' iconClass='fa-file-import' />*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Section>
      {/*<span*/}
      {/*  id={'admin-more'}*/}
      {/*  className='is-block is-size-5 has-text-right'*/}
      {/*  style={{ marginBottom: '0.5rem', marginRight: '1rem', userSelect: 'none' }}>*/}
      {/*  <Link to={ROUTES.admin.more} className='has-text-weight-semibold'>*/}
      {/*    More...*/}
      {/*  </Link>*/}
      {/*</span>*/}
    </WrapperComponent>
  );
};

export default admin;
