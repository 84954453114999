import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import LendActions from '../../redux/actions/lend.actions';

import './lend.styles.scss';
import SearchDropdown from '../_helpers/search-dropdown/search-dropdown.component';
import LendListItem from '../_helpers/lend-list-item/lend-list-item.component';

const LendPage = ({ isLoading, lendList, lendItems, orgOptionsLoading, getOrgDropdownList, addLendItem }) => {
  // states
  const [inputValues, setInputValues] = useState({ lendLimit: '', barcode: '', lendFileNo: '' });
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  // refs
  const refGoButton = useRef(null);

  // on mount
  useEffect(() => {
    if (orgOptions.length === 0) {
      getOrgDropdownList().then(data => {
        setOrgOptions(data);
      });
    }
  }, []);

  const handleOnGo = () => {
    addLendItem(inputValues.barcode, inputValues.lendLimit, inputValues.lendFileNo).then(() => {
      setInputValues({ ...inputValues, barcode: '' });
    });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    handleOnGo();
  };

  // const handleOnBarcodeInputKeyDown = e => {
  //   console.log('handleOnbarcodeInputKeyDown', e.keyCode);
  //   if (e.keyCode === 13 && refGoButton) {
  //     // refGoButton.current.click();
  //   }
  // };

  const handleOnInputChange = e => {
    const key = e.target.getAttribute('name');
    setInputValues({ ...inputValues, [key]: e.target.value });
  };

  return (
    <div className='comp-lend comp-container'>
      <div className='comp-header title is-size-3'>Lend item</div>
      <div className='container'>
        <form className='input-container' method={null} onSubmit={handleOnSubmit}>
          <div className='input-group-top'>
            <div className='field has-addons'>
              <div className='control'>
                {/*<input*/}
                {/*  type='text'*/}
                {/*  name='lendFileNo'*/}
                {/*  value={inputValues.lendFileNo}*/}
                {/*  onChange={handleOnInputChange}*/}
                {/*  maxLength={50}*/}
                {/*  className='input input-lend-fileno'*/}
                {/*  placeholder='File no.'*/}
                {/*/>*/}
              </div>
              <div className='control'>
                <input
                  type='number'
                  name='lendLimit'
                  value={inputValues.lendLimit}
                  onChange={handleOnInputChange}
                  className='input input-lend-limit'
                  placeholder='Lend limit'
                  min='1'
                  max='9999'
                  required
                />
              </div>
            </div>
            <div className='control is-expanded'>
              <SearchDropdown
                label=''
                placeholder='Select an organization'
                value={selectedOption}
                options={orgOptions}
                onSelect={setSelectedOption}
                fluid
              />
            </div>
          </div>
          <div className='field has-addons'>
            <div className='control is-expanded'>
              <input
                type='text'
                name='barcode'
                value={inputValues.barcode}
                onChange={handleOnInputChange}
                className='input'
                placeholder='Enter item barcode...'
                required
              />
            </div>
            <div className='control'>
              <input type='submit' className='button is-primary' value='Add' ref={refGoButton} />
            </div>
            
          </div>
        </form>
        <div className='list-container list'>
          {lendList.map(item => (
            <LendListItem item={item} />
          ))}
        </div>
        {lendList.length > 0 ? (
          <div className='control'>
            <input type='button' className='button is-success is-large' value='Lend' onClick={() => lendItems(selectedOption)} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ lendReducer, postScanReducer }) => ({
  isLoading: lendReducer.isLoading,
  lendList: lendReducer.lendList,
  postScanLoader: postScanReducer.isLoading,
  orgOptionsLoading: lendReducer.orgList.isLoading
});

const mapDispatchToProps = {
  fetchOrgList: LendActions.fetchOrgList,
  getOrgDropdownList: LendActions.getOrgDropdownList,
  addLendItem: LendActions.addLendItem,
  lendItems: LendActions.lendStart
};

export default connect(mapStateToProps, mapDispatchToProps)(LendPage);
