import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PartnerOrgType } from '../../models/PartnerOrg';
import PartnerActions from '../../redux/actions/partner.actions';
import './view-partner.styles.scss';
import Loader from '../../layout/loader/loader';
import ViewPartnerEdit from './view-partner-edit/view-partner-edit.component';

const ViewPartnerPage = ({ location, match, fetchPartnerDetails }) => {
  const { ooiId } = match.params;
  const { state } = location;

  const [statePartner, setStatePartner] = useState(state && state.partner ? { ...state.partner } : null);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (!statePartner) {
      fetchPartnerDetails(ooiId).then(data => setStatePartner(data));
    }
  }, []);

  const handleOnSubmit = () => {
    fetchPartnerDetails(ooiId).then(data => setStatePartner(data));
  };

  const fieldMap = [
    { key: 'ooiDesc', name: 'Description' },
    { key: 'ooiContactName', name: 'Contact name' },
    { key: 'ooiEmail', name: 'Email' },
    { key: 'ooiPhone', name: 'Phone' },
    { key: 'ooiStreet', name: 'Street' },
    { key: 'ooiProvince', name: 'Province' },
    { key: 'ooiPin', name: 'Postal/ZIP Code' },
    { key: 'ooiCountry', name: 'Country' }
  ];

  const viewPartnerDetails = () => {
    if (isEditing) {
      return (
        <ViewPartnerEdit
          fieldMap={fieldMap}
          partner={statePartner}
          handleOnCancel={() => setIsEditing(false)}
          onSubmit={handleOnSubmit}
        />
      );
    }
    return (
      <React.Fragment>
        <div className='comp-header title is-size-3'>Partner Org Details</div>
        <div className='content-container box'>
          <div className='name-container'>
            <div className='title is-size-4'>{statePartner.ooiName}</div>
            <div className='edit-button button is-small is-rounded is-info is-outlined' onClick={() => setIsEditing(true)}>
              <i className='fas fa-edit' /> Edit
            </div>
          </div>
          <div className='list'>
            {fieldMap.map((item, index) => (
              <div key={index} className='list-item has-background-white-bis'>
                <div className='item-label'>{item.name}</div>
                <div className='item-value'>{statePartner[item.key] ? statePartner[item.key] : 'nil'}</div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className='view-partner-comp comp-container'>
      <Loader active={!!!statePartner} />
      {statePartner ? viewPartnerDetails() : null}
    </div>
  );
};

ViewPartnerPage.propTypes = {
  partner: PropTypes.objectOf(PartnerOrgType)
};

const mapDispatchToProps = {
  fetchPartnerDetails: PartnerActions.fetchPartnerDetails
};

export default connect(null, mapDispatchToProps)(ViewPartnerPage);
