import { apiGet } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';

const fetchPartnerDetails = ooiId => async dispatch => {
  try {
    const res = await apiGet('/admin/partner/partner-org.php', { ooiId });
    const { data } = await res;
    if (data.partnerOrgs.length > 0) {
      return data.partnerOrgs[0];
    }
    showAlert({ type: 'error', title: 'Organization does not exist' });
  } catch (e) {
    showAlert({ type: 'error', title: 'Failed to fetch organization details' });
  }
};

const PartnerActions = {
  fetchPartnerDetails
};

export default PartnerActions;
