import { SET_REFRESHING_TOKEN } from '../types';

const initialState = {
  isRefreshing: false,
  refreshingCall: null
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_REFRESHING_TOKEN:
      return { ...state, ...payload };
    default:
      return state;
  }
}
