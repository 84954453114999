import React from 'react';
import PropTypes from 'prop-types';

const BorrowDetailItem = ({ title, value, type, isEditable, onEdit }) => {
  return (
    <div className='detail-item is-flex'>
      <div className='detail-label has-text-weight-bold'>{title}</div>
      <div className='detail-value'>{value || 'nil'}</div>
      {isEditable ? (
        <button className='detail-edit-btn button is-info is-small is-rounded is-outlined' onClick={onEdit}>
          CHANGE
        </button>
      ) : null}
    </div>
  );
};

BorrowDetailItem.defaultProps = {
  isEditable: false
};

BorrowDetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func
};

export default BorrowDetailItem;
