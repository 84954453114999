import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Loader = (props = {}) => {
  const { title } = props.title ? props : '';
  const { active } = props || { active: true };
  const refLoader = React.createRef();
  return active ? (
    <div className='loading-overlay' ref={refLoader}>
      <div className='ld ld-ring ld-spin' style={{ fontSize: '6em' }} />
      {title !== '' ? <span className='title is-size-3'>{title}</span> : null}
    </div>
  ) : null;
};

Loader.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool
};

export default Loader;
