import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './lend-list-item.styles.scss';
import LendActions from '../../../redux/actions/lend.actions';

const LendListItem = ({ item, removeLendItem }) => {
  return (
    <div className='list-item lend-list-item-comp'>
      <div className='left-part'>
        <span className='item-barcode subtitle is-size-6'><em>{item.itdeBarcode}</em></span>
        <span className="lend-fileno-container" style={{ marginLeft: '0.5rem', marginRight: '0.5rem'}}>
          File no: <b>{item.lendFileNo}</b>
        </span>
        <span className='lend-limit-container'>
          Lending for: <b>{item.lendDays} days</b>
        </span>
        <br />
        <span className='item-title title is-size-5'>{item.itemLbpTitle}</span>
      </div>
      <div className='right-part'>
        <div className='remove-btn-container'>
          <i
            className='remove-btn fas fa-times-circle has-text-grey-light is-size-6'
            onClick={() => removeLendItem(item.itdeBarcode)}
          />
        </div>

      </div>
    </div>
  );
};

LendListItem.propTypes = {
  item: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  removeLendItem: LendActions.removeLendItem
};

export default connect(null, mapDispatchToProps)(LendListItem);
