// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import type { BorrowItem } from '../borrow.types';
import BorrowDetailItem from './borrow-detail-item.component';
import getLocalDate from '../../../helpers/get-local-date';
import { apiGet } from '../../../helpers/Api';
import { showAlert } from '../../../helpers/Alert';
import type { BorrowDetailItemType } from '../borrow.types';
import Loader from '../../../layout/loader/loader';
import Datepicker from '../../_helpers/datepicker/datepicker.component';

const BorrowDetails = ({ borId, onBack }) => {
  const [item: BorrowDetailItemType, setItem] = useState(null);

  const apiFetchItemDetails = () => {
    apiGet('/admin/borrow/get-borrow-details.php', { borId })
      .then(res => setItem(res.data))
      .catch(() => showAlert({ type: 'error', title: 'Failed to fetch details' }));
  };

  useEffect(() => {
    apiFetchItemDetails();
  }, []);

  useEffect(() => {
    console.log('setItem', item);
  }, [item]);
  
  const getReturnAlertView = () => {
    // return (
    //   <div className="return-alert">
    //     <div className="title is-size-4">Change return date</div>
    //     <div className="center">
    //       <Datepicker label='Return date' options={{}} handleChange={}
    //     </div>
    //   </div>
    // )
  };
  
  const handleOnReturnEdit = () => {
    showAlert({
      title: <ReturnAlert />,
      focusConfirm: false,
      showCancelButton: true
    })
  };

  return (
    <div className='detail-comp box has-background-white-bis'>
      <div className='title is-size-4 header center'>
        <div className='back-btn has-text-info' onClick={onBack}>
          <i className='fas fa-caret-left' style={{ marginRight: '1rem' }} />
          Go Back
        </div>
        <span>Details</span>
      </div>
      <div className='details'>
        {item ? (
          <React.Fragment>
            <BorrowDetailItem title='Borrowed from:' value={`${item.ooiName} ${item.ooiEmail ? `(${item.ooiEmail})` : ''}`} />
            <BorrowDetailItem title='Borrow number:' value={item.borNumber} />
            <BorrowDetailItem title='Borrowed on:' value={getLocalDate(item.borDate, false)} />
            <BorrowDetailItem title='Due back on:' value={getLocalDate(item.borDueBack, false)} />
            <BorrowDetailItem title='Returned on:' value={item.borReturned ? getLocalDate(item.borReturned, false) : null} isEditable onEdit={handleOnReturnEdit} />
            <BorrowDetailItem title='Title:' value={item.borItemTitle} />
            <BorrowDetailItem title='Author:' value={item.borItemAuthor} />
            <BorrowDetailItem title='Description:' value={item.borItemDescription} />
            <BorrowDetailItem title='Publisher:' value={item.borItemPublisher} />
            <BorrowDetailItem title='ISBN:' value={item.borItemIsbn} />
            <BorrowDetailItem title='Barcode:' value={item.borItemBarcode} />
            <BorrowDetailItem title='Call number:' value={item.borItemCallNumber} />
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

const ReturnAlert = (props) => {
  return (
    <div className="return-alert">
      <div className="title is-size-4-has-text-centered">Change return date</div>
      <div className="center">
        <Datepicker label='Return date' options={{ maxDate: 'today' }} handleChange={() => {}} />
      </div>
    </div>
  );
};
// const DetailItem = (title: string, value: string) => {
//   return (
//     <div className='detail-item'>
//       <span className='has-text-weight-bold'>{title}</span>
//       <br />
//       {value || 'nil'}
//     </div>
//   );
// };

// BorrowDetails.propTypes = {
//   borId: PropTypes.string.isRequired
// };

export default BorrowDetails;
