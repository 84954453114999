import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getItems, setBrowseShouldRefresh } from '../../redux/actions/browse-action';
import { showAlert } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';
import ListItem from './sub-components/list-item';
import './style.css';
import SearchContainer from './sub-components/search-container';
import FilterContainer from './sub-components/filter-container';
import Pagination from '../_helpers/pagination/pagination.comp';
import { apiPostUrlEncoded } from '../../helpers/Api';
import Details from './details';
import { ROUTES } from '../../constants';

class Browse extends Component {
  constructor(props) {
    super(props);
    const { shouldRefresh } = this.props.location.state || { shouldRefresh: false };
    this.state = {
      isLoading: this.props.browseRes === null || shouldRefresh || this.props.browseShouldRefresh,
      selectedCategories: this.props.browseReq.categories,
      selectedItem: null,
      isFilterVisible: false,
      isAdmin: this.props.location.pathname === ROUTES.admin.viewItems
    };
    console.log('browse constructor', this.state);
  }

  componentDidMount() {
    console.log('Browse location', this.props.location);
    if (this.state.isLoading) {
      this.apiGetItems();
    }
  }

  apiGetItems = (req = null) => {
    const onResponse = () => {
      this.setState({ isLoading: false });
    };
    this.props
      .getItems(req)
      .then(() => {
        onResponse();
      })
      .catch(() => {
        onResponse();
        showAlert({ type: 'error', title: 'Failed to load items' });
      });
  };

  toggleFilter = () => {
    this.setState(prevState => ({
      ...prevState,
      isFilterVisible: !prevState.isFilterVisible
    }));
  };

  onSearch = searchParams => {
    // console.log(e.keyCode, searchByAuthor, searchText);
    // if (e.keyCode !== 13) return;
    console.log('Search params', searchParams);
    this.apiGetItems({ page: 1, ...searchParams });
  };

  onFilterItemClick = e => {
    console.log(e.target.value, e.target.checked);
    const { value, checked } = e.target;
    if (value !== 'fav') {
      if (checked) {
        this.setState(
          prevState => ({
            ...prevState,
            selectedCategories: [...prevState.selectedCategories, value]
          }),
          () => {
            console.log('Selected categories', this.state.selectedCategories);
            this.apiGetItems({ page: 1, categories: this.state.selectedCategories });
          }
        );
      } else {
        this.setState(
          prevState => ({
            ...prevState,
            selectedCategories: prevState.selectedCategories.filter(val => val !== value)
          }),
          () => this.apiGetItems({ page: 1, categories: this.state.selectedCategories })
        );
      }
    } else {
      this.apiGetItems({ page: 1, getFavourites: checked });
    }
  };

  onPageSelect = page => {
    console.log('Selected page', page);
    this.apiGetItems({ page: page });
  };

  onListItemClick = itemId => {
    if (this.state.isAdmin) {
      this.props.history.push(ROUTES.admin.addItem + '?itemId=' + itemId);
    } else {
      this.props.history.push(`${ROUTES.itemDetails}/${itemId}`);
    }
  };

  render() {
    const { isFilterVisible } = this.state;
    const { isLoading, isAdmin } = this.state;
    const showList = () => {
      const { browseRes, browseReq } = this.props;
      if (browseRes !== null) {
        console.log(browseReq);
        const { items } = browseRes;
        return (
          <div className='container center-column'>
            <SearchContainer
              toggleFilter={this.toggleFilter}
              isFilterVisible={isFilterVisible}
              onSearch={this.onSearch}
              searchText={browseReq.searchText}
              searchByAuthor={browseReq.searchByAuthor}
            />
            {isFilterVisible ? (
              <FilterContainer
                itemCategories={browseRes.itemCategories}
                favValue={browseReq.getFavourites}
                selectedCategories={this.state.selectedCategories}
                onFilterItemClick={this.onFilterItemClick}
              />
            ) : null}
            {items.length === 0 ? <div style={{ marginTop: '1em', fontSize: '1.5em' }}>Nothing to show.</div> : null}
            <div className='item-list list'>
              {items.map(item => (
                <ListItem key={item.itemId} item={item} onListItemClick={this.onListItemClick} isAdmin={isAdmin} />
              ))}
            </div>
            <Pagination
              page={browseReq.page}
              limit={browseReq.limit}
              totalItems={browseRes.total}
              onPageSelect={this.onPageSelect}
            />
          </div>
        );
      }
      return null;
    };
    return <div className='comp-container comp-browse center-column'>{isLoading ? <Loader /> : showList()}</div>;
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron,
  browseRes: state.browseReducer.getItemsRes,
  browseReq: state.browseReducer.getItemsReq,
  browseShouldRefresh: state.browseReducer.shouldRefresh
});

Browse.propTypes = {
  patron: PropTypes.object.isRequired,
  browseRes: PropTypes.object.isRequired,
  browseReq: PropTypes.object.isRequired,
  browseShouldRefresh: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  setBrowseShouldRefresh: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getItems, setBrowseShouldRefresh })(Browse);
