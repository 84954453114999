import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import store from './redux/store';
import HttpsRedirect from './helpers/HttpsRedirect';

if (process.env.NODE_ENV !== 'development') console.log = () => {};
if (process.env.NODE_ENV !== 'development') console.warn = () => {};
if (process.env.NODE_ENV !== 'development') console.error = () => {};

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <App />
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
