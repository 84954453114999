import React from 'react';
import { ROUTES } from '../../constants';
import './admin-more.styles.scss';
import icoCheckout from '../../assets/ico_checkout.png';
import icoCheckin from '../../assets/ico_checkin.png';
import icoPartners from '../../assets/ico_partners.png';
import icoAdd from '../../assets/ico_add.png';
import icoView from '../../assets/ico_view.png';
import BoxLink from '../Index/logged-in/sub-components/box-link';
import Section from '../Index/logged-in/sub-components/section';

const AdminMore = ({ history }) => {
  console.log('admin more', history.location.pathname);
  const {
    location: { pathname }
  } = history;
  let linkList = [];
  let title = '';
  switch (pathname) {
    case ROUTES.admin.more:
      linkList = [
        { img: icoPartners, label: 'PARTNERS', link: ROUTES.admin.partners },
        { img: icoCheckin, label: 'BORROW', link: ROUTES.admin.borrowingVA },
        { img: icoCheckout, label: 'LENDING', link: ROUTES.admin.lendingVA }
      ];
      title = 'Inter-Library Loans (ILL)';
      break;
    case ROUTES.admin.lendingVA:
      linkList = [
        { img: icoAdd, label: 'ADD', link: ROUTES.admin.lend },
        { img: icoView, label: 'VIEW', link: ROUTES.admin.lendHistory }
      ];
      title = 'LENDING';
      break;
    default:
      linkList = [
        { img: icoAdd, label: 'ADD', link: ROUTES.admin.borrowAdd },
        { img: icoView, label: 'VIEW', link: ROUTES.admin.borrowing }
      ];
      title = 'BORROWING';
  }
  return (
    <div className='comp-admin-more comp-container'>
      <div className='comp-header'>
        <span className='title'>{title}</span>
      </div>
      <div className='container is-flex center'>
        <Section>
          {linkList.map((item, index) => (
            <BoxLink key={index} image={item.img} label={item.label} link={item.link} />
          ))}
          {/*<BoxLink image={icoIll} label='VIEW PARTNERS' link={ROUTES.admin.partners} />*/}
          {/*<BoxLink image={icoIll} label='LEND ITEMS' link={ROUTES.admin.lend} />*/}
          {/*<BoxLink image={icoIll} label='VIEW LENT ITEMS' link={ROUTES.admin.lendHistory} />*/}
          {/*<BoxLink image={icoIll} label='VIEW / ADD LOANED ITEMS' link={ROUTES.admin.borrowing} />*/}
        </Section>
      </div>
      {/*<div className='list is-radiusless'>*/}
      {/*  /!*{ADMIN_COMPS.map(item => (*!/*/}
      {/*  /!*  <ListItemAdmin listItem={item} />*!/*/}
      {/*  /!*))}*!/*/}
      {/*</div>*/}
    </div>
  );
};

export default AdminMore;
