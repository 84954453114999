import { combineReducers } from 'redux';
import patronReducer from './patronReducer';
import checkReducer from './check-out-in-reducer';
import browseReducer from './browse-reducer';
import historyReducer from './history-reducer';
import apiReducer from './api-reducer';
import { LOGOUT } from '../types';
import LendReducer from './lend.reducer';
import PostScanReducer from './post-scan.reducer';

const appReducer = combineReducers({
  patronReducer: patronReducer,
  checkReducer: checkReducer,
  browseReducer: browseReducer,
  historyReducer: historyReducer,
  apiReducer: apiReducer,
  lendReducer: LendReducer,
  postScanReducer: PostScanReducer
});

const rootReducer = (state, action) => {
  //Upon LOGOUT action clear the state
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
