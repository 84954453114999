export default function getLocalDate(gmtDateTimeStr, shouldConvert = true) {
  let date = new Date(gmtDateTimeStr);
  if (shouldConvert) date = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  let dateStr = date.toDateString();
  dateStr = dateStr.substr(4);
  return dateStr;
}

export const getTimeDiff = gmtDateTimeStr => {
  console.log('received date', gmtDateTimeStr);
  let date = new Date(gmtDateTimeStr + ' GMT');
  console.log('return date', date);
  let diffInHours = (date.getTime() - new Date().getTime()) / 3600000;
  console.log('difference in hours', diffInHours);
  return diffInHours;
};
