// flow
import React from 'react';
import PropTypes from 'prop-types';
import { Transaction } from '../../../models/history.res';
import { withRouter } from 'react-router-dom';

import './history-list-item.styles.scss';
import getLocalDate from '../../../helpers/get-local-date';
import HistoryDetailListItem from './history-detail-list-item.component';
import { ROUTES } from '../../../constants';

opaque type HistoryItemProp = {
  historyTransaction: Transaction
};
const HistoryListItem = (props: HistoryItemProp) => {
  const { historyTransaction, apiGetHistory, history } = props;
  const shouldShowPatronDetails = history.location.pathname === ROUTES.admin.allTransactions;
  return (
    <div className='list-item history-list-item'>
      <div className='is-flex trns-info'>
        <span>
          Check out date:{' '}
          <em>
            <b>{getLocalDate(historyTransaction.trnsDate, false)}</b>
          </em>
        </span>

        {historyTransaction.isAllReturned ? (
          <span className='returned has-text-success'>
            <em>ALL RETURNED</em>
          </span>
        ) : null}
        {shouldShowPatronDetails ? (
          <span>
            <em>
              <b>
                {historyTransaction.ptrnFirstname} {historyTransaction.ptrnLastname} ({historyTransaction.ptrnEmail})
              </b>
            </em>
          </span>
        ) : null}
      </div>
      Items checked out [{historyTransaction.items}]:
      <div className='list'>
        {historyTransaction.details.map(detail => (
          <HistoryDetailListItem trnsDetail={detail} apiGetHistory={apiGetHistory} />
        ))}
      </div>
    </div>
  );
};

HistoryListItem.defaultProps = {
  apiGetHistory: () => {}
};

HistoryListItem.propTypes = {
  historyTransaction: PropTypes.objectOf(Transaction),
  apiGetHistory: PropTypes.func
};

export default withRouter(HistoryListItem);
