/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HISTORY_CURRENT, HISTORY_THIRTY, HISTORY_THIRTY_OLDER, HISTORY_SIXTY_OLDER } from '../../redux/types';
import { getHistory } from '../../redux/actions/history-action';
import { showAlert } from '../../helpers/Alert';
import Loader from '../../layout/loader/loader';
import './style.css';
import HistoryItem from './sub-components/history-list-item';

class HistoryComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.historyState === null
    };
  }
  componentDidMount(): void {
    if (this.state.isLoading) {
      this.apiGetHistory();
    }
  }

  apiGetHistory = (historyMethod = HISTORY_CURRENT) => {
    const { getHistory } = this.props;
    const onResponse = () => {
      this.setState({
        isLoading: false
      });
    };
    getHistory(historyMethod)
      .then(() => {
        onResponse();
      })
      .catch(() => {
        onResponse();
        showAlert({ type: 'error', title: 'Failed to load history' });
      });
  };

  onHistorySelect = e => {
    this.apiGetHistory(e.target.value);
  };

  render() {
    const { isLoading } = this.state;
    const getHistoryView = () => {
      const {
        currentlyBorrowedIsAvailable,
        thirtyOldIsAvailable,
        thirtyToSixtyOldIsAvailable,
        olderThanSixtyIsAvailable,
        requestedList,
        selectedList
      } = this.props.historyState;
      // noinspection EqualityComparisonWithCoercionJS
      return currentlyBorrowedIsAvailable || thirtyOldIsAvailable || thirtyToSixtyOldIsAvailable || olderThanSixtyIsAvailable ? (
        <ul className='list-history list'>
          <li className='list-item center'>
            <div className='control'>
              <div className='select is-medium'>
                <select onChange={e => this.onHistorySelect(e)}>
                  {currentlyBorrowedIsAvailable ? (
                    <option selected={HISTORY_CURRENT == selectedList} value={HISTORY_CURRENT}>
                      Currently Checked-out
                    </option>
                  ) : null}
                  {thirtyOldIsAvailable ? (
                    <option selected={HISTORY_THIRTY == selectedList} value={HISTORY_THIRTY}>
                      Up to Thirty days
                    </option>
                  ) : null}
                  {thirtyToSixtyOldIsAvailable ? (
                    <option selected={HISTORY_THIRTY_OLDER == selectedList} value={HISTORY_THIRTY_OLDER}>
                      Over Thirty days
                    </option>
                  ) : null}
                  {olderThanSixtyIsAvailable ? (
                    <option selected={HISTORY_SIXTY_OLDER == selectedList} value={HISTORY_SIXTY_OLDER}>
                      Over Sixty days
                    </option>
                  ) : null}
                </select>
              </div>
            </div>
          </li>
          {requestedList.map(item => (
            <HistoryItem item={item} onSuccessfulRenew={this.apiGetHistory} />
          ))}
        </ul>
      ) : (
        <span className='no-history-message'>No history available</span>
      );
    };
    return <div className='comp-history comp-container container center-column'>{isLoading ? <Loader /> : getHistoryView()}</div>;
  }
}

HistoryComp.propTypes = {
  historyState: PropTypes.object,
  getHistory: PropTypes.func
};

const mapStateToProps = state => ({
  historyState: state.historyReducer.historyState
});

export default connect(
  mapStateToProps,
  { getHistory }
)(HistoryComp);
