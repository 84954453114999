import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './add-partner-style.css';

const PartnerFormField = props => {
  const { type, index, label, value, onChange, isRequired } = props;
  const [showErrMsg, setErrMsg] = useState({ msg: '', status: false });
  const onInputBlur = e => {
    const { value } = e.target;
    if (isRequired && value.trim() === '') {
      setErrMsg({ msg: 'This field is Required', status: true });
    } else {
      setErrMsg({ msg: '', status: false });
    }
    e.target.value = e.target.value.trim();
  };

  const handleChange = ({ target: { value } }) => onChange(index, value);

  return (
    <div className='field'>
      <label className='label'>{label}:</label>
      <div className='control'>
        {type != 'textarea' ? (
          <input
            type='text'
            onBlur={e => onInputBlur(e)}
            className={`input ${showErrMsg.status ? 'is-danger' : isRequired ? 'is-primary' : ''}`}
            placeholder={`Enter ${label.toLowerCase()}...`}
            defaultValue={value}
            onChange={e => handleChange(e)}
            required={isRequired}
          />
        ) : (
          <textarea
            rows={3}
            onBlur={onInputBlur}
            className={`input`}
            placeholder={`Enter ${label.toLowerCase()}...`}
            defaultValue={value}
            onChange={handleChange}
          />
        )}
        {showErrMsg.status ? <p className='help is-danger'>This field is required</p> : null}
      </div>
    </div>
  );
};
const AddPartner = props => {
  const { onSave, onChange, addPartnerReq } = props;
  return (
    <div className='container-add-partner'>
      <div className='form-container'>
        <PartnerFormField label={'Name'} onChange={onChange} index={'ooiName'} value={addPartnerReq.ooiName} isRequired={true} />

        <div className='is-divider' data-content='Contact info' />
        <PartnerFormField
          label={'Contact name'}
          onChange={onChange}
          index={'ooiContactName'}
          value={addPartnerReq.ooiContactName}
        />
        <PartnerFormField label={'Email'} onChange={onChange} index={'ooiEmail'} value={addPartnerReq.ooiEmail} />
        <PartnerFormField label={'Phone'} onChange={onChange} index={'ooiPhone'} value={addPartnerReq.ooiPhone} />
        <div className='is-divider' data-content='Address' />
        <PartnerFormField label={'Street'} onChange={onChange} index={'ooiStreet'} value={addPartnerReq.ooiStreet} />
        <PartnerFormField label={'Postal / Zip code'} onChange={onChange} index={'ooiPin'} value={addPartnerReq.ooiPin} />
        <PartnerFormField label={'Province'} onChange={onChange} index={'ooiProvince'} value={addPartnerReq.ooiProvince} />
        <PartnerFormField label={'Country'} onChange={onChange} index={'ooiCountry'} value={addPartnerReq.ooiCountry} />
        <div className='is-divider' data-content='Others' />
        <PartnerFormField
          label={'Description'}
          type='textarea'
          onChange={onChange}
          index={'ooiDesc'}
          value={addPartnerReq.ooiDesc}
        />
        {/*<div className='field'>*/}
        {/*  <label className='label'>Description:</label>*/}
        {/*  <div className='control'>*/}
        {/*    <textarea cols='3' className='input' placeholder={'Enter partner description...'} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

AddPartner.propTypes = {
  addPartnerReq: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddPartner;
