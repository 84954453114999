import React from 'react';
import PropTypes from 'prop-types';
import './grid-item.css';

const GridItem = props => {
  const { header, color, children, isChart } = props;
  return (
    <div className={`dashboard-item box ${isChart ? 'is-chart' : ''}`} style={{ minWidth: '13em', minHeight: '11.333em' }}>
      <button
        className={`heading is-size-6 item-header box has-text-centered ${color ? color : 'is-accent'}`}
        style={{ minWidth: '7em' }}>
        {header}
      </button>
      <div className='item-content'>{children}</div>
    </div>
  );
};

GridItem.propTypes = {
  header: PropTypes.string.isRequired,
  color: PropTypes.string
};
export default GridItem;
