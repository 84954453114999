import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BoxLink = props => {
  return (
    <div className='column center' style={{ whiteSpace: 'nowrap', maxWidth: '400px'}}>
      <Link className='box box-big box-link' to={props.link}>
        <img src={props.image} alt={props.label} />
        <span>{props.label}</span>
      </Link>
    </div>
  );
};

BoxLink.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default BoxLink;
