import React from 'react';
import PropTypes from 'prop-types';
import GridItem from '../grid-item';

const GridItemItem = props => {
  const { item } = props;
  return (
    <GridItem header={'Item'} color={'is-dark-blue'}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div className='level-item'>
            <div>
              <span className='heading'>Total</span>
              <span className='title is-size-4'>{item.total}</span>
            </div>
          </div>
          <div className='level-item'>
            <div>
              <span className='heading'>Available</span>
              <span className='title is-size-4'>{item.currentlyAvailable}</span>
            </div>
          </div>
          <div className='level-item'>
            <div>
              <span className='heading'>Checked-out</span>
              <span className='title is-size-4'>{item.currentlyCheckedOut}</span>
            </div>
          </div>
          <div className='level-item'>
            <div>
              <span className='heading'>Never loaned</span>
              <span className='title is-size-4'>{item.neverLoaned}</span>
            </div>
          </div>
        </div>
      </div>
      <span className='subtitle is-size-6'>IN</span>
      <div className='columns'>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <span className='heading'>Today</span>
                <span className='title is-size-5'>{item.returnedToday}</span>
              </div>
            </div>
            <div className='level-item' style={{ marginLeft: '0.5rem' }}>
              <div>
                <span className='heading'>7 days</span>
                <span className='title is-size-5'>{item.returnedIn7days}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <span className='heading'>30 days</span>
                <span className='title is-size-5'>{item.returnedIn30days}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className='subtitle is-size-6'>OUT</span>
      <div className='columns'>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <span className='heading'>Today</span>
                <span className='title is-size-5'>{item.borrowedToday}</span>
              </div>
            </div>
            <div className='level-item' style={{ marginLeft: '0.5rem' }}>
              <div>
                <span className='heading'>7 days</span>
                <span className='title is-size-5'>{item.borrowedIn7days}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='level is-mobile'>
            <div className='level-item'>
              <div>
                <span className='heading'>30 days</span>
                <span className='title is-size-5'>{item.borrowedIn30days}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

GridItemItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default GridItemItem;
