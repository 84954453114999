import React from 'react';
import PropTypes from 'prop-types';
import './lhli.styles.scss';
import getLocalDate from '../../../helpers/get-local-date';

const LendHistoryListItem = ({ item }) => {
  return (
    <div className='lhli-comp list-item'>
      <div className='left-part'>
        <span className='subtitle is-size-5' style={{ marginRight: '1rem' }}>
          <b>Lent on:</b> {getLocalDate(item.lendDate, false)}
        </span>
        {item.lendReturned ? (
          <span className='subtitle is-size-5'>
            <b>Returned on: </b> <span className='has-text-success'>{getLocalDate(item.lendReturned, false)}</span>
          </span>
        ) : (
          <span className='subtitle is-size-5'>
            <b>Due-back on: </b>
            {getLocalDate(item.lendDueback, false)}
          </span>
        )}
        <div className='subtitle is-size-5' style={{ marginTop: '0.5rem', marginBottom: '1.2rem' }}>
          {item.itdeBarcode}
        </div>
        <div className='title is-size-5'>{item.itemLbpTitle}</div>
      </div>
      <div className='right-part'>
        <div className='subtitle is-size-5 has-text-weight-bold'>
          {item.ooiName} <em>{item.ooiEmail ? `(${item.ooiEmail})` : ''}</em>
        </div>
        <div style={{ textAlign: 'right' }}>
          <b>File no:</b> <em>{item.lendFileNo ? item.lendFileNo : 'nil'}</em>
        </div>
      </div>
    </div>
  );
};

LendHistoryListItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default LendHistoryListItem;
