import React, { Component } from 'react';
import './App.css';
import login from './controllers/login';
import Patron from './models/Patron';
import './styles/style.css';
import './styles/helper-classes.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { verifyPatron } from './redux/actions/patron-action';

import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import Index from './components/Index/Index';
import Logout from './components/Logout/Logout';
import LaunchLoading from './components/launch-loading/launch-loading';

import { PrivateRoute, AdminRoute, NonPrivateRoute } from './helpers/app-routes';

import NotFound from './components/NotFound';
import Registration from './components/Registration/Registration';
import Checkout from './components/checkout-checkin/checkout';
import Browse from './components/browse/browse';
import Account from './components/account/account';
// import PrivateRoute from './helpers/PrivateRoute';
import { firstPath, ROUTES } from './constants';
import AssignBarcode from './components/assign-barcode/assign-barcode';
import Checkin from './components/checkout-checkin/checkin';
import HistoryComp from './components/history/history';
import Dashboard from './components/dashboard/dashboard';
import AddItem from './components/add-item/add-item';
// import AdminRoute from './helpers/AdminRoute';
import AddPatron from './components/add-patron/add-patron';
import Partners from './components/partners/partners';
import AdminMore from './components/admin-more/admin-more';
import ViewPatrons from './components/view-patrons/view-patrons';
import ScrollToTop from './components/_helpers/scroll-to-top';
import ForgotPassword from './components/forgot-password/forgot-password';
import OrgDetailsComponent from './components/org-details/org-details.component';
import HistoryComponent from './components/history/history.component';
import PatronDetailsComp from './components/patron-details/patron-details.component';
import ItemDetailsPage from './components/item-details/item-details.component';
import LendPage from './components/lend/lend.component';
import ViewPartnerPage from './components/view-partner/view-partner.component';
import LendHistoryPage from './components/lend-history/lend-history.component';
import BorrowPage from './components/borrow/borrow.component';
import BorrowAdd from './components/borrow/comp/borrow-add.component';

class App extends Component {
  state = {
    isSideNavbarVisible: false,
    isLoading: true
  };

  verifyPatron() {
    if (!this.state.isLoading) return;
    this.props.verifyPatron(status => {
      this.setState({
        ...this.state,
        isLoading: false
      });
    });
  }

  componentDidMount() {
    this.verifyPatron();
  }

  render() {
    const margin = (pathname = window.location.pathname) => {
      console.log(pathname);
      console.log(this.props.patron);
      return pathname === '/' && this.props.patron === null
        ? null
        : { position: 'relative', boxSizing: 'border-box', marginTop: '3rem' };
    };

    const onNavbarBurgerClick = value => {
      console.log('burger clicked', value);
      this.setState({
        ...this.state,
        isSideNavbarVisible: !value ? value : !this.state.isSideNavbarVisible
      });
    };

    const renderLoadingOrMain = () => {
      if (this.state.isLoading) {
        return <LaunchLoading />;
      } else {
        return (
          <React.Fragment>
            <Header
              patron={this.props.patron}
              onNavbarBurgerClick={onNavbarBurgerClick}
              isSideNavbarVisible={this.state.isSideNavbarVisible}
            />
            <div style={{ position: 'relative' }}>
              {this.state.isSideNavbarVisible ? (
                <div onClick={() => onNavbarBurgerClick(false)} className='overlay is-hidden-desktop' />
              ) : null}
              <Switch>
                <Route exact path={ROUTES.index} render={props => <Index {...props} margin={margin} />} />
                <NonPrivateRoute path='/registration' component={Registration} />
                <NonPrivateRoute path={ROUTES.forgotPass} component={ForgotPassword} />
                <PrivateRoute path={ROUTES.checkout} component={Checkout} />
                <PrivateRoute path={ROUTES.browse} component={Browse} />
                <AdminRoute path={`${ROUTES.history}/:ptrnId`} component={HistoryComponent} />
                <PrivateRoute path={ROUTES.history} component={HistoryComponent} />
                <PrivateRoute path={ROUTES.account} component={Account} />
                <PrivateRoute path={`${ROUTES.itemDetails}/:itemId/:itdeId`} component={ItemDetailsPage} />
                <PrivateRoute path={`${ROUTES.itemDetails}/:itemId`} component={ItemDetailsPage} />
                <AdminRoute path={ROUTES.admin.checkin} component={Checkin} />
                <AdminRoute path={ROUTES.admin.assignTag} component={AssignBarcode} />
                <AdminRoute path={ROUTES.admin.dashboard} component={Dashboard} />
                {/*<AdminRoute path={ROUTES.admin.orgDetails} component={OrgDetailsComponent} />*/}
                <AdminRoute path={ROUTES.admin.viewItems} component={Browse} />
                <AdminRoute path={ROUTES.admin.addItem} component={AddItem} />
                <AdminRoute path={`${ROUTES.admin.viewPatrons}/:ptrnId`} component={PatronDetailsComp} />
                <AdminRoute path={ROUTES.admin.viewPatrons} component={ViewPatrons} />
                <AdminRoute path={ROUTES.admin.addPatron} component={AddPatron} />
                <AdminRoute path={ROUTES.admin.allTransactions} component={HistoryComponent} />
                <AdminRoute path={`${ROUTES.admin.partners}/:ooiId`} component={ViewPartnerPage} />
                <AdminRoute path={ROUTES.admin.partners} component={Partners} />
                <AdminRoute path={ROUTES.admin.lend} component={LendPage} />
                <AdminRoute path={ROUTES.admin.lendHistory} component={LendHistoryPage} />
                <AdminRoute path={ROUTES.admin.borrowing} component={BorrowPage} />
                <AdminRoute path={ROUTES.admin.more} component={AdminMore} />
                <AdminRoute path={ROUTES.admin.lendingVA} component={AdminMore} />
                <AdminRoute path={ROUTES.admin.borrowingVA} component={AdminMore} />
                <AdminRoute path={ROUTES.admin.borrowAdd} component={BorrowAdd} />
                <Route path={ROUTES.logout} render={props => <Logout {...props} closeSideNav={closeSideNavbar} />} />
                <Route component={NotFound} />
              </Switch>
              {this.props.patron !== null ? <Footer isLoggedIn={this.props.patron !== null} /> : null}
            </div>
          </React.Fragment>
        );
      }
    };

    const closeSideNavbar = () => {
      onNavbarBurgerClick(false);
    };
    return (
      <Router basename={firstPath === 'blast-test' ? '/blast-test' : '/'}>
        <ScrollToTop>
          <div className='App'>{renderLoadingOrMain()}</div>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});

export default connect(mapStateToProps, { verifyPatron })(App);
