/*flow*/
export class AddPatronReq {
  ptrnFirstname: string;
  ptrnLastname: string;
  ptrnEmail: string;
  ptrnIsAdmin: boolean;
  shouldEmail: boolean;
  constructor() {
    this.ptrnFirstname = '';
    this.ptrnLastname = '';
    this.ptrnEmail = '';
    this.ptrnIsAdmin = false;
    this.shouldEmail = false;
  }
}
