import React from 'react';
import PropTypes from 'prop-types';

const SearchContainer = props => {
  const { toggleFilter, isFilterVisible, onSearch, searchByAuthor, searchText } = props;
  const inputSearch = React.createRef();
  const inputSearchMethod = React.createRef();
  // const handleOnSearch = () => {
  //   onSearch(null, inputSearchMethod.current.value, inputSearch.current.value);
  // };
  /*let searchMethod = false;
  const onSelectSearchMethod = e => {
    searchMethod = e.target.value;
    onSearch(null, inputSearchMethod.current.value, inputSearch.current.value);
  };*/

  return (
    <div className='search-container center'>
      {/*<div className='control has-icons-right'>
        <input type='text' className='input is-rounded is-primary' placeholder='Enter title...' />
        <div className='button is-rounded is-primary'>
          <i className='fas fa-search' />
        </div>
      </div>*/}
      <div className='field has-addons has-addons-centered'>
        <p className='control'>
          <span className='select'>
            <select
              value={searchByAuthor}
              onChange={e => {
                // noinspection EqualityComparisonWithCoercionJS
                onSearch({ searchByAuthor: e.target.value == 'true' });
              }}
              ref={inputSearchMethod}>
              <option value={false}>Title</option>
              <option value={true}>Author</option>
            </select>
          </span>
        </p>
        <p className='control has-icons-right'>
          <input
            type='text'
            className='input is-primary'
            placeholder={'Search...'}
            value={searchText}
            onChange={e => onSearch({ searchText: e.target.value })}
            ref={inputSearch}
          />
          {searchText !== '' ? (
            <span
              className='icon is-small is-right tooltip is-tooltip-bottom'
              data-tooltip='Clear search'
              style={{ pointerEvents: 'auto', cursor: 'pointer' }}
              onClick={() => onSearch({ searchText: '' })}>
              <i className='fas fa-times' />
            </span>
          ) : null}
        </p>
        <p className='control'>
          <span className='button is-primary' onClick={() => onSearch({})}>
            <i className='fas fa-search' />
          </span>
        </p>
        <p className='control'>
          <span className={'button is-info' + (!isFilterVisible ? ' is-outlined' : '')} onClick={toggleFilter}>
            <i className='fas fa-filter' />
          </span>
        </p>
      </div>
    </div>
  );
};

SearchContainer.propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  isFilterVisible: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchByAuthor: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired
};

export default SearchContainer;
