import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from '../../assets/blast_custom_logo.png';
import NotLoggedIn from './NotLoggedIn';

import { loginPatron } from '../../redux/actions/patron-action';
import LoggedIn from './LoggedIn';
import { Redirect } from 'react-router-dom';

class Index extends Component {
  // componentDidMou nt(): void {
  //   console.log(this.props.patron);
  // }
  //
  // loginPatron(event) {
  //   const orgId = this.orgIdInput.current.value;
  //   const ptrnEmail = this.emailInput.current.value;
  //   const ptrnPass = this.passwordInput.current.value;
  //   event.preventDefault();
  //   this.props.loginPatron(orgId, ptrnEmail, ptrnPass);
  // }
  constructor() {
    super();
    this.state = {
      notLoggedIn: {
        loginStatusMessage: null,
        loginButtonIsLoading: false
      },
      loggedIn: {
        shouldRedirect: false
      }
    };
  }

  componentWillMount(): void {
    // console.log('Index.js', this.props.history.location.pathname);
    this.props.margin(this.props.history.location.pathname);
  }

  onLogin(event, orgId, ptrnEmail, ptrnPassword) {
    event.preventDefault();
    this.setState({
      ...this.state,
      notLoggedIn: { ...this.state.notLoggedIn, loginButtonIsLoading: true }
    });
    this.props.loginPatron(orgId, ptrnEmail, ptrnPassword, status => {
      if (status === 401) {
        this.setState({
          ...this.state,
          notLoggedIn: {
            ...this.state.notLoggedIn,
            loginStatusMessage: 'Invalid credentials',
            loginButtonIsLoading: false
          }
        });
      } else if (status === 200) {
        this.setState({
          ...this.state,
          notLoggedIn: {
            ...this.state.notLoggedIn,
            loginButtonIsLoading: false
          },
          loggedIn: {
            ...this.state.loggedIn,
            shouldRedirect: true
          }
        });
      } else {
        this.setState({
          ...this.state,
          notLoggedIn: {
            ...this.state.notLoggedIn,
            loginStatusMessage: 'Connection Error',
            loginButtonIsLoading: false
          }
        });
      }
    });
  }

  render() {
    const { patron } = this.props;
    const { from } = this.props.location.state || { from: null };
    const { forAdminOnly } = this.props.location.state || { forAdminOnly: false };
    return patron == null ? (
      <NotLoggedIn onLogin={this.onLogin.bind(this)} state={this.state.notLoggedIn} />
    ) : this.state.loggedIn.shouldRedirect && from !== null ? (
      forAdminOnly && !patron.ptrnIsAdmin ? (
        <LoggedIn />
      ) : (
        <Redirect to={from} />
      )
    ) : (
      <LoggedIn />
    );
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});
export default connect(
  mapStateToProps,
  { loginPatron }
)(Index);
