import { LOGIN, LOGOUT, PATRON_CHANGE_EMAIL, VERIFY } from '../types';
import { axiosPostUrlEncoded, apiPostUrlEncoded, apiGet } from '../../helpers/Api';

const APP_NAME = 'beaconblast.net';

export const loginPatron = (orgId, ptrnIdOrEmail, ptrnPassword, callback) => dispatch => {
  apiPostUrlEncoded('/patron/login-auth.php', {
    orgId: orgId,
    ptrnIdOrEmail: ptrnIdOrEmail,
    ptrnPassword: ptrnPassword,
    shouldRemember: true
  })
    .then(res => {
      dispatch({
        type: LOGIN,
        payload: res.data
      });
      callback(res.status);
    })
    .catch(err => {
      callback(err.response.status);
    });
};

export const logoutPatron = () => dispatch => {
  dispatch({
    type: LOGOUT,
    payload: null
  });
  return apiGet('/patron/logout.php', { shouldLogoutOthers: false })
    .then(res => {
      console.log(res.status);
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject();
    });
};

export const verifyPatron = callback => dispatch => {
  apiGet('/verification/verify-token.php', { issuer: APP_NAME })
    .then(res => {
      dispatch({
        type: VERIFY,
        payload: res.data
      });
      callback(res.status);
    })
    .catch(err => {
      callback(err.status);
    });
};

export const changePatronEmail = newEmail => (dispatch, getState) => {
  const {
    patronReducer: { patron }
  } = getState();
  newEmail = newEmail.trim().toLowerCase();
  console.log('current email', patron.ptrnEmail);
  console.log('new email', newEmail);
  if (newEmail === '') return Promise.reject('Please provide an email address');
  const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!pattern.test(newEmail)) return Promise.reject('Invalid email');
  if (patron.ptrnEmail.trim().toLowerCase() === newEmail) return Promise.reject('Same as current email');
  return apiPostUrlEncoded('patron/email-reset.php', { newEmail: newEmail })
    .then(() => {
      dispatch({
        type: PATRON_CHANGE_EMAIL,
        payload: newEmail
      });
      return Promise.resolve();
    })
    .catch(() => Promise.reject('Failed to update email'));
};
