import React from 'react';
import PropTypes from 'prop-types';
import GridItem from '../grid-item';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants';

const GridItemIll = ({ ill }) => {
  return (
    <GridItem header={'ILL'} color={'is-purple'}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div>
            <span className='heading'>
              Total <br />
              Lends / Borrows
            </span>
            <span className='title is-size-4'>
              <Link to={{ pathname: ROUTES.admin.lendHistory, state: { filterBy: 0 } }}>{ill.totalLent}</Link> /{' '}
              <Link to={{ pathname: ROUTES.admin.borrowing, state: { filterBy: 0 } }}>{ill.totalBorrows}</Link>
            </span>
          </div>
        </div>
      </div>
      <div className='level is-mobile'>
        <div className='level-item'>
          <div>
            <Link to={{ pathname: ROUTES.admin.lendHistory, state: { filterBy: 1 } }}>
              <span className='heading'>
                Currently
                <br />
                Lent
              </span>
              <span className='title is-size-5'>{ill.currentlyLent}</span>
            </Link>
          </div>
        </div>
        <div className='level-item' style={{ marginLeft: '0.5rem' }}>
          <div>
            <Link to={{ pathname: ROUTES.admin.borrowing, state: { filterBy: 1 } }}>
              <span className='heading'>
                Currently
                <br />
                Borrowed
              </span>
              <span className='title is-size-5'>{ill.currentlyBorrowing}</span>
            </Link>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default GridItemIll;
