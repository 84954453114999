// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiGet } from '../../../helpers/Api';
import { showAlert } from '../../../helpers/Alert';
import BorrowListItem from './borrow-list-item.component';
import type { BorrowFilterValue, BorrowFilterOnChange } from './borrow-filter.component';
import BorrowFilter from './borrow-filter.component';

type BorrowViewFilterProps = {
  filterValue: BorrowFilterValue,
  orgDropdownList: [],
  patronDropdownList: [],
  handleFilterValueChange: BorrowFilterOnChange
};

type BorrowViewProps = {
  setCurrentPage: () => void,
  filterProps: BorrowViewFilterProps
};
const BorrowView = (props: BorrowViewProps) => {
  const { setCurrentPage, filterProps } = props;
  const [res, setRes] = useState({ total: 0, list: [] });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleOnAdd = () => {
    setCurrentPage({ page: 2, props: {} });
  };
  useEffect(() => {
    apiGet('/admin/borrow/get-borrows.php', { ...filterProps.filterValue })
      .then(res => setRes(res.data))
      .catch(() => showAlert({ type: 'error', title: 'Failed to fetch borrowed items' }));
  }, [filterProps]);

  const handleOnItemClick = borId => {
    setCurrentPage({ page: 2, props: { borId } });
  };

  const handleOnFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className='view-comp'>
      <div className='inputs-container'>
        <button className='button is-info is-large' onClick={handleOnFilterClick}>
          <i className='fas fa-search' style={{ marginRight: '1rem' }} /> Filter{' '}
          <i className={`fas fa-caret-${isFilterOpen ? 'up' : 'down'}`} style={{ marginLeft: '1rem' }} />
        </button>
        {/*<button className='button is-primary is-large' style={{ marginLeft: '2rem' }} onClick={handleOnAdd}>*/}
        {/*  <i className='fas fa-plus' style={{ marginRight: '1rem' }} /> Add*/}
        {/*</button>*/}
      </div>

      {isFilterOpen ? (
        <BorrowFilter
          values={filterProps.filterValue}
          onChange={filterProps.handleFilterValueChange}
          orgDropdownList={filterProps.orgDropdownList}
          patronDropdownList={filterProps.patronDropdownList}
        />
      ) : null}

      <div className='borrow-list list'>
        {res.list.map(item => (
          <BorrowListItem key={item.borId} item={item} onClick={() => handleOnItemClick(item.borId)} />
        ))}
      </div>
    </div>
  );
};

BorrowView.propTypes = {};

export default BorrowView;
