import React from 'react';

const CopyListItem = props => {
  const { itemCopy, colorFlag, onSelect } = props;
  const style = {
    levelLeft: {
      maxWidth: 'calc(100% - 5em)',
      overflowWrap: 'breakWord',
      hyphens: 'auto'
    },
    levelRight: {
      maxWidth: '5em'
    }
  };
  return (
    <li className={`list-item ${colorFlag ? 'has-background-white-ter' : 'has-background-white-bis'}`}>
      <div>
        <div className='level is-mobile'>
          <div className='level-left' style={style.levelLeft}>
            <div className='level-item' style={{ maxWidth: '100%' }}>
              <div>
                <span className='subtitle is-size-6'>
                  Copy No. <em>{itemCopy.itdeCopy !== '' ? itemCopy.itdeCopy : 'nil'}</em>
                </span>{' '}
                <span className='subtitle is-size-6' style={{ marginLeft: '1.5rem' }}>
                  Call No. <em>{itemCopy.itdeCallNumber !== '' ? itemCopy.itdeCallNumber : 'nil'}</em>
                </span>{' '}
                <br />
                <span className='subtitle is-size-6'>
                  Located at: <em>{itemCopy.itloName !== '' ? itemCopy.itloName : 'nil'}</em>
                </span>
              </div>
            </div>
          </div>
          <div className='level-right' style={style.levelRight}>
            <div className='level-item'>
              <div
                className='button is-small is-rounded is-link is-outlined'
                style={{ marginRight: '0.2rem' }}
                onClick={onSelect}>
                Select
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
const ListItem = props => {
  const { listItem, onSelect } = props;
  let colorFlag = true;
  const style = {
    levelLeft: {
      maxWidth: 'calc(100% - 3.8em)',
      overflowWrap: 'breakWord',
      hyphens: 'auto'
    },
    levelRight: {
      maxWidth: '3.8em',
      overflowWrap: 'breakWord'
    }
  };
  return (
    <div className='list-item-ab box'>
      <div className='level is-mobile' style={{ marginBottom: 0 }}>
        <div className='level-left' style={style.levelLeft}>
          <div className='level-item is-multiline' style={{ width: '100%' }}>
            <div>
              <span className='title is-size-6'>
                {listItem.itemLbpTitle} {listItem.itemVol ? <em>Vol: {listItem.itemVol}</em> : null}
              </span>
              <br />
              {listItem.itemLbpAuthor && listItem.itemLbpAuthor !== null ? (
                <span className='subtitle is-size-6'>
                  {listItem.itemLbpAuthor}
                  <br />
                </span>
              ) : null}
              <em className='subtitle is-size-6'>{listItem.ittyName}</em>
            </div>
          </div>
        </div>
        <div className='level-right' style={style.levelRight}>
          <div className='level-item has-text-centered'>
            <div>
              <div className='heading'>
                Tagged
                <br />
                <em>
                  {listItem.total - listItem.untaggedTotal}/{listItem.total}
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='ul list' style={{ marginTop: '0.5rem' }}>
        {/*<li className='list-item list-header has-background-white-ter'>Copies</li>*/}
        {listItem.itemCopies.map(itemCopy => {
          colorFlag = !colorFlag;
          return (
            <CopyListItem
              itemCopy={itemCopy}
              colorFlag={colorFlag}
              onSelect={() =>
                onSelect(
                  `${listItem.itemLbpTitle} ${listItem.itemVol ? `Vol: ${listItem.itemVol}` : ''}`,
                  itemCopy.itdeCopy,
                  itemCopy.itdeId
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListItem;
