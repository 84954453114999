import React, { Component } from 'react';
import FpStepOne from './sub-components/step-one';
import FpStepTwo from './sub-components/step-two';
import { apiGet, apiPostUrlEncoded } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      isLoading: false
    };

    this.stepOneValues = {
      orgId: '',
      email: ''
    };
    this.stepTwoValues = {
      number: '',
      pass: '',
      cpass: ''
    };
  }

  apiRequestResetCode = (shouldReset = false) => {
    if (this.state.isLoading) return;
    const { orgId, email } = this.stepOneValues;

    if (orgId.trim() === '' || email.trim() === '') {
      showAlert({ type: 'error', title: 'All fields are required' });
      return;
    }
    const req = {
      orgId: orgId,
      ptrnEmail: email,
      resetCode: shouldReset
    };

    this.setState(
      {
        isLoading: true
      },
      () => {
        apiGet('/patron/forgot-password.php', req)
          .then(() =>
            this.setState({
              isLoading: false,
              step: 2
            })
          )
          .catch(err => {
            this.setState({
              isLoading: false
            });
            if (err.response.status === 404 || err.response.status === 401) {
              showAlert({ type: 'error', title: 'Please check your Org ID and Email' });
            } else {
              showAlert({ type: 'error', title: 'Some error occurred' });
            }
          });
      }
    );
  };

  apiChangePassword = () => {
    if (this.state.isLoading) return;
    const { number, pass, cpass } = this.stepTwoValues;
    const { orgId, email } = this.stepOneValues;
    if (number === '' || pass === '' || cpass === '') {
      showAlert({ type: 'error', title: 'All fields are required' });
      return;
    }
    if (pass !== cpass) {
      showAlert({ type: 'error', title: "Passwords don't match" });
      return;
    }

    const req = {
      orgId: orgId,
      ptrnEmail: email,
      ptrnPassword: pass,
      code: number
    };

    this.setState({ isLoading: true }, () => {
      apiPostUrlEncoded('patron/forgot-password.php', req)
        .then(() => {
          this.setState({
            isLoading: false
          });
          showAlert({ type: 'success', title: 'Password changed' });
          this.props.history.push('/');
        })
        .catch(err => {
          this.setState({
            isLoading: false
          });
          if (err.response.status === 406)
            showAlert({ type: 'error', title: '6-digit reset number has expired', text: 'Please request for a new number' });
          else if (err.response.status === 404) showAlert({ type: 'error', title: 'Invalid 6-digit number provided' });
          else showAlert({ type: 'error', title: 'Some error occurred' });
        });
    });
  };

  onNextDoneClick = () => {
    const { step } = this.state;
    if (step === 1) this.apiRequestResetCode();
    else this.apiChangePassword();
  };

  onPrevClick = () => {
    this.setState({ step: 1 });
  };

  render() {
    const { step, isLoading } = this.state;
    return (
      <div>
        <div className='comp-header title'>Recover password</div>
        <div className='comp-container center-column'>
          <div className='box' style={{ maxWidth: '26rem', width: '100%', margin: 'auto' }}>
            {step === 1 ? (
              <FpStepOne
                defaultState={this.stepOneValues}
                onInputsChange={values => {
                  console.log(values);
                  this.stepOneValues = values;
                }}
              />
            ) : (
              <FpStepTwo
                onInputsChange={values => {
                  this.stepTwoValues = values;
                }}
                onResendNumber={() => this.apiRequestResetCode(true)}
              />
            )}
            <hr style={{ margin: '1.5rem 0 1rem 0' }} />
            <span className='btn-footer right'>
              {step !== 1 ? (
                <div className='button is-info' onClick={this.onPrevClick}>
                  Prev
                </div>
              ) : null}
              <div
                className={'button ' + (step === 1 ? 'is-info' : 'is-success') + (isLoading ? ' is-loading' : '')}
                style={{ marginLeft: '1rem' }}
                onClick={this.onNextDoneClick}>
                {step === 1 ? 'Next' : 'Done'}
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
