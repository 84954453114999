import React from 'react';
import getLocalDate from '../../../helpers/get-local-date';

const ItemCopyAt = ({ org }) => {
  const { lendDate, lendDueback, ooiId, ooiName, ooiEmail, ooiPhone } = org;
  return (
    <div className='item-copy-with box has-background-white-bis' style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
      <div>
        {ooiName} (Email: {ooiEmail}}) <br />
        {ooiPhone ? `(Ph: ${ooiPhone})` : null}
      </div>
      <div>
        <div className='has-text-weight-semibold'>Lent on: </div>
        <div>{getLocalDate(lendDate, false)}</div>
        <div className='has-text-weight-semibold'>Due back on: </div>
        <div>{getLocalDate(lendDueback, false)}</div>
      </div>
    </div>
  );
};

export default ItemCopyAt;
