import React from 'react';
import PropTypes from 'prop-types';
import GridItem from '../grid-item';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants';

const GridItemPatron = props => {
  const { patron } = props;
  return (
    <GridItem header={'Patron'} color={'is-pink'}>
      <div className='level is-mobile'>
        <div className='level-left'>
          <div>
            <span className='heading'>Total</span>
            <span className='title is-size-4'><Link to={ROUTES.admin.viewPatrons}>{patron.total}</Link></span>
          </div>
        </div>
      </div>
      <div className='level is-mobile'>
        <div className='level-item'>
          <div>
            <span className='heading'>
              With
              <br />
              Items
            </span>
            <span className='title is-size-5'>{patron.withItems}</span>
          </div>
        </div>
        <div className='level-item' style={{ marginLeft: '0.5rem' }}>
          <div>
            <span className='heading'>
              Never
              <br />
              Borrowed
            </span>
            <span className='title is-size-5'>{patron.neverBorrowed}</span>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

GridItemPatron.propTypes = {
  patron: PropTypes.object.isRequired
};

export default GridItemPatron;
