import { CHECKOUT, CHECKIN, ADD_CHECKIN_ITEM, REMOVE_CHECKIN_ITEM, ADD_CHECKOUT_ITEM, REMOVE_CHECKOUT_ITEM } from '../types';
import { apiPostJson, apiPostUrlEncoded } from '../../helpers/Api';

const isItemAlreadyPresent = (list, barcode) => {
  for (const item of list) {
    if (item.itdeBarcode == barcode) return true;
  }
  return false;
};

const getBarcodesForCheckin = state => {
  const {
    checkReducer: { checkinList }
  } = state;
  const barcodes = [];
  for (const item of checkinList) {
    /** @namespace item.itdeBarcodes */
    barcodes.push(item.itdeBarcode);
  }
  return barcodes;
};

const getIdsForCheckout = state => {
  const {
    checkReducer: { checkoutList }
  } = state;
  const ids = [];
  for (const item of checkoutList) {
    ids.push(item.itdeId);
  }
  return ids;
};

export const checkinItems = () => (dispatch, getState) => {
  const barcodes = getBarcodesForCheckin(getState());
  if (barcodes.length === 0) return Promise.reject({ type: 'warning', title: 'Nothing to checkin' });
  return apiPostJson('/transaction/v2/checkin.php', { items: barcodes, isBarcode: true })
    .then(res => {
      dispatch({
        type: CHECKIN,
        payload: []
      });
      return Promise.resolve({ type: 'success', title: 'Checkin Successful' });
    })
    .catch(err => {
      return Promise.reject({ type: 'error', title: 'Checkin Failed' });
    });
};

export const addCheckinItem = barcode => (dispatch, getState) => {
  const {
    checkReducer: { checkinList }
  } = getState();
  if (isItemAlreadyPresent(checkinList, barcode)) return Promise.reject({ type: 'warning', title: 'Item already present' });
  return apiPostUrlEncoded('/transaction/v2/post-scan-check.php', {
    itdeBarcode: barcode
  })
    .then(res => {
      const item = res.data;
      dispatch({
        type: ADD_CHECKIN_ITEM,
        payload: item
      });
      return Promise.resolve();
    })
    .catch(err => {
      if (err.response.status === 404) return Promise.reject({ type: 'error', title: 'Item not found' });
      return Promise.reject({ type: 'error', title: 'Something went wrong' });
    });
};

export const checkoutItems = () => (dispatch, getState) => {
  const ids = getIdsForCheckout(getState());
  if (ids.length === 0) return Promise.reject({ type: 'warning', title: 'Nothing to checkout' });
  return apiPostJson('/transaction/v2/checkout.php', { items: ids, shouldSendReceipt: true })
    .then(res => {
      dispatch({
        type: CHECKOUT,
        payload: []
      });
      return Promise.resolve({ type: 'success', title: 'Checkout Successful' });
    })
    .catch(err => {
      return Promise.reject({ type: 'error', title: 'Checkout Failed' });
    });
};

export const addCheckoutItem = barcode => (dispatch, getState) => {
  const {
    checkReducer: { checkoutList }
  } = getState();
  if (isItemAlreadyPresent(checkoutList, barcode)) return Promise.reject({ type: 'warning', title: 'Item already present' });
  return apiPostUrlEncoded('/transaction/v2/post-scan-check.php', {
    itdeBarcode: barcode
  })
    .then(res => {
      const item = res.data;
      console.log('addCheckoutItem Action', item);
      /** @namespace item.isAvailable */
      if (!item.isAvailable) return Promise.reject({ type: 'error', title: 'Item not available' });
      dispatch({
        type: ADD_CHECKOUT_ITEM,
        payload: item
      });
      return Promise.resolve();
    })
    .catch(err => {
      if (err.response) {
        if (err.response.status === 404) return Promise.reject({ type: 'error', title: 'Item not found' });
      }
      if (err.type) return Promise.reject(err);
      return Promise.reject({ type: 'error', title: 'Something went wrong' });
    });
};

export const removeCheckoutItemAt = itdeId => dispatch => {
  dispatch({
    type: REMOVE_CHECKOUT_ITEM,
    payload: itdeId
  });
};

export const removeCheckinItemAt = itdeId => dispatch => {
  dispatch({
    type: REMOVE_CHECKIN_ITEM,
    payload: itdeId
  });
};
