import React from 'react';
import PropTypes from 'prop-types';

import './form-input.styles.scss';

const FormInput = ({ type, value, name, label, placeholder, onChange, inputClass, msg, required, disabled, rows, ...others }) => {
  return (
    <div className='form-input-comp field'>
      <label className='label'>{label}</label>
      <div className='control'>
        {type !== 'textarea' ? (
          <input
            type={type}
            className={`input ${inputClass}`}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder || label}
            disabled={disabled}
            required={required}
            {...others}
          />
        ) : (
          <textarea
            name={name}
            placeholder={placeholder || label}
            rows={rows}
            value={value}
            className={`textarea ${inputClass}`}
            onChange={onChange}
            required={required}
            disabled={disabled}
            {...others}
          />
        )}
      </div>
    </div>
  );
};

FormInput.defaultProps = {
  inputClass: '',
  type: 'text',
  rows: 4,
  required: false,
  disabled: false
};

FormInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  inputClass: PropTypes.string
};

export default FormInput;
