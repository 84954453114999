import React from 'react';

export default function ListItem(props) {
  const { item, onDelete } = props;
  return (
    <li className='box list-item'>
      <span className='subtitle is-size-5'>{item.itdeBarcode}</span>
      <br />
      <span className='title is-size-5'>{item.itemLbpTitle}</span>
      <span className='delete' onClick={onDelete.bind(this, item.itdeId)} />
    </li>
  );
}
