import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logo from '../../../assets/blast_custom_logo.png';

function NavbarMain(props) {
  const style = {
    height: '3em',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1
  };
  return (
    <div className='custom-navbar center has-background-primary' style={style}>
      <Link to='/' style={{ height: '2em' }}>
        <img src={logo} alt='BLAST' style={{ height: '100%' }} />
      </Link>
    </div>
  );
}

NavbarMain.propTypes = {};

export default NavbarMain;
