import React from 'react';
import PropTypes from 'prop-types';

import './item-copy-details.styles.scss';
import ItemCopyWith from '../item-copy-with/item-copy-with.component';
import ItemCopyAt from '../item-copy-at/item-copy-at.component';

const ItemCopyDetails = ({ copy, onBack }) => {
  const {
    available,
    currently: { patron, org }
  } = copy;
  const lableValueArrayMap = [
    {
      key: 'itdeBarcode',
      label: 'Barcode'
    },
    {
      key: 'itloName',
      label: 'Location in library'
    },
    {
      key: 'itdeCallNumber',
      label: 'Call number'
    },
    {
      key: 'itdeIsbn',
      label: 'ISBN'
    },
    {
      key: 'itdeNotes',
      label: 'Notes'
    },
    {
      key: 'itdeLendLimit',
      label: 'Lend limit (days)'
    },
    {
      key: 'itdeRenews',
      label: 'Renews allowed'
    },
    {
      key: 'itdeRenewPeriod',
      label: 'Renew period (days)'
    }
  ];

  return (
    <div className='item-copy-details list-item'>
      <div className='header-container'>
        <div className='copy-title-container'>
          <i className={`fas ${available ? 'fa-check has-text-success' : 'fa-times has-text-danger'}`} /> Copy {copy.itdeCopy}
        </div>
        <div className='back-container' onClick={onBack}>
          <i className='fas fa-caret-left' /> Back
        </div>
      </div>
      <div>
        <div className='info-container'>
          {lableValueArrayMap.map(item =>
            copy[item.key] ? (
              <div className='info-item'>
                <div className='info-label has-text-weight-semibold'>{item.label}: </div>
                <div className='info-value'>{copy[item.key]}</div>
              </div>
            ) : null
          )}
          {patron ? (
            <div className='info-item with-container'>
              <div className='info-label has-text-weight-semibold'>Currently with:</div>
              <div className='info-value'>
                <ItemCopyWith patron={patron} />
              </div>
            </div>
          ) : null}
          {org ? (
            <div className='info-item with-container'>
              <div className='info-label has-text-weight-semibold'>Currently At:</div>
              <div className='info-value'>
                <ItemCopyAt org={org} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ItemCopyDetails.propTypes = {
  copy: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired
};

export default ItemCopyDetails;
