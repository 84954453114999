import { apiGet } from '../../helpers/Api';
import { showAlert } from '../../helpers/Alert';

const getPatrons = () => async dispatch => {
  try {
    const res = await apiGet('admin/patron/get-patrons.php');
    return res.data;
  } catch (e) {
    showAlert({ type: 'error', title: 'Failed to fetch patrons' });
    throw e;
  }
};

const getPatronDropdownList = () => async dispatch => {
  try {
    const data = await dispatch(getPatrons());
    const { patrons } = await data;
    return patrons.map(({ ptrnId, ptrnFirstname, ptrnLastname, ptrnEmail }) => ({
      key: ptrnId,
      value: ptrnId,
      text: `${ptrnFirstname} ${ptrnLastname} (${ptrnEmail})`
    }));
  } catch (e) {
    throw e;
  }
};

const PatronActions = {
  getPatrons,
  getPatronDropdownList
};

export default PatronActions;
