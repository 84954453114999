/* flow */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

type TypeType = 'success' | 'error' | 'warning' | 'info' | 'question';
type Config = {
  title: string | HTMLElement,
  html: HTMLElement,
  text: string,
  type: TypeType,
  footer: string | HTMLElement,
  input: HTMLInputElement,
  inputValue: string,
  inputPlaceholder: string,
  inputOptions: string,
  inputValidator(): Promise,
  validationMessage: string,
  allowOutsideClick: boolean,
  allowEnterKey: boolean,
  allowEscapeKey: boolean,
  showLoaderOnConfirm: boolean,
  showConfirmButton: boolean,
  showCancelButton: boolean,
  confirmButtonText: string,
  cancelButtonText: string,
  preConfirm(): Promise,
  onAfterClose(): null
};

const MySwal = withReactContent(Swal);
const defaultAlertConfig = {
  customClass: {
    input: 'input is-rounded',
    confirmButton: 'button is-primary is-rounded',
    cancelButton: 'button is-danger is-rounded'
  }
};

const defaultToastOptions = {
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 3500
};

export const alertIsLoading = () => MySwal.isLoading();

export const alertShowValidationMessage = message => MySwal.showValidationMessage(message);

export const showAlert = (config: Config, customConfig = false) => {
  return customConfig ? MySwal.fire({ ...config }) : MySwal.fire({ ...config, ...defaultAlertConfig });
};

export const showToast = (config: Config, options = {}) => {
  const Toast = Swal.mixin({ ...options, ...defaultToastOptions });
  return Toast.fire(config);
};
