import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListItemAcc from './sub-components/ListItemAcc';
import './style.css';
import { apiGet, apiPostUrlEncoded } from '../../helpers/Api';
import { alertShowValidationMessage, showAlert, showToast } from '../../helpers/Alert';
import { changePatronEmail } from '../../redux/actions/patron-action';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      privacySetting: false,
      privacyLoading: true
    };
    this.passwordChangeReq = {
      ptrnOldPassword: '',
      ptrnNewPassword: '',
      ptrnConfirmPassword: ''
    };
    this.refPrivacySwitch = React.createRef();
  }

  componentDidMount(): void {
    this.apiGetPrivacySetting();
  }

  handlePrivacySwitchClick = () => {
    const { privacyLoading, privacySetting } = this.state;
    if (privacyLoading) return;
    this.setState(
      {
        privacyLoading: true
      },
      () => {
        apiPostUrlEncoded('patron/privacy-setting.php', { isAnonymous: privacySetting })
          .then(() => this.setState({ privacyLoading: false, privacySetting: !privacySetting }))
          .catch(() => {
            this.setState({ privacyLoading: false });
            showAlert({ type: 'error', title: 'Failed to change setting' });
          });
      }
    );
  };

  apiGetPrivacySetting = () => {
    this.setState({
      privacyLoading: true
    });
    apiGet('patron/privacy-setting.php')
      .then(res => {
        this.setState({
          privacyLoading: false,
          privacySetting: !res.data.ptrnAnonymous
        });
      })
      .catch(() => {
        this.setState({
          privacyLoading: false
        });
        showAlert({ type: 'error', title: 'Failed to fetch current privacy setting' });
      });
  };

  handleEmailClick = () => {
    const { changePatronEmail } = this.props;
    showAlert(
      {
        title: 'Change email',
        input: 'email',
        inputPlaceholder: 'Enter new email',
        customClass: {
          input: 'input'
        },
        showCancelButton: true,
        inputValidator: value => {
          return changePatronEmail(value)
            .then(() => Promise.resolve())
            .catch(err => {
              return err;
            });
        }
      },
      true
    );
  };

  handlePasswordChangeClick = () => {
    showAlert(
      {
        title: this.passwordChangeView(),
        focusConfirm: false,
        showCancelButton: true,
        customClass: {
          title: 'swal2-custom-title'
        },
        onAfterClose: () => {
          this.passwordChangeReq = { ptrnOldPassword: '', ptrnNewPassword: '', ptrnConfirmPassword: '' };
        },
        allowEnterKey: true,
        preConfirm: () => {
          const { ptrnOldPassword, ptrnNewPassword, ptrnConfirmPassword } = this.passwordChangeReq;
          if (ptrnOldPassword === '' || ptrnNewPassword === '' || ptrnConfirmPassword === '') {
            alertShowValidationMessage('All fields are required');
            return false;
          }
          apiPostUrlEncoded('patron/password-reset.php', this.passwordChangeReq)
            .then(() => {
              showToast({ type: 'success', title: 'Password changed successfully' });
              return true;
            })
            .catch(err => {
              if (err.response.status === 401) {
                alertShowValidationMessage('Invalid password');
              } else if (err.response.status === 400) {
                alertShowValidationMessage("Passwords don't match");
              } else if (err.response.status === 409)
                alertShowValidationMessage('Please use a password different from the current one');
              else alertShowValidationMessage('Failed to change password');
              return false;
            });
          return false;
        }
      },
      true
    );
  };

  passwordChangeView = () => {
    const onInputChange = (e, index) => {
      const { value } = e.target;
      this.passwordChangeReq[index] = value;
      console.log(this.passwordChangeReq);
    };
    return (
      <form className='has-text-left'>
        <div className='title is-size-4 has-text-centered'>Change Password</div>
        <div className='field'>
          <label className='label'>Current password:</label>
          <input
            type='password'
            className='input'
            placeholder='Enter current password...'
            defaultValue={this.passwordChangeReq.ptrnOldPassword}
            onChange={e => onInputChange(e, 'ptrnOldPassword')}
          />
        </div>
        <div className='field'>
          <label className='label'>New password:</label>
          <input
            type='password'
            className='input'
            placeholder='Enter new password...'
            defaultValue={this.passwordChangeReq.ptrnNewPassword}
            onChange={e => onInputChange(e, 'ptrnNewPassword')}
          />
        </div>
        <div className='field'>
          <label className='label'>Confirm password:</label>
          <input
            type='password'
            className='input'
            placeholder='Retype new password...'
            defaultValue={this.passwordChangeReq.ptrnConfirmPassword}
            onChange={e => onInputChange(e, 'ptrnConfirmPassword')}
          />
        </div>
      </form>
    );
  };
  render() {
    const { privacyLoading, privacySetting } = this.state;
    const { patron } = this.props;
    return (
      <div className='comp-container comp-account'>
        <div className='header-container has-text-centered'>
          <div className='title is-size-3'>Welcome, {patron.ptrnFirstname}!</div>
          <div className='subtitle is-size-5'>Account settings</div>
        </div>
        <div className='content-container list'>
          <div className='list is-radiusless'>
            <ListItemAcc header={'IDs'}>
              <div className='list-item inner-list is-flex'>
                <div className='center-column' style={{ marginRight: '1rem' }}>
                  <i className='fas fa-university is-size-4 has-text-grey-dark' />
                </div>
                <div>
                  <div className='heading is-size-7'>Org</div>
                  <em>{patron.orgId}</em>
                </div>
              </div>
              <div className='list-item inner-list is-flex'>
                <div className='center-column' style={{ marginRight: '1rem' }}>
                  <i className='fas fa-user is-size-4 has-text-grey-dark' />
                </div>
                <div>
                  <div className='heading is-size-7'>Patron</div>
                  <em>{patron.ptrnId}</em>
                </div>
              </div>
            </ListItemAcc>
            <ListItemAcc header={'Name'}>
              <div className='list-item inner-list'>
                {patron.ptrnFirstname} {patron.ptrnLastname}
              </div>
            </ListItemAcc>
            <ListItemAcc header={'Sensitive info'}>
              <div className='list-item inner-list hover-pointer' onClick={this.handleEmailClick}>
                <div className='level is-mobile'>
                  <div className='level-left'>
                    <div className='level-item'>
                      <div>
                        <div className='heading is-size-7'>E-mail</div>
                        <em>{patron.ptrnEmail}</em>
                      </div>
                    </div>
                  </div>
                  <div className='level-right'>
                    <div className='level-item'>
                      <div>
                        <i className='fas fa-edit has-text-grey-dark' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='list-item inner-list hover-pointer' onClick={this.handlePasswordChangeClick}>
                Change password
              </div>
            </ListItemAcc>
            <ListItemAcc header={'Privacy'}>
              <div
                className='list-item inner-list hover-pointer is-flex'
                style={{ justifyContent: 'space-between', alignContents: 'stretch' }}
                onClick={this.handlePrivacySwitchClick}>
                <div>
                  {privacyLoading ? <div className='ld ld-ring ld-spin' style={{ marginRight: '0.5rem' }} /> : null}
                  Allow other patrons to see my name against items checked out by me.
                </div>
                <div className='center-column'>
                  <div className='is-unfocusable'>
                    <input
                      id={'acc-switch-privacy'}
                      type='checkbox'
                      className='switch is-rounded'
                      ref={this.refPrivacySwitch}
                      checked={privacySetting}
                    />
                    <label htmlFor={'acc-switch-privacy'} />
                  </div>
                </div>
              </div>
            </ListItemAcc>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});

Account.propTypes = {
  patron: PropTypes.object.isRequired,
  changePatronEmail: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  { changePatronEmail }
)(Account);
