export const firstPath = window.location.pathname.split('/')[1];
const rootPath = firstPath === 'blast-test' ? '/blast-test/' : '/';
console.log('Root path', firstPath, window.location.pathname.split('/'), rootPath);
export const ROUTES = {
  index: '/',
  registration: '/registration',
  forgotPass: '/forgot-password',
  contactUs: '/contact-us',
  logout: '/logout',
  checkout: '/checkout',
  browse: '/browse',
  history: '/history',
  account: '/account',
  itemDetails: '/item-details',
  admin: {
    checkin: '/admin/checkin',
    dashboard: '/admin/dashboard',
    orgDetails: '/admin/org-details',
    addItem: '/admin/add-item',
    viewItems: '/admin/view-items',
    addPatron: '/admin/add-patron',
    viewPatrons: '/admin/view-patrons',
    assignTag: '/admin/assign-tag',
    importPatrons: '/admin/import-patrons',
    allTransactions: '/all-transactions',
    partners: '/admin/partners',
    lendingVA: '/admin/lending',
    borrowingVA: '/admin/borrowing',
    lendHistory: '/admin/lend-history',
    lend: '/admin/lend',
    borrowing: '/admin/borrow-view',
    borrowAdd: '/admin/borrow-add',
    more: '/admin/ill'
  }
};

export const ADMIN_COMPS = [
  // { path: ROUTES.admin.checkin, title: 'Checkin' },
  // { path: ROUTES.admin.dashboard, title: 'Dashboard' },
  // {
  //   path: null,
  //   title: 'Items',
  //   subCategories: [
  //     { path: ROUTES.admin.addItem, title: 'Add' },
  //     { path: ROUTES.admin.viewItems, title: 'View' }
  //   ]
  // },
  // {
  //   path: null,
  //   title: 'Patrons',
  //   subCategories: [
  //     { path: ROUTES.admin.addPatron, title: 'Add' },
  //     { path: ROUTES.admin.viewPatrons, title: 'View' }
  //   ]
  // },
  // { path: ROUTES.admin.assignTag, title: 'Assign tag' },
  // // { path: ROUTES.admin.importPatrons, title: 'Import patrons' },
  { path: ROUTES.admin.partners, title: 'View partner organizations' },
  { path: ROUTES.admin.lend, title: 'Lend items' },
  { path: ROUTES.admin.lendHistory, title: 'View lent items' },
  { path: ROUTES.admin.borrowing, title: 'View / Add Loaned items' }
  // {
  //   path: null,
  //   title: 'Inter-Library Loans (ILL)',
  //   subCategories: [
  //     { path: ROUTES.admin.partners, title: 'View partner organizations' },
  //     { path: ROUTES.admin.lend, title: 'Lend items' },
  //     { path: ROUTES.admin.lendHistory, title: 'View items lent to partners' },
  //     { path: ROUTES.admin.borrowing, title: 'Loan' }
  //   ]
  // }
];
