import React from 'react';
import PropTypes from 'prop-types';
import { TransactionDetail } from '../../../models/history.res';

import './history-detail-list-item.styles.scss';
import getLocalDate from '../../../helpers/get-local-date';
import { apiPostUrlEncoded } from '../../../helpers/Api';
import { showAlert, showToast } from '../../../helpers/Alert';
import { Link, withRouter } from 'react-router-dom';
import { ROUTES } from '../../../constants';

opaque type HistoryDetailItemProp = {
  trnsDetail: TransactionDetail
};
const HistoryDetailListItem = (props: HistoryDetailItemProp) => {
  const { trnsDetail, apiGetHistory, history } = props;

  const apiRenew = () => {
    apiPostUrlEncoded('/transaction/v2/renew.php', { trdeId: trnsDetail.trdeId })
      .then(() => {
        showToast({
          title: 'Item renewed',
          type: 'success'
        });
        apiGetHistory();
      })
      .catch(() => {
        showAlert({
          type: 'error',
          title: 'Failed to renew'
        });
      });
  };

  const handleOnLinkClick = e => {
    if (e.target.matches('.button')) return;
    history.push(`${ROUTES.itemDetails}/${trnsDetail.itemId}/${trnsDetail.itdeId}`);
  };

  return (
    <div
      onClick={handleOnLinkClick}
      className='list-item history-detail-list-item has-background-white-bis'>
      <div className='left-part'>
        {trnsDetail.trdeReturned != '0000-00-00 00:00:00' ? (
          <div className='returned has-text-success'>
            RETURNED: <em>{getLocalDate(trnsDetail.trdeReturned, false)}</em>
          </div>
        ) : (
          <div className='dueback has-text-info'>
            DUE BACK: <em>{getLocalDate(trnsDetail.trdeDueback, false)}</em>
          </div>
        )}
        <span className='title is-size-6'>{trnsDetail.itemLbpTitle}</span>
      </div>
      {trnsDetail.isDiscontinued ? <div className='right-part has-text-danger'>Discontinued</div> : null}
      {trnsDetail.isRenewable ? (
        <div className='right-part'>
          <div className='button is-info' onClick={apiRenew}>
            Renew
          </div>
        </div>
      ) : null}
    </div>
  );
};

HistoryDetailListItem.defaultProps = {
  apiGetHistory: () => {}
};

HistoryDetailListItem.propTypes = {
  trnsDetail: PropTypes.objectOf(TransactionDetail),
  apiGetHistory: PropTypes.func
};

export default withRouter(HistoryDetailListItem);
