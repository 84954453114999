import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Datepicker from '../datepicker/datepicker.component';
import Dropdown from '../dropdown/dropdown.comp';
import { DropdownItem } from '../dropdown/dropdown-item.model';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const HistoryOptions = ({ startDate, endDate, filterBy, onChange, isLendHistory }) => {
  // create a copy of the initial values so that it can used later for storing new values
  let mStartDate = startDate;
  let mEndDate = endDate;
  let mFilterBy = filterBy;

  const dropdownItems = [
    new DropdownItem(0, 'All items', true),
    new DropdownItem(1, isLendHistory ? 'Currently lent out' : 'Checked out items'),
    new DropdownItem(2, 'Returned items')
  ];

  useEffect(() => {
    dropdownItems[filterBy].isActive = true;
  }, []);

  const valueType = {
    startDate: 0,
    endDate: 1,
    filterBy: 2
  };

  // handle Input change
  const handleOnChange = (value, type) => {
    switch (type) {
      case valueType.startDate:
        mStartDate = value;
        break;
      case valueType.endDate:
        mEndDate = value;
        break;
      default:
        mFilterBy = value;
    }
    // execute onChange only if new value is different
    if (startDate !== mStartDate || endDate !== mEndDate || filterBy !== mFilterBy) {
      if (onChange) onChange(mStartDate, mEndDate, mFilterBy);
    }
  };

  const handleOnDropdownSelect = (item: DropdownItem) => {
    handleOnChange(item.id, valueType.filterBy);
  };

  return (
    <div className='date-range-picker field is-grouped is-grouped-centered'>
      <div className='field is-grouped'>
        <div className='control'>
          <Datepicker
            label='From'
            options={{ maxDate: mEndDate || 'today', defaultDate: mStartDate }}
            handleChange={date => handleOnChange(date, valueType.startDate)}
          />
        </div>
        <div className='control'>
          <Datepicker
            label='To'
            options={{ minDate: mStartDate, maxDate: 'today', defaultDate: mEndDate }}
            handleChange={date => handleOnChange(date, valueType.endDate)}
          />
        </div>
      </div>
      <div className='field is-grouped is-flex' style={{ alignItems: 'center', marginLeft: '2rem' }}>
        <Dropdown items={dropdownItems} onSelect={handleOnDropdownSelect} />
      </div>
    </div>
  );
};

HistoryOptions.defaultProps = {
  filterBy: 0,
  isLendHistory: false
};

HistoryOptions.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  filterBy: PropTypes.number,
  onChange: PropTypes.func
};

export default HistoryOptions;
