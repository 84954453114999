import {
  CHECKOUT,
  CHECKIN,
  ADD_CHECKIN_ITEM,
  ADD_CHECKOUT_ITEM,
  REMOVE_CHECKIN_ITEM,
  LOGOUT,
  REMOVE_CHECKOUT_ITEM
} from '../types';

class CheckReducerState {
  constructor() {
    this.checkoutList = [];
    this.checkinList = [];
  }
}

const initialState = new CheckReducerState();

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case CHECKOUT:
      return { ...state, checkoutList: [] };
    case ADD_CHECKOUT_ITEM:
      state.checkoutList.push(payload);
      return state;
    case CHECKIN:
      return { ...state, checkinList: [] };
    case ADD_CHECKIN_ITEM:
      state.checkinList.push(payload);
      return state;
    case REMOVE_CHECKIN_ITEM:
      return { ...state, checkinList: state.checkinList.filter(item => item.itdeId !== payload) };
    case REMOVE_CHECKOUT_ITEM:
      return { ...state, checkoutList: state.checkoutList.filter(item => item.itdeId !== payload) };
    case LOGOUT:
      return new CheckReducerState();
    default:
      return state;
  }
}
