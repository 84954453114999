import React, { Component } from 'react';
import { connect } from 'react-redux';
import './logged-in/style.css';
import Admin from './logged-in/admin';
import Section from './logged-in/sub-components/section';
import icoBrowse from '../../assets/ico_browse.png';
import icoCheckout from '../../assets/ico_checkout.png';
import icoHistory from '../../assets/ico_history.png';
import icoAcc from '../../assets/ico_acc.png';
import BoxLink from './logged-in/sub-components/box-link';
import { ROUTES } from '../../constants';

class LoggedIn extends Component {
  render() {
    return (
      <div className='container' style={{ paddingTop: '2rem', minHeight: '86vh', boxSizing: 'border-box' }}>
        {this.props.patron.ptrnIsAdmin ? <Admin /> : null}
        <Section label='Patron functions' iconClass='fa-book-reader'>
          <BoxLink image={icoCheckout} label='CHECK-OUT' link={ROUTES.checkout} />
          <BoxLink image={icoBrowse} label='BROWSE' link={ROUTES.browse} />
          <BoxLink image={icoHistory} label='HISTORY' link={ROUTES.history} />
          <BoxLink image={icoAcc} label='ACCOUNT' link={ROUTES.account} />
        </Section>
      </div>
    );
  }
}

LoggedIn.propTypes = {};

const mapStateToProps = state => ({
  patron: state.patronReducer.patron
});

export default connect(mapStateToProps)(LoggedIn);
