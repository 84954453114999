import React from 'react';
import { Link } from 'react-router-dom';

const BoxMultiLink = props => {
  return (
    <div className='column center'>
      <div className='box box-big'>
        <span className='box-label-top'>{props.label}</span>
        <Link className='button is-primary' to={props.viewLink}>
          <i className='fas fa-search' /> VIEW
        </Link>
        <Link className='button is-success' to={props.addLink}>
          <i className='fas fa-plus' /> ADD
        </Link>
      </div>
    </div>
  );
};

export default BoxMultiLink;
