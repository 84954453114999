import React from 'react';

import './style.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';

const sideNavbarUser = props => {
  const onSideNavSubMenuDropdownClick = event => {
    const menu = event.target.nextSibling;
    if (menu != null) menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
  };
  const isCurrent = pathname => {
    return pathname === props.pathname ? ' is-active' : '';
  };
  const adminSideNav = () => {
    return props.patron.ptrnIsAdmin ? (
      <React.Fragment>
        <p className='menu-label'>Admin</p>
        <ul className='menu-list'>
          <li>
            <Link className={`${isCurrent(ROUTES.admin.dashboard)}`} to={ROUTES.admin.dashboard}>
              Dashboard
            </Link>
          </li>
          <li>
            <a href='#' className='side-nav-dropdown has-text-justified' onClick={event => onSideNavSubMenuDropdownClick(event)}>
              Items <i className='fas fa-caret-down is-pulled-right' />
            </a>
            <ul className='menu-list' style={{ display: 'none' }}>
              <li>
                <Link className={`${isCurrent(ROUTES.admin.viewItems)}`} to={ROUTES.admin.viewItems}>
                  View items
                </Link>
              </li>
              <li>
                <Link onClick={window.location.reload} className={`${isCurrent(ROUTES.admin.addItem)}`} to={ROUTES.admin.addItem}>
                  Add items
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <a href='#' className='side-nav-dropdown' onClick={event => onSideNavSubMenuDropdownClick(event)}>
              Patrons <i className='fas fa-caret-down is-pulled-right' />
            </a>
            <ul className='menu-list' style={{ display: 'none' }}>
              <li>
                <Link className={`${isCurrent(ROUTES.admin.viewPatrons)}`} to={ROUTES.admin.viewPatrons}>
                  View patrons
                </Link>
              </li>
              <li>
                <Link className={`${isCurrent(ROUTES.admin.addPatron)}`} to={ROUTES.admin.addPatron}>
                  Register patrons
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link className={`${isCurrent(ROUTES.admin.allTransactions)}`} to={ROUTES.admin.allTransactions}>
              All transactions
            </Link>
          </li>
          <li>
            <Link className={`${isCurrent(ROUTES.admin.more)}`} to={ROUTES.admin.more}>
              ILL
            </Link>
          </li>
        </ul>
      </React.Fragment>
    ) : null;
  };
  return (
    <aside
      className={`side-navbar has-background-white-bis is-hidden-desktop${
        props.isSideNavbarVisible ? ' side-navbar-visible' : ' side-navbar-gone'
      }`}>
      <p className='menu-header side-nav-header'>
        <span className='title is-size-6'>{props.patron.ptrnFirstname + ' ' + props.patron.ptrnLastname}</span>
        <br />
        <span className='subtitle is-size-6'>{props.patron.ptrnEmail}</span>
      </p>
      <p className='menu-label'>General</p>
      <ul className='menu-list'>
        <li>
          <Link className={`${isCurrent(ROUTES.index)}`} to={ROUTES.index}>
            Home
          </Link>
        </li>
        <li>
          <Link className={`${isCurrent(ROUTES.checkout)}`} to={ROUTES.checkout}>
            Check-out
          </Link>
        </li>
        {props.patron.ptrnIsAdmin ? (
          <li>
            <Link className={`${isCurrent(ROUTES.admin.checkin)}`} to={ROUTES.admin.checkin}>
              Check-in
            </Link>
          </li>
        ) : null}
        <li>
          <Link className={`${isCurrent(ROUTES.browse)}`} to={ROUTES.browse}>
            Browse
          </Link>
        </li>
        <li>
          <Link className={`${isCurrent(ROUTES.history)}`} to={ROUTES.history}>
            History
          </Link>
        </li>
        {adminSideNav()}
        <p className='menu-label'>Account</p>
        <ul className='menu-list'>
          <li>
            <Link className={`${isCurrent(ROUTES.account)}`} to={ROUTES.account}>
              Account
            </Link>
          </li>
          <li>
            <Link className={`${isCurrent(ROUTES.logout)}`} to={ROUTES.logout}>
              Log out
            </Link>
          </li>
        </ul>
      </ul>
    </aside>
  );
};

export default sideNavbarUser;
