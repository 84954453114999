import { lendTypes, LOGOUT } from '../types';

class InitialState {
  constructor() {
    this.isLoading = false;
    this.lendList = [];
    this.orgList = {
      isLoading: false,
      list: []
    };
    this.error = null;
  }
}

let initialState = new InitialState();

const LendReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case lendTypes.LEND_FETCH_ORG_LIST_START:
      return { ...state, orgList: { ...state.orgList, isLoading: true } };
    case lendTypes.LEND_FETCH_ORG_LIST_SUCCESS:
      return { ...state, orgList: { ...state.orgList, list: payload, isLoading: false } };
    case lendTypes.LEND_FETCH_ORG_LIST_FAILURE:
      return { ...state, orgList: { ...state.orgList, isLoading: false } };
    case lendTypes.ADD_LEND_ITEM:
      return { ...state, lendList: [...state.lendList, payload] };
    case lendTypes.LEND_ITEM_REMOVE:
      return { ...state, lendList: state.lendList.filter(item => item.itdeBarcode !== payload) };
    case lendTypes.LEND_ITEM_START:
      return { ...state, isLoading: true };
    case lendTypes.LEND_ITEM_SUCCESS:
      return { ...state, lendList: [] };
    case lendTypes.LEND_ITEM_FAILURE:
      return { ...state, error: payload.error };
    case LOGOUT:
      return new InitialState();
    default:
      return state;
  }
};

export default LendReducer;
