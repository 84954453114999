// styles
import './borrow-add.style.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormInput from '../../_helpers/form-input/form-input.component';
import LendActions from '../../../redux/actions/lend.actions';
import SearchDropdown from '../../_helpers/search-dropdown/search-dropdown.component';
import PatronActions from '../../../redux/actions/patron.actions';
import { apiGet, apiPostJson } from '../../../helpers/Api';
import { showAlert, showToast } from '../../../helpers/Alert';
import Datepicker from '../../_helpers/datepicker/datepicker.component';
import Moment from 'moment';

const BorrowAdd = ({ onBack, getOrgDropdownList, getPatronDropdownList, borId }) => {
  const [inputValues, setInputValues] = useState({
    borId: borId,
    borDate: borId ? null : Moment().format('YYYY-MM-DD'),
    borReturned: null,
    borrowDays: '',
    borNumber: '',
    borItemTitle: '',
    borItemAuthor: '',
    borItemDescription: '',
    borItemPublisher: '',
    borItemIsbn: '',
    borItemBarcode: '',
    borItemCallNumber: ''
  });

  const [orgOptions, setOrgOptions] = useState([]);
  const [ptrnOptions, setPtrnOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPtrn, setSelectedPtrn] = useState(null);

  const resetInputs = () => {
    setInputValues({
      ...inputValues,
      borReturned: null,
      borrowDays: '',
      borNumber: '',
      borItemTitle: '',
      borItemAuthor: '',
      borItemDescription: '',
      borItemPublisher: '',
      borItemIsbn: '',
      borItemBarcode: '',
      borItemCallNumber: ''
    });
    setSelectedOption(null);
    setSelectedPtrn(null);
    window.scrollTo(0, 0);
  };

  const handleBorDateChange = date => {
    console.log(date);
    setInputValues(state => ({ ...state, borDate: date }));
  };

  const handleReturnDateChange = date => {
    setInputValues(state => ({ ...state, borReturned: date || null }));
  };

  const getDueBackDate = () => {
    const { borDate, borrowDays } = inputValues;
    if (!borrowDays) return '';
    if (borrowDays > 9999 || borrowDays < 1) return '(Bad date!)';
    const dueBackDate = Moment(borDate)
      .add(borrowDays, 'days')
      .format('MMMM DD, YYYY');
    return `(${dueBackDate})`;
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!selectedOption) {
      showAlert({ type: 'warning', title: 'Please select the organization' });
      return;
    }
    const res = {
      ...inputValues,
      borDueBack: Moment(inputValues.borDate)
        .add(inputValues.borrowDays, 'days')
        .format('YYYY-MM-DD'),
      ooiId: selectedOption.value,
      ptrnId: selectedPtrn ? selectedPtrn.value : null
    };
    apiPostJson(`/admin/borrow/${borId ? 'update' : 'add'}-borrow.php`, res)
      .then(() => {
        if (onBack) onBack();
        else resetInputs();
        showAlert({ type: 'success', title: borId ? 'Item updated' : 'Item added to borrowed items' });
      })
      .catch(() => showAlert({ type: 'error', title: borId ? 'Update failed' : 'Failed to borrowed item' }));
  };

  // on mount
  useEffect(() => {
    window.scrollTo(0, 0);
    if (orgOptions.length === 0) {
      getOrgDropdownList().then(data => {
        setOrgOptions(data);
      });
    }
    if (ptrnOptions.length === 0) {
      getPatronDropdownList().then(data => setPtrnOptions(data));
    }
    if (borId) {
      apiFetchItemDetails();
    }
  }, []);

  const apiFetchItemDetails = () => {
    apiGet('/admin/borrow/get-borrow-details.php', { borId })
      .then(res => {
        const { data } = res;
        const borDate = Moment(data.borDate);
        // const dueBackDate = Moment(data.borDueBack).format('YYYY-MM-DD');
        const borReturned = data.borReturned ? Moment(data.borReturned).format('YYYY-MM-DD') : null;
        setInputValues({
          ...inputValues,
          borDate: data.borDate,
          borReturned: borReturned,
          borrowDays: Moment(data.borDueBack).diff(borDate, 'days'),
          borNumber: data.borNumber,
          borItemTitle: data.borItemTitle,
          borItemAuthor: data.borItemAuthor,
          borItemDescription: data.borItemDescription,
          borItemPublisher: data.borItemPublisher,
          borItemIsbn: data.borItemIsbn,
          borItemBarcode: data.borItemBarcode,
          borItemCallNumber: data.borItemCallNumber
        });
        setSelectedOption({ value: data.ooiId });
        setSelectedPtrn({ value: data.ptrnId });
      })
      .catch(() => showAlert({ type: 'error', title: 'Failed to fetch details' }));
  };

  useEffect(() => console.log('Input values', inputValues), [inputValues]);

  const handleOnInputChange = e => {
    const key = e.target.getAttribute('name');
    setInputValues({ ...inputValues, [key]: e.target.value });
  };
  return (
    <form className='borrow-add-comp comp-container add-comp box has-background-white-bis' onSubmit={handleFormSubmit}>
      <div className='title is-size-4 has-text-centered'>{borId ? 'Update' : 'Add'} borrowed item</div>
      <div className='field'>
        <label className='label'>* Select organization</label>
        <SearchDropdown
          label='*Organization'
          placeholder='Select the organization you want to borrow from'
          value={selectedOption}
          options={orgOptions}
          onSelect={setSelectedOption}
          fluid
        />
      </div>
      <div className='field'>
        <label className='label'>Select patron (optional)</label>
        <SearchDropdown
          label='Patron'
          placeholder='Select a patron to assign this item to'
          value={selectedPtrn}
          options={ptrnOptions}
          onSelect={setSelectedPtrn}
          fluid
          hasClear
        />
      </div>
      {inputValues.borDate ? (
        <Datepicker
          label='* Loaned on'
          options={{ maxDate: 'today', defaultDate: inputValues.borDate }}
          handleChange={handleBorDateChange}
          isClearable={false}
        />
      ) : null}
      <FormInput
        type='number'
        label={`* Number of days ${getDueBackDate()}`}
        onChange={handleOnInputChange}
        value={inputValues.borrowDays}
        name={'borrowDays'}
        placeholder='Enter the number of days you want to loan this item for'
        min={1}
        max={9999}
        length={4}
        required
      />
      {borId && inputValues.borDate ? (
        <Datepicker
          label='Returned on'
          options={{ maxDate: 'today', defaultDate: inputValues.borReturned, minDate: inputValues.borDate }}
          handleChange={handleReturnDateChange}
        />
      ) : null}
      <FormInput
        label='File number'
        onChange={handleOnInputChange}
        value={inputValues.borNumber}
        name={'borNumber'}
        placeholder='Enter file number'
      />
      <FormInput
        label='* Title'
        onChange={handleOnInputChange}
        value={inputValues.borItemTitle}
        name={'borItemTitle'}
        placeholder='Enter title'
        required
      />
      <FormInput
        label='Author'
        onChange={handleOnInputChange}
        value={inputValues.borItemAuthor}
        name={'borItemAuthor'}
        placeholder='Enter author'
      />
      <FormInput
        label='Description'
        type='textarea'
        onChange={handleOnInputChange}
        value={inputValues.borItemDescription}
        name={'borItemDescription'}
        placeholder='Enter description'
      />
      <FormInput
        label='Publisher'
        onChange={handleOnInputChange}
        value={inputValues.borItemPublisher}
        name={'borItemPublisher'}
        placeholder='Enter publisher'
      />
      <FormInput
        label='ISBN'
        onChange={handleOnInputChange}
        value={inputValues.borItemIsbn}
        name={'borItemIsbn'}
        placeholder='Enter isbn'
      />
      <FormInput
        label='Barcode'
        onChange={handleOnInputChange}
        value={inputValues.borItemBarcode}
        name={'borItemBarcode'}
        placeholder='Enter barcode'
      />
      <FormInput
        label='Call number'
        onChange={handleOnInputChange}
        value={inputValues.borItemCallNumber}
        name={'borItemCallNumber'}
        placeholder='Enter call number'
      />
      <div className='button-footer'>
        {onBack ? (
          <button style={{ marginRight: '1rem' }} className='button is-danger is-outlined' type='button' onClick={onBack}>
            Cancel
          </button>
        ) : null}
        <button className='button is-primary'>{borId ? 'Update' : 'Add'}</button>
      </div>
    </form>
  );
};

BorrowAdd.propTypes = {};

const mapDispatchToProps = {
  getOrgDropdownList: LendActions.getOrgDropdownList,
  getPatronDropdownList: PatronActions.getPatronDropdownList
};

export default connect(null, mapDispatchToProps)(BorrowAdd);
