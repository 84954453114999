// @flow
import React, { useState, useEffect } from 'react';
import GridItem from '../grid-item';
import { Bar } from 'react-chartjs-2';
import Moment from 'moment';
import { apiGet } from '../../../../helpers/Api';
import { showAlert } from '../../../../helpers/Alert';
import Loader from '../../../../layout/loader/loader';
import './grid-item-chart.styles.scss';

type ChartData = {
  [string]: {
    checkouts: number,
    itemsCheckedOut: number,
    itemsCheckedIn: number,
    itemsLent: number,
    lendsReturned: number
  }
};

const GridItemChart = () => {
  const [chartData: ChartData, setChartData] = useState(null);
  const [parsedChartData, setParsedChartData] = useState(null);
  const [endDateMoment, setEndDateMoment] = useState(Moment().format('YYYY-MM-DD'));

  useEffect(() => {
    apiGetChartData();
  }, [endDateMoment]);

  useEffect(() => {
    if (chartData != null) {
      let labels = [];
      let checkouts = [];
      let itemsCheckedOut = [];
      let itemsCheckedIn = [];
      let itemsLent = [];
      let lendsReturned = [];
      for (const date of Object.keys(chartData)) {
        labels.push(Moment(date, 'YYYY-MM-DD').format('MMM DD, YY'));
        checkouts.push(chartData[date].checkouts);
        itemsCheckedOut.push(chartData[date].itemsCheckedOut);
        itemsCheckedIn.push(chartData[date].itemsCheckedIn);
        itemsLent.push(chartData[date].itemsLent);
        lendsReturned.push(chartData[date].lendsReturned);
      }
      const data = {
        labels,
        datasets: [
          {
            label: 'Transactions',
            backgroundColor: '#00ACC1',
            borderColor: '#00ACC1',
            data: checkouts
          },
          {
            label: 'Items checked out',
            backgroundColor: '#F4511E',
            borderColor: '#F4511E',
            data: itemsCheckedOut
          },
          {
            label: 'Items checked in',
            backgroundColor: '#43A047',
            borderColor: '#43A047',
            data: itemsCheckedIn
          },
          {
            label: 'Lends',
            backgroundColor: '#f4da1d',
            borderColor: '#f4da1d',
            data: itemsLent
          },
          {
            label: 'Lends returned',
            backgroundColor: '#5c47a0',
            borderColor: '#5c47a0',
            data: lendsReturned
          }
        ]
      };
      setParsedChartData(data);
    }
  }, [chartData]);

  const apiGetChartData = () => {
    const moment = Moment(endDateMoment, 'YYYY-MM-DD');
    const req = {
      endDate: moment.format('YYYY-MM-DD'),
      startDate: moment.subtract(15, 'days').format('YYYY-MM-DD')
    };
    apiGet('admin/dashboard/chart-data.php', req)
      .then(res => {
        setChartData(res.data);
      })
      .catch(() => {
        showAlert({ type: 'error', title: 'Failed to fetch chart data' });
      });
  };

  const handleArrowClick = direction => {
    const moment = Moment(endDateMoment, 'YYYY-MM-DD');
    if (direction === 0) {
      setEndDateMoment(moment.subtract(15, 'days').format('YYYY-MM-DD'));
    } else {
      setEndDateMoment(moment.add(15, 'days').format('YYYY-MM-DD'));
    }
  };

  return (
    <GridItem header={'Chart'} color='is-blue-grey' isChart={true}>
      {parsedChartData ? (
        <div className='chart-container'>
          <button className='chart-btn btn-left' onClick={() => handleArrowClick(0)}>
            <i className='fas fa-caret-left' />
          </button>
          <div className='the-chart'>
            <Bar data={parsedChartData} options={{ maintainAspectRatio: false }} />
          </div>
          <button className='chart-btn btn-right' onClick={() => handleArrowClick(1)}>
            <i className='fas fa-caret-right' />
          </button>
        </div>
      ) : (
        <Loader title='Loading chart' active />
      )}
    </GridItem>
  );
};

export default GridItemChart;
