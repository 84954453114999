import { BROWSE_SET_REQ, BROWSE_SET_RES, BROWSE_SHOULD_REFRESH } from '../types';
import { apiPostJson } from '../../helpers/Api';
import { GetItemsReq } from '../../models/GetItems';

export const getItems = (reqBody = null) => (dispatch, getState) => {
  const { browseReducer } = getState();
  console.log('ReqBody', reqBody);
  // const req = reqBody !== null ? browseReducer.getItemsReq : { ...browseReducer.getItemsReq, ...reqBody };
  let backupReq = JSON.parse(JSON.stringify(browseReducer.getItemsReq));

  if (reqBody !== null) {
    dispatch({
      type: BROWSE_SET_REQ,
      payload: reqBody
    });
  }
  return apiPostJson('/item/get-items.php', { ...browseReducer.getItemsReq, ...reqBody })
    .then(res => {
      dispatch({
        type: BROWSE_SET_RES,
        payload: res.data
      });
      dispatch({
        type: BROWSE_SHOULD_REFRESH,
        payload: false
      });
      return Promise.resolve();
    })
    .catch(err => {
      dispatch({
        type: BROWSE_SET_REQ,
        payload: backupReq
      });
      return Promise.reject();
    });
};

export const setBrowseShouldRefresh = shouldRefresh => dispatch => {
  dispatch({
    type: BROWSE_SHOULD_REFRESH,
    payload: shouldRefresh
  });
};
