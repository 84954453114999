import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';

// styles
import './flatpickr.scss';

const Datepicker = props => {
  let { label, isClearable } = props;
  let { options } = props;
  const { handleChange } = props;

  // if (!options) options = {};
  const allOptions = { ...options, altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' };
  if (!label) label = 'Select date';
  // date input
  let refInput = null;

  let date = allOptions.defaultDate || '';
  const [isClearVisible, setClearVisible] = useState(date !== '');
  
  // date value
  // const [date, setDate] = useState(allOptions.defaultDate);

  // on date change
  // useEffect(() => {
  //   // if (!date) refInput.flatpickr.clear();
  //   console.log('Date', date);
  //   if (handleChange) handleChange(date);
  // });

  // handle date input change
  const handleOnInputChange = (selectedDates, dateStr, instance) => {
    date = dateStr;
    setClearVisible(date !== '');
    if (handleChange) handleChange(dateStr);
  };

  // on clear
  const onClear = () => {
    // setDate('');
    refInput.flatpickr.clear();
    date = '';
  };

  return (
    <div className='field'>
      <label className='label'>{label}</label>
      <div className='datepicker field has-addons'>
        <div className='control'>
          <Flatpickr
            options={allOptions}
            className='input'
            placeholder={label}
            ref={input => (refInput = input)}
            onChange={(selectedDates, dateStr, instance) => handleOnInputChange(selectedDates, dateStr, instance)}
          />
        </div>
        {isClearVisible && isClearable ? (
          <div className='control'>
            <button className='button is-outlined' onClick={onClear}>
              <i className='fas fa-times has-text-red' />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Datepicker.defaultProps = {
  options: {},
  isClearable: true
};

Datepicker.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  isClearable: PropTypes.bool,
  handleChange: PropTypes.func
};

export default Datepicker;
