export const LOGIN = 'login:patron';
export const LOGOUT = 'logout:patron';
export const PATRON_CHANGE_EMAIL = 'patron:changeEmail';
export const VERIFY = 'verify:patron';
export const CHECKOUT = 'trans:checkOut';
export const ADD_CHECKOUT_ITEM = 'trans:addCheckout';
export const CHECKIN = 'trans:checkIn';
export const ADD_CHECKIN_ITEM = 'trans:addCheckin';
export const REMOVE_CHECKIN_ITEM = 'trans:removeCheckin';
export const REMOVE_CHECKOUT_ITEM = 'trans:removeCheckout';
export const BROWSE_SET_REQ = 'browse:setReq';
export const BROWSE_SET_RES = 'browse:setRes';
export const BROWSE_SHOULD_REFRESH = 'browse:shouldRefresh';
export const GET_HISTORY = 'history';
export const HISTORY_CURRENT = 0;
export const HISTORY_THIRTY = 30;
export const HISTORY_THIRTY_OLDER = 60;
export const HISTORY_SIXTY_OLDER = 61;
export const SET_REFRESHING_TOKEN = 'api:setRefreshingToken';
export const lendTypes = {
  LEND_ITEM_START: 'lend_item_start',
  LEND_ITEM_SUCCESS: 'lend_item_success',
  LEND_ITEM_FAILURE: 'lend_item_failure',
  LEND_ITEM_REMOVE: 'lend_item_remove',
  LEND_FETCH_ORG_LIST_START: 'lend_fetch_org_list_start',
  LEND_FETCH_ORG_LIST_SUCCESS: 'lend_fetch_org_list_success',
  LEND_FETCH_ORG_LIST_FAILURE: 'lend_fetch_org_list_failure',
  ADD_LEND_ITEM: 'add_lend_item'
};

export const postScanType = {
  POST_SCAN_START: 'postScanType:postScanStart',
  POST_SCAN_SUCCESS: 'postScantype:postScanSuccess',
  POST_SCAN_FAILURE: 'postScanType:postScanFailure'
};

export const partnerTypes = {
  GET_PARTNER_DETAILS: 'partnerTypes:getPartnerDetails'
};
