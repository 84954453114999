import React, { Component } from 'react';
import logo from '../../assets/beacontree_logo_full.png';
import { Link } from 'react-router-dom';

// import NavBarPatron from '../navbar/NavBarPatron';
import NavbarMain from './Navbar/NavbarMain';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NavbarUser from './Navbar/NavbarUser';

// import { Consumer } from '../Context';

export class Header extends Component {
  render() {
    const { patron, history } = this.props;
    const getJSX = () => {
      if (patron === null) {
        if (history.location.pathname === '/') return null;
        else return <NavbarMain />;
      } else
        return (
          <NavbarUser
            pathname={history.location.pathname}
            patron={patron}
            onNavbarBurgerClick={this.props.onNavbarBurgerClick}
            isSideNavbarVisible={this.props.isSideNavbarVisible}
          />
        );
    };
    return getJSX();
  }

  propTypes = {
    patron: PropTypes.object.isRequired
  };
}

export default withRouter(Header);
