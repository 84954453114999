import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../../_helpers/form-input/form-input.component';
import { apiPostJson } from '../../../helpers/Api';
import { showAlert } from '../../../helpers/Alert';

const ViewPartnerEdit = ({ fieldMap, partner, handleOnCancel, onSubmit }) => {
  const tempMap = [{ key: 'ooiName', name: 'Name' }, ...fieldMap];
  const [statePartner, setStatePartner] = useState({ ...partner });

  const handleInputOnChange = e => {
    const keyName = e.target.getAttribute('name');
    const { value } = e.target;
    setStatePartner({ ...statePartner, [keyName]: value });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    apiPostJson('/admin/partner/update-partner-org.php', statePartner)
      .then(() => {
        onSubmit();
        handleOnCancel();
      })
      .catch(err => {
        showAlert({ type: 'error', title: 'Failed to update partner' });
      });
  };

  const handleOnDelete = () => {
    console.log('deleted');
  };

  return (
    <React.Fragment>
      <div className='comp-header title is-size-3'>Edit Partner Org</div>
      <div className='content-container box has-background-white-bis'>
        <form className='form' onSubmit={handleOnSubmit}>
          {tempMap.map(item => {
            const type = item.key === 'ooiDesc' ? 'textarea' : 'input';
            return (
              <FormInput
                label={item.name}
                onChange={handleInputOnChange}
                value={statePartner[item.key]}
                name={item.key}
                placeholder={`Enter ${item.name}`}
                type={type}
                required={item.key === 'ooiName'}
              />
            );
          })}

          <div className='button-container is-flex'>
            <div className='left'>
              <button type='button' className='button is-primary is-outlined' onClick={handleOnCancel}>
                Cancel
              </button>
            </div>
            <div className='right'>
              <button type='button' className='button is-danger' style={{ marginRight: '1rem' }} onClick={() => {}}>
                Delete
              </button>
              <button type='submit' className='button is-primary'>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

ViewPartnerEdit.propTypes = {
  fieldMap: PropTypes.array.isRequired
};

export default ViewPartnerEdit;
