import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './search-dropdown.styles.scss';

const SearchDropdown = ({ label, placeholder, value, onSelect, options, fluid, hasClear }) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const refSearchInput = useRef(null);

  const handleOnDropdownBtnClick = e => {
    if (e.target.matches('.search-dropdown-input')) return;
    setIsDropdownActive(!isDropdownActive);
  };

  const handleOnOptionSelect = option => {
    onSelect(option);
  };

  const handleOnSearchInputChange = e => {
    setSearchInputValue(e.target.value);
  };
  
  const selectedOption = value ? options.find(option => option.value === value.value) : null;

  const getOptions = () => {
    const filteredOptions = options.filter(option => option.text.toLowerCase().includes(searchInputValue.toLowerCase().trim()));
    return filteredOptions.map((option, index) => (
      <a
        key={index}
        className={`dropdown-item ${value && option.value === value.value ? 'is-active' : ''}`}
        onClick={() => handleOnOptionSelect(option)}>
        {option.text}
      </a>
    ));
  };

  useEffect(() => {
    if (isDropdownActive && refSearchInput) {
      refSearchInput.current.focus();
    }
  }, [isDropdownActive]);

  return (
    <div className='field is-flex'>
      <div
        className={`search-dropdown dropdown ${isDropdownActive ? 'is-active' : ''} ${fluid ? 'is-fluid' : ''}`}
        onClick={handleOnDropdownBtnClick}>
        <div className={`dropdown-trigger ${fluid ? 'is-fluid' : ''}`}>
          <button className='button' type='button' aria-haspopup='true' aria-controls='dropdown-menu'>
            <span>
              {selectedOption ? selectedOption.text : placeholder}
            </span>
            <span className='icon is-small'>
              <i className={`fas fa-angle-${isDropdownActive ? 'up' : 'down'}`} />
            </span>
          </button>
        </div>
        <div className='dropdown-menu'>
          <div className='dropdown-content'>
            <div className='input-field'>
              <input
                value={searchInputValue}
                onChange={handleOnSearchInputChange}
                ref={refSearchInput}
                type='text'
                className='input search-dropdown-input'
                placeholder='Search...'
                autoFocus
              />
            </div>
            {getOptions()}
          </div>
        </div>
      </div>
      {!!value && hasClear ? (
        <button type='button' className='button is-outlined' style={{ marginLeft: '0.5rem' }} onClick={() => onSelect(null)}>
          Clear
        </button>
      ) : null}
    </div>
  );
};

SearchDropdown.defaultProps = {
  options: [],
  placeholder: '',
  fluid: false,
  hasClear: false
};

SearchDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  fluid: PropTypes.bool,
  hasClear: PropTypes.bool
};

export default SearchDropdown;
