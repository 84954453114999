import React from 'react';
import PropTypes from 'prop-types';

const FilterItem = props => {
  const { title, value, onFilterItemClick, isChecked } = props;
  return (
    <p className='field'>
      <input
        id={'filter-checkbox-' + value}
        type='checkbox'
        className={'is-checkradio is-block has-no-border' + (value === 'fav' ? ' is-accent' : ' is-info')}
        value={value}
        onClick={e => onFilterItemClick(e)}
        checked={isChecked}
      />
      <label htmlFor={'filter-checkbox-' + value} className='checkbox' style={{ userSelect: 'none' }}>
        {title}
      </label>
    </p>
  );
};

const FilterContainer = props => {
  const { itemCategories, onFilterItemClick, selectedCategories, favValue } = props;
  return (
    <div className='filter-container field is-grouped is-grouped-centered is-grouped-multiline'>
      <FilterItem title={'Favourites'} value={'fav'} onFilterItemClick={onFilterItemClick} isChecked={favValue} />
      <span>| &nbsp;</span>
      {itemCategories.map(itemCategory => (
        <FilterItem
          key={itemCategory.ittyId}
          title={itemCategory.ittyName}
          value={itemCategory.ittyId}
          isChecked={selectedCategories.includes(itemCategory.ittyId)}
          onFilterItemClick={onFilterItemClick}
        />
      ))}
    </div>
  );
};

FilterItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired
};

FilterContainer.propTypes = {
  itemCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  favValue: PropTypes.bool.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default FilterContainer;
