import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../helpers/form-field';
import { ItemCopy } from '../../../models/AddItem';

const CopyItem = props => {
  const { itemCopy, onRemove, onChange, itemLocations, onDuplicate } = props;
  /*const handleChange = (event, key) => {
    const {
      target: { value }
    } = event;
    itemCopy[key] = value;
  };*/
  const handleChange = (event, key) => {
    let tempEvent = event;
    if (key === 'itdeCopy' || key === 'itdeLendLimit' || key === 'itdeRenews' || key === 'itdeRenewPeriod') {
      if (isNaN(Number(event.target.value))) return;
      tempEvent = { target: { value: Math.abs(Number(event.target.value).toFixed(0)) } };
    }

    // if (key === 'itdeRenewPeriod') {
    //   if (event.target.value.trim() === '') tempEvent = { target: { value: 1 } };
    //   else if (isNaN(Number(event.target.value))) return;
    //   else {
    //     const convertedValue = Math.abs(Number(event.target.value).toFixed(0));
    //     tempEvent = { target: { value: convertedValue <= 0 ? 1 : convertedValue } };
    //   }
    // }
    const id = itemCopy.id ? itemCopy.id : itemCopy.itdeId;
    onChange(tempEvent, id, key);
  };

  const handleOnRenewPeriodBlur = ({ event }) => {
    let tempEvent = event;
    if (event.target.value <= 0) {
      tempEvent = { target: { value: 1 } };
    }
    const id = itemCopy.id ? itemCopy.id : itemCopy.itdeId;
    onChange(tempEvent, id, 'itdeRenewPeriod');
  };

  const handleDuplicate = () =>
    onDuplicate(new ItemCopy(0, '', itemCopy.itdeCallNumber, itemCopy.itdeIsbn, itemCopy.itloId, itemCopy.itdeNotes));

  return (
    <li className='list-item copy-item has-background-white-bis'>
      <div className='title is-size-5'>Copy {itemCopy.itdeCopy}</div>
      <div className='delete is-medium' onClick={() => onRemove(itemCopy.id ? itemCopy.id : itemCopy.itdeId)} />
      <div className='btn-duplicate-copy button is-link is-rounded is-small' onClick={handleDuplicate}>
        <i className='fas fa-clone' style={{ marginRight: '0.5rem' }} /> DUPLICATE
      </div>
      <FormField
        label='Copy'
        placeholder='Enter copy number...'
        indexKey='itdeCopy'
        onChange={handleChange}
        defaultValue={itemCopy.itdeCopy}
        attributes={{ maxlength: 4 }}
      />
      <FormField
        label='Barcode'
        placeholder='Enter barcode...'
        indexKey='itdeBarcode'
        onChange={handleChange}
        defaultValue={itemCopy.itdeBarcode}
      />
      <FormField
        label='Call Number'
        placeholder='Enter call number...'
        indexKey='itdeCallNumber'
        onChange={handleChange}
        defaultValue={itemCopy.itdeCallNumber}
      />
      <FormField
        label='ISBN'
        placeholder='Enter ISBN...'
        indexKey='itdeIsbn'
        onChange={handleChange}
        defaultValue={itemCopy.itdeIsbn}
      />
      <FormField label='Location'>
        <div className='select'>
          <select onChange={e => handleChange(e, 'itloId')}>
            {itemLocations.map(location => (
              <option selected={itemCopy.itloId == location.itloId} value={location.itloId}>
                {location.itloName}
              </option>
            ))}
          </select>
        </div>
      </FormField>
      <FormField label='Notes'>
        <textarea
          cols='30'
          rows='3'
          className='textarea'
          onChange={e => handleChange(e, 'itdeNotes')}
          placeholder='Enter description...'
          value={itemCopy.itdeNotes}
        />
      </FormField>
      <FormField
        label='Lend limit'
        placeholder='Enter lend limit...'
        indexKey='itdeLendLimit'
        onChange={handleChange}
        defaultValue={itemCopy.itdeLendLimit}
        attributes={{ maxlength: 4 }}
      />
      <FormField
        label='Renews'
        placeholder='Enter number of times renewable...'
        indexKey='itdeRenews'
        onChange={handleChange}
        defaultValue={itemCopy.itdeRenews}
        attributes={{ maxlength: 4 }}
      />
      <FormField
        label='Renew period'
        placeholder='Enter the number of days an item can be renewed for...'
        indexKey='itdeRenewPeriod'
        onChange={handleChange}
        onBlur={handleOnRenewPeriodBlur}
        defaultValue={itemCopy.itdeRenewPeriod}
        attributes={{ maxlength: 4 }}
      />
    </li>
  );
};

CopyItem.propTypes = {
  itemCopy: PropTypes.object.isRequired,
  itemLocations: PropTypes.array.isRequired,
  onDuplicate: PropTypes.func.isRequired
};

export default CopyItem;
